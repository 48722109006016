import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract, utils } from "ethers";
import { STAKE_CONTRACT } from "../../../consts";
import abi from "../../../contracts/stakes.json";

const useUnstakeAll = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(STAKE_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendUnstakeAll, state } = useContractFunction(
    contract,
    "unstakeAll"
  );

  const unstakeAll = async () => {
    try {
      if (account) {
        await sendUnstakeAll();
      }
    } catch (error) {
      console.error("Error while staking:", error);
    }
  };

  return { unstakeAll, state };
};

export default useUnstakeAll;
