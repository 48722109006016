import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract } from "ethers";
import { STAKE_CONTRACT } from "../../../consts";
import abi from "../../../contracts/stakes.json";

const useTriggerAllPayouts = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(STAKE_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendTriggerAllPayouts, state } = useContractFunction(
    contract,
    "triggerAllPayouts"
  );

  const triggerAllPayouts = async () => {
    try {
      if (account) {
        await sendTriggerAllPayouts();
      }
    } catch (error) {
      console.error("Error while contributing:", error);
    }
  };

  return { triggerAllPayouts, state };
};

export default useTriggerAllPayouts;
