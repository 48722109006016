import { useRef, useState } from "react";
import { toPng } from "html-to-image";
import "./Stake.module.css";

const Profile = () => {
  const [size, setSize] = useState(100);
  const [file, setFile] = useState();
  function handleChange(e) {
    if (e.target.files.length > 0)
      setFile(URL.createObjectURL(e.target.files[0]));
  }

  const elementRef = useRef(null);

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "dripx-pfp.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="w-full mt-[95px] py-20 flex flex-col lg:flex-row gap-6 justify-center items-center lg:items-start relative content">
      <div className="shadow-lg w-[95%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Profile Picture Creator</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div
            ref={elementRef}
            className={`border-[24px] border-[#2FC3F5] rounded-full w-full aspect-square overflow-hidden flex items-center justify-center bg-center bg-no-repeat`}
            style={{
              backgroundImage: `url(${file})`,
              backgroundSize: size + "%",
            }}
          ></div>
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">Size</span>
              <span>
                <input
                  type="number"
                  className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                  value={size}
                  max={200}
                  min={1}
                  onChange={({ target }) => setSize(target.value)}
                  onBlur={({ target }) =>
                    setSize(
                      +target.value > 200
                        ? 200
                        : +target.value < 1
                        ? 1
                        : +target.value
                    )
                  }
                />
                <span className="opacity-50 ml-2">%</span>
              </span>
            </div>
            <div className="relative">
              <input
                type="range"
                min="1"
                max="200"
                className="w-full appearance-none relative z-[3] bg-transparent"
                value={size}
                onChange={({ target }) => setSize(target.value)}
              />
            </div>
          </div>
          <div className="w-full">
            <label
              for="files"
              className="cursor-pointer block w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic text-center"
            >
              Upload Image
            </label>
            <input
              id="files"
              className="hidden w-full"
              type="file"
              accept="image/*"
              onChange={handleChange}
            />
          </div>
          <button
            className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic"
            onClick={htmlToImageConvert}
          >
            Download Profile Picture
          </button>
          <p className="opacity-50 text-white text-center">
            Your image never leaves your device. <br />
            DripX.win does not store any images.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
