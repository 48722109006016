import { useEffect, useRef, useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import MetaMaskIcon from "../../assets/metamask.svg";
import WalletConnectIcon from "../../assets/walletconnect.svg";
import { useEthers } from "@usedapp/core";

const ConnectModal = ({ onSelect, stats }) => {
  const { activateBrowserWallet } = useEthers();
  return (
    <>
      <div className="max-h-full overflow-y-auto z-[102] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg bg-[#0C0518] rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
        <div className="flex flex-col">
          <button
            className="h-[200px] flex items-center justify-center border-b border-b-pinky hover:bg-pinky hover:bg-opacity-30 transition-all rounded-t-lg"
            onClick={() => {
              activateBrowserWallet({ type: "metamask" });
              onSelect();
            }}
          >
            <img src={MetaMaskIcon} className="w-1/3" alt="Metamask" />
          </button>
          <button
            className="h-[200px] w-full flex items-center justify-center hover:bg-pinky hover:bg-opacity-30 transition-all rounded-b-lg"
            onClick={() => {
              activateBrowserWallet({ type: "walletConnectV2" });
              onSelect();
            }}
          >
            <img
              src={WalletConnectIcon}
              className="w-2/5"
              alt="WalletConnect V2"
            />
          </button>
        </div>
      </div>
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-[101]"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default ConnectModal;
