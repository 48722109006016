import { BSC, useEtherBalance, useEthers } from "@usedapp/core";
import PayoutCycle from "../components/Payouts/PayoutCycle";
import { useEffect, useMemo, useState } from "react";
import useTxToast from "../hooks/useTxToast";
import Tooltips from "../assets/tooltips";
import TooltipWrapper from "../components/Shared/TooltipWrapper";
import usePeggedBNBAmount from "../hooks/Admin/read/usePeggedBNBAmount";
import useWithdrawFromPool from "../hooks/Admin/write/useWithdrawFromPool";
import useDepositToPool from "../hooks/Admin/write/useDepositToPool";
import AdminPayoutCycle from "../components/Admin/AdminPayoutCycle";
import { useNavigate } from "react-router-dom";
import useMaxDripMintingOnDay from "../hooks/Miners/read/useMaxDripMintingOnDay";
import useUpdateMaxDripMintingOnDay from "../hooks/Admin/write/useUpdateMaxDripMintingOnDay";
import useCap from "../hooks/BuyAndBurn/read/useCap";
import { BUY_AND_BURN_CONTRACTS } from "../consts";
import useUpdateCap from "../hooks/Admin/write/useUpdateCap";
import useGetAvailableCollectBalance from "../hooks/BuyAndBurn/read/useGetAvailableCollectBalance";
import useCollect from "../hooks/BuyAndBurn/write/useCollect";
import useOwnerBuyAndBurn from "../hooks/Admin/write/useOwnerBuyAndBurn";

function formatNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(2) + "B";
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + "M";
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(2) + "K";
  }
  return num.toString();
}

const Admin = () => {
  const navigate = useNavigate();
  const { account } = useEthers();

  const [bnbPrice, setBnbPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [instantBnBAmount, setInstantBnBAmount] = useState(0);

  const bnbBalance = useEtherBalance(account);

  const maxDripMintingOnDay = useMaxDripMintingOnDay();
  const [newMaxDripMintingOnDay, setNewMaxDripMintingOnDay] = useState(0);
  const { updateMaxDripMintingOnDay, state: updateMaxDripMintingOnDayState } =
    useUpdateMaxDripMintingOnDay();

  useTxToast(updateMaxDripMintingOnDayState, "Updated Max Drip Minting On Day");

  const cap = useCap(BUY_AND_BURN_CONTRACTS[BSC.chainId][1]);
  const [newTriggerCap, setNewTriggerCap] = useState(0);
  const { updateCap, state: updateCapState } = useUpdateCap(
    BUY_AND_BURN_CONTRACTS[BSC.chainId][1]
  );

  useTxToast(updateCapState, "Updated Cap");

  const peggedBNBAmount_90 = usePeggedBNBAmount(90);
  const peggedBNBAmount_369 = usePeggedBNBAmount(369);
  const peggedBNBAmount_888 = usePeggedBNBAmount(888);

  const totalPegged = useMemo(
    () => peggedBNBAmount_90 + peggedBNBAmount_369 + peggedBNBAmount_888,
    [peggedBNBAmount_90, peggedBNBAmount_369, peggedBNBAmount_888]
  );

  const collectBalance = useGetAvailableCollectBalance(
    BUY_AND_BURN_CONTRACTS[BSC.chainId][1]
  );
  console.log(collectBalance);

  const { collect, state: collectState } = useCollect(
    BUY_AND_BURN_CONTRACTS[BSC.chainId][1]
  );

  const { ownerBuyAndBurn, state: ownerBuyAndBurnState } = useOwnerBuyAndBurn(
    BUY_AND_BURN_CONTRACTS[BSC.chainId][1]
  );

  useTxToast(collectState, "Collected!");
  useTxToast(ownerBuyAndBurnState, "Bought and burnt!");

  useEffect(() => {
    if (
      account &&
      account.toLowerCase() !==
        "0xe90C5C1D36aB80FfcCCca40C4989633026EF45Fa".toLowerCase()
    ) {
      navigate("/swap");
    }
  }, [account, navigate]);

  useEffect(() => {
    fetch(
      "https://api.dexscreener.com/latest/dex/pairs/bsc/0x172fcd41e0913e95784454622d1c3724f546f849"
    )
      .then((res) => res.json())
      .then((data) => {
        setBnbPrice(data.pairs[0].priceNative);
      });
  }, []);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0xcc9834a4e8b5b22bdfb0fcc7d43430ff9dabedca"
      )
        .then((res) => res.json())
        .then((data) => {
          setPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return account &&
    account.toLowerCase() ===
      "0xe90C5C1D36aB80FfcCCca40C4989633026EF45Fa".toLowerCase() ? (
    <div className="w-full mt-[95px] py-20 flex flex-col gap-6 justify-center items-center relative content">
      <div className="grid w-[85%] md:w-[780px] lg:w-[1024px] grid-cols-1 lg:grid-cols-2 gap-4">
        <div
          className={`shadow-lg bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4 col-span-1`}
        >
          <div className="flex justify-between text-white">
            <span className="text-xl font-medium flex-1">Miners</span>
          </div>
          <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
            <div className="flex flex-col">
              <div className="flex justify-between text-lg text-white">
                <span className="opacity-50 flex gap-2 items-center">
                  Miner Daily Cap
                  <TooltipWrapper
                    title={"The cap of DRIPX minted on a certain day."}
                  />
                </span>
                <div className="flex flex-col text-right text-white-400">
                  <span className="flex items-center gap-1">
                    {formatNumber(maxDripMintingOnDay)} DRIPX
                    <TooltipWrapper title={maxDripMintingOnDay} />
                  </span>
                </div>
              </div>
              <div className="w-full h-px bg-white bg-opacity-50 my-8" />
              <div className="flex gap-4 flex-col">
                <div className="flex flex-col gap-2 justify-between text-white">
                  <span className="opacity-50 flex gap-2 items-center justify-between">
                    Change to{" "}
                  </span>
                  <span className="flex">
                    <input
                      type="number"
                      className="flex-1 text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                      value={newMaxDripMintingOnDay}
                      onChange={({ target }) =>
                        setNewMaxDripMintingOnDay(target.value)
                      }
                    />
                    <span className="opacity-50 ml-2">DRIPX</span>
                  </span>
                </div>
                <button
                  className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] px-4 py-2 rounded-2xl font-medium text-2xl text-white not-italic"
                  onClick={async () =>
                    await updateMaxDripMintingOnDay(newMaxDripMintingOnDay)
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`shadow-lg bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4 col-span-1`}
        >
          <div className="flex justify-between text-white">
            <span className="text-xl font-medium flex-1">Reservoir</span>
          </div>
          <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
            <div className="flex flex-col">
              <div className="flex justify-between text-lg text-white">
                <span className="opacity-50 flex gap-2 items-center">
                  Reservoir Cap
                  <TooltipWrapper title={"The trigger cap of the B&B."} />
                </span>
                <div className="flex flex-col text-right text-white-400">
                  <span>{cap} BNB</span>
                </div>
              </div>
              <div className="w-full h-px bg-white bg-opacity-50 my-8" />
              <div className="flex gap-4 flex-col">
                <div className="flex flex-col gap-2 justify-between text-white">
                  <span className="opacity-50 flex gap-2 items-center justify-between">
                    Change to{" "}
                  </span>
                  <span className="flex">
                    <input
                      type="number"
                      className="flex-1 text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                      value={newTriggerCap}
                      onChange={({ target }) => setNewTriggerCap(target.value)}
                    />
                    <span className="opacity-50 ml-2">BNB</span>
                  </span>
                </div>
                <button
                  className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] px-4 py-2 rounded-2xl font-medium text-2xl text-white not-italic"
                  onClick={async () => await updateCap(newTriggerCap)}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid w-[85%] md:w-[780px] lg:w-[1024px] grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="shadow-lg bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4 col-span-1">
          <div className="flex justify-between text-white">
            <span className="text-xl font-medium">LP Fees</span>
          </div>
          <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
            <div className="flex flex-col ">
              <div className="flex justify-between text-lg text-white">
                <span className="opacity-50 flex gap-2 items-center">
                  Available BNB
                </span>
                <div className="flex flex-col text-right text-green-400">
                  <span>${(bnbPrice * collectBalance[0]).toFixed(2)}</span>
                  <span className="text-sm opacity-50">
                    {(+collectBalance[0]).toFixed(2)} BNB
                  </span>
                </div>
              </div>
              <div className="flex justify-between text-lg text-white">
                <span className="opacity-50 flex gap-2 items-center">
                  Available DRIPX
                </span>
                <div className="flex flex-col text-right text-green-400">
                  <span>${(price * collectBalance[1]).toFixed(2)}</span>
                  <span className="text-sm opacity-50">
                    {(+collectBalance[1]).toFixed(2)} DRIPX
                  </span>
                </div>
              </div>
            </div>
            <button
              className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] px-4 py-2 rounded-2xl font-medium text-2xl text-white not-italic"
              onClick={async () => await collect()}
            >
              Collect
            </button>
          </div>
        </div>
        <div className="shadow-lg bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4 col-span-1">
          <div className="flex justify-between text-white">
            <span className="text-xl font-medium">Instant Buy and Burn</span>
          </div>
          <div className="bg-[#0C0518] flex-1 bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
            <div className="flex flex-1 justifyflex-col">
              <div className="flex gap-4 flex-1 justify-between flex-col">
                <div className="flex flex-col gap-2 justify-between text-white">
                  <span className="opacity-50 flex gap-2 items-center justify-between">
                    Amount{" "}
                  </span>
                  <span className="flex">
                    <input
                      type="number"
                      className="flex-1 text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                      value={instantBnBAmount}
                      onChange={({ target }) =>
                        setInstantBnBAmount(target.value)
                      }
                    />
                    <span className="opacity-50 ml-2">BNB</span>
                  </span>
                </div>
                <button
                  className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] px-4 py-2 rounded-2xl font-medium text-2xl text-white not-italic"
                  onClick={async () => await ownerBuyAndBurn(instantBnBAmount)}
                >
                  Activate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-lg w-[85%] md:w-[780px] lg:w-[1024px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Admin Panel</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col ">
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Total Pegged BNB
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>${(bnbPrice * totalPegged).toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  {(+totalPegged).toFixed(2)} BNB
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid w-[85%] md:w-[780px] lg:w-[1024px] grid-cols-1 lg:grid-cols-2 gap-4">
        {[90, 369, 888].map((el, index) => (
          <AdminPayoutCycle
            key={index}
            days={el}
            bnbPrice={bnbPrice}
            bnbBalance={bnbBalance}
          />
        ))}
      </div>
    </div>
  ) : (
    <div className="w-full mt-[95px] py-20 flex flex-col gap-6 justify-center items-center relative content text-white">
      No Access
    </div>
  );
};

export default Admin;
