import FarmPool from "../components/Farms/FarmPool";
import { useEffect, useState } from "react";
import { PulseChain } from "../PulseChain";
import { FARMS } from "../consts";

const formatNumber = (number, decimals = 2) => {
  return (+number).toLocaleString("en-US", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });
};

const Vault = () => {
  const [incPrice, setIncPrice] = useState(0);
  const [pendingIncs, setPendingIncs] = useState([0, 0, 0, 0]);
  const [farmedBalances, setFarmedBalances] = useState([0, 0, 0, 0]);

  useEffect(() => {
    fetch(
      "https://api.dexscreener.com/latest/dex/pairs/pulsechain/0xf808bb6265e9ca27002c0a04562bf50d4fe37eaa"
    )
      .then((res) => res.json())
      .then((data) => {
        setIncPrice(data.pairs[0].priceUsd);
      });
  }, []);

  const totalPendingInc = pendingIncs.reduce((acc, curr) => acc + curr, 0);
  const totalFarmedBalance = farmedBalances.reduce(
    (acc, curr) => acc + curr,
    0
  );

  return (
    <div className="w-full mt-[95px] py-20 flex flex-col gap-6 justify-center items-center relative content">
      <div className="grid w-[85%] md:w-[780px] lg:w-[1024px] grid-cols-1 lg:grid-cols-2 gap-4">
        {/* <div
          className={`lg:col-span-2 shadow-lg bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4 mb-4 col-span-1`}
        >
          <div className="flex justify-between text-white">
            <span className="text-xl font-medium flex-1">Vault</span>
          </div>
          <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
            <div className="flex flex-col">
              <div className="flex justify-between text-lg text-white">
                <span className="opacity-50 flex gap-2 items-center">
                  Total Pending Rewards
                </span>
                <div className="flex flex-col text-right text-green-400">
                  <span>${formatNumber(totalPendingInc * incPrice)}</span>
                  <span className="text-sm opacity-50">
                    {formatNumber(+totalPendingInc)} INC
                  </span>
                </div>
              </div>
              <div className="flex justify-between text-lg text-white">
                <span className="opacity-50 flex gap-2 items-center">
                  Total Staked Balance
                </span>
                <div className="flex flex-col text-right text-green-400">
                  <span>${formatNumber(totalFarmedBalance)}</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {FARMS[PulseChain.chainId].map((el, index) => (
          <FarmPool
            index={index}
            farm={el}
            key={el.pId}
            incPrice={incPrice}
            setPendingIncs={setPendingIncs}
            setFarmedBalances={setFarmedBalances}
          />
        ))}
      </div>
    </div>
  );
};

export default Vault;
