import React, { useState } from "react";
import useCurrentCyclePayoutAmout from "../../hooks/Stakes/read/useCurrentCyclePayoutAmout";
import useCurrentCyclePayoutIndex from "../../hooks/Stakes/read/useCurrentCyclePayoutIndex";
import Tooltips from "../../assets/tooltips";
import TooltipWrapper from "../Shared/TooltipWrapper";
import { createPortal } from "react-dom";
import ShareModal from "./ShareModal";
import { IoShareOutline } from "react-icons/io5";

const PayoutCycle = ({
  days,
  bnbPrice,
  userShares,
  globalShares,
  currentContractDay,
  genesisTs,
}) => {
  const [showShareModal, setShowShareModal] = useState(undefined);

  const currentCyclePayoutAmount = useCurrentCyclePayoutAmout(days);
  const currentCyclePayoutIndex = useCurrentCyclePayoutIndex(days);

  const lastCyclePayout =
    days * +currentCyclePayoutIndex - 1 * currentCyclePayoutIndex;
  const nextCyclePayout = days * (+currentCyclePayoutIndex + 1) - 1;

  const lastCyclePayoutTs = lastCyclePayout * 86400 + +genesisTs;
  const nextCyclePayoutTs = nextCyclePayout * 86400 + +genesisTs;

  if (days == 8) {
    console.log("genesisTs", genesisTs);
    console.log("lastCyclePayout", lastCyclePayout);
    console.log("last payout ts", lastCyclePayout * 86400 + +genesisTs);

    console.log("next payout", nextCyclePayout);
    console.log("next payout ts", nextCyclePayout * 86400 + +genesisTs);
  }

  // using the timestamps, calculate the progress
  const progress = Math.min(
    ((Date.now() / 1000 - lastCyclePayoutTs) /
      (nextCyclePayoutTs - lastCyclePayoutTs)) *
      100,
    100
  );

  const globalCyclePayoutUsd = currentCyclePayoutAmount * bnbPrice;

  const userEstPayout = (userShares / globalShares) * currentCyclePayoutAmount;
  const userEstPayoutUsd = userEstPayout * bnbPrice;

  return (
    <>
      <div
        className={`shadow-lg bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4 col-span-1 ${
          days === 888 && "lg:col-span-2"
        }`}
      >
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium flex-1">
            {days}-Day Payout Cycle #{currentCyclePayoutIndex}
          </span>
          <button
            className="text-xl font-bold bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg text-white not-italic"
            onClick={() => setShowShareModal(true)}
          >
            <IoShareOutline />
          </button>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Global Cycle Payout
                <TooltipWrapper title={Tooltips.PAYOUTS.GLOBAL_CYCLE_PAYOUT} />
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>${globalCyclePayoutUsd.toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  {(+currentCyclePayoutAmount).toFixed(7)} BNB
                </span>
              </div>
            </div>
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Your Est. Payout
                <TooltipWrapper title={Tooltips.PAYOUTS.EST_PAYOUT} />
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>${userEstPayoutUsd.toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  {userEstPayout.toFixed(7)} BNB
                </span>
              </div>
            </div>
            <div className="w-full h-px bg-white bg-opacity-50" />
            <div className="flex flex-col text-white  mt-2 gap-2">
              <div className="flex justify-between">
                <span className="opacity-50 text-lg flex gap-2 items-center">
                  Next Payout
                  <TooltipWrapper title={Tooltips.PAYOUTS.NEXT_PAYOUT} />
                </span>
                <span className="text-lg">Day {nextCyclePayout + 1}</span>
              </div>
              <div className="flex w-full justify-between items-center gap-2">
                <div className="w-full h-2 rounded-xl bg-[#D9D9D9] bg-opacity-20 overflow-hidden">
                  <div
                    style={{
                      width: `${progress.toFixed(2)}%`,
                    }}
                    className={`h-full bg-gradient-to-r rounded-r-xl from-pinky to-yellowy `}
                  />
                </div>
                <span>{progress.toFixed(2)}%</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showShareModal &&
        createPortal(
          <ShareModal
            onSelect={() => setShowShareModal(undefined)}
            day={days}
            currentCyclePayoutIndex={currentCyclePayoutIndex}
            globalCyclePayoutUsd={globalCyclePayoutUsd}
            currentCyclePayoutAmount={currentCyclePayoutAmount}
            userEstPayoutUsd={userEstPayoutUsd}
            userEstPayout={userEstPayout}
            nextCyclePayout={nextCyclePayout}
            progress={progress}
          />,
          document.body
        )}
    </>
  );
};

export default PayoutCycle;
