import { LuExternalLink } from "react-icons/lu";

const BuyNow = () => {
  return (
    <div className="w-full mt-[95px] py-20 flex flex-col gap-6 justify-center items-center relative content">
      <div className="shadow-lg w-[100%] md:w-[580px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white items-center">
          <span className="text-xl font-medium">Buy DRIPX on PancakeSwap</span>
          <a
            className="text-2xl"
            href="https://pancakeswap.finance/swap?outputCurrency=0xeCbcE2c13d3A1248deBfD23DCc0558b495916756&chainId=56&inputCurrency=0xF30224eB7104aca47235beb3362E331Ece70616A"
            target="_blank"
            rel="noreferrer"
          >
            <LuExternalLink />
          </a>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <iframe
            src="https://pancakeswap.finance/swap?outputCurrency=0xeCbcE2c13d3A1248deBfD23DCc0558b495916756&chainId=56&inputCurrency=0xF30224eB7104aca47235beb3362E331Ece70616A?embed=true "
            title="Buy Now"
            className="w-full h-[700px] rounded-2xl"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default BuyNow;
