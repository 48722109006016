import Tooltips from "../../assets/tooltips";
import { BUY_AND_BURN_CONTRACTS, WETH_CONTRACT } from "../../consts";
import useActionTimeout from "../../hooks/BuyAndBurn/read/useActionTimeout";
import useClaimTimestamp from "../../hooks/BuyAndBurn/read/useClaimTimestamp";
import useGetAvailableRewards from "../../hooks/BuyAndBurn/read/useGetAvailableRewards";
import useTotalBurnedToken from "../../hooks/BuyAndBurn/read/useTotalBurnedToken";
import useTotalRewarded from "../../hooks/BuyAndBurn/read/useTotalRewarded";
import useActivate from "../../hooks/BuyAndBurn/write/useActivate";
import useTokenBalance from "../../hooks/useTokenBalance";
import useTxToast from "../../hooks/useTxToast";
import { useEthers } from "@usedapp/core";
import Countdown from "../Shared/Countdown";
import useCap from "../../hooks/BuyAndBurn/read/useCap";
import useTotalPaired from "../../hooks/BuyAndBurn/read/useTotalPaired";

const BuyAndBurnComponent = ({ bnbPrice, tokenPrice, version }) => {
  const { chainId, library } = useEthers();
  const contract =
    BUY_AND_BURN_CONTRACTS?.[chainId]?.[version - 1] ?? undefined;
  const claimTimestamp = useClaimTimestamp(contract);
  const actionTimeout = useActionTimeout(contract);
  const availableRewards = useGetAvailableRewards(contract);
  const totalBurnedToken = useTotalBurnedToken(contract);
  const totalRewarded = useTotalRewarded(contract);
  const totalPaired = useTotalPaired(contract);
  const wethBalance = useTokenBalance(contract, WETH_CONTRACT[chainId]);
  const cap = useCap(version == 2 ? contract : undefined);

  const buyAndBurnAmount = +availableRewards * 0.9967;
  const userRewards = +availableRewards * 0.0033;

  const { activate, state } = useActivate(contract);

  const isActivable = (+claimTimestamp + +actionTimeout) * 1000 < Date.now();

  useTxToast(state, "Activated!");

  const handleActivate = async () => {
    try {
      await library.provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38", // Hexadecimal version of 56, for BSC Mainnet
            chainName: "BSC Mainnet (PancakeSwap Private RPC)",
            nativeCurrency: {
              name: "Binance Coin",
              symbol: "BNB", // 2-6 characters long
              decimals: 18,
            },
            rpcUrls: ["https://pancake-bnb.rpc.blxrbdn.com"],
            blockExplorerUrls: ["https://bscscan.com/"],
          },
        ],
      });

      await activate();
    } catch (addError) {
      console.error("Error adding chain: ", addError);
      // create a new tab to
      window.open(
        "https://docs.pancakeswap.finance/products/pancakeswap-private-rpc",
        "_blank"
      );
    }
  };

  return (
    <div className="shadow-lg w-[85%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
      <div className="flex justify-between text-white">
        {/* <span className="text-xl font-medium">Buy & Burn v{version}</span> */}
        <span className="text-xl font-medium">Reservoir</span>
      </div>
      <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col ">
          <div className="flex justify-between text-lg text-white">
            <span className="opacity-50 flex gap-2 items-center">
              Reservoir Balance
            </span>
            <div className="flex flex-col text-right text-green-400">
              <span>${(wethBalance * bnbPrice).toFixed(2)}</span>
              <span className="text-sm opacity-50">{wethBalance} BNB</span>
            </div>
          </div>
          <div className="flex justify-between text-lg text-white">
            <span className="opacity-50 flex gap-2 items-center">
              Trigger Ready In
            </span>
            <div className="flex flex-col text-right text-green-400">
              <span>
                {version == 1 || !isActivable ? (
                  <Countdown
                    targetDate={(+claimTimestamp + +actionTimeout) * 1000}
                  />
                ) : (
                  "Ready"
                )}
              </span>
            </div>
          </div>
          {/* <div className="flex justify-between text-lg text-white">
            <span className="opacity-50 flex gap-2 items-center">
              Available Buy & Burn
            </span>
            <div className="flex flex-col text-right text-green-400">
              <span>${(bnbPrice * buyAndBurnAmount).toFixed(2)}</span>
              <span className="text-sm opacity-50">
                {buyAndBurnAmount.toFixed(6)} BNB
              </span>
            </div>
          </div>
          <div
            className={`flex justify-between text-lg text-white ${
              version == 1 && "opacity-0"
            }`}
          >
            <span className="opacity-50 flex gap-2 items-center">
              Available User Rewards
            </span>
            <div className="flex flex-col text-right text-green-400">
              <span>${(bnbPrice * userRewards).toFixed(2)}</span>
              <span className="text-sm opacity-50">
                {(+userRewards).toFixed(6)} BNB
              </span>
            </div>
          </div> */}
        </div>
      </div>
      {/* <button
        disabled={version == 1 || !isActivable}
        className={`${
          (version == 1 || !isActivable) && "opacity-30"
        } w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-2xl text-white not-italic`}
        onClick={async () => await handleActivate()}
      >
        {version == 1 || !isActivable ? (
          <Countdown targetDate={(+claimTimestamp + +actionTimeout) * 1000} />
        ) : (
          "Activate"
        )}
      </button> */}
      <div className="flex flex-col text-sm">
        <div className="flex justify-between text-white">
          <span className="opacity-50 flex gap-2 items-center">
            Trigger Cap
          </span>
          <div className="flex flex-col text-right ">
            <span>{cap} BNB</span>
          </div>
        </div>
        <div className="flex justify-between text-white items-start">
          <span className="opacity-50 flex gap-2 items-center">
            Total DRIPX Bought & Burned
          </span>
          <div className="flex flex-col text-right ">
            <span>
              {(+totalBurnedToken).toFixed(6)} DRIPX ($
              {(tokenPrice * totalBurnedToken).toFixed(2)})
            </span>
          </div>
        </div>
        <div className="flex justify-between text-white items-start">
          <span className="opacity-50 flex gap-2 items-center">
            Total BNB Rewarded
          </span>
          <div className="flex flex-col text-right ">
            <span>
              {(+totalRewarded).toFixed(6)} BNB ($
              {(bnbPrice * totalRewarded).toFixed(2)})
            </span>
          </div>
        </div>
        <div className="flex justify-between text-white items-start">
          <span className="opacity-50 flex gap-2 items-center">
            Total BNB Paired as LP
          </span>
          <div className="flex flex-col text-right ">
            <span>
              {(+totalPaired).toFixed(6)} BNB ($
              {(bnbPrice * totalPaired).toFixed(2)})
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyAndBurnComponent;
