import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract } from "ethers";
import { MINER_CONTRACT } from "../../../consts";
import abi from "../../../contracts/miners.json";

const useBatchMintMiner = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(MINER_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendBatchMintMiner, state } = useContractFunction(
    contract,
    "batchMintMiner"
  );

  const batchMintMiner = async (power, length, quantity, value) => {
    try {
      if (account) {
        await sendBatchMintMiner(power, length, quantity, {
          value,
        });
      }
    } catch (error) {
      console.error("Error while contributing:", error);
    }
  };

  return { batchMintMiner, state };
};

export default useBatchMintMiner;
