import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract } from "ethers";
import { MINER_CONTRACT } from "../../../consts";
import abi from "../../../contracts/miners.json";

const useClaimMint = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(MINER_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendClaimMint, state } = useContractFunction(
    contract,
    "claimMint"
  );

  const claimMint = async (id) => {
    try {
      if (account) {
        await sendClaimMint(id);
      }
    } catch (error) {
      console.error("Error while contributing:", error);
    }
  };

  return { claimMint, state };
};

export default useClaimMint;
