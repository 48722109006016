import { useCall, useEthers } from "@usedapp/core";
import { Contract, utils } from "ethers";
import { MINER_CONTRACT } from "../../../consts";
import abi from "../../../contracts/miners.json";

const useMinerName = (id) => {
  const { chainId } = useEthers();

  const { value, error } =
    useCall(
      id !== undefined &&
        MINER_CONTRACT[chainId] && {
          contract: new Contract(MINER_CONTRACT[chainId], abi),
          method: "minerNames",
          args: [id],
        }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? value?.[0] : "Unnamed";
};

export default useMinerName;
