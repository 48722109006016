import { useMemo, useState } from "react";
import useCurrentMintCost from "../../hooks/Miners/read/useCurrentMintCost";
import useCurrentMintable from "../../hooks/Miners/read/useCurrentMintable";
import useCurrentEAA from "../../hooks/Miners/read/useCurrentEAA";
import useTotalSupply from "../../hooks/Miners/read/useTotalSupply";
import useGenesisTs from "../../hooks/Miners/read/useGenesisTs";
import useCurrentContractDay from "../../hooks/Miners/read/useCurrentContractDay";
import useDripMintingOnDay from "../../hooks/Miners/read/useDripMintingOnDay";
import useMaxDripMintingOnDay from "../../hooks/Miners/read/useMaxDripMintingOnDay";
import Tooltips from "../../assets/tooltips";
import TooltipWrapper from "../Shared/TooltipWrapper";

const calculateValueAtExpiry = (mintPower, numOfDays, mintable, eaa) => {
  let sumMintable = 0;

  mintable = +mintable;

  for (let i = 0; i < numOfDays; i++) {
    sumMintable += mintable;
    mintable = (mintable * 99_90) / 100_00;
    if (mintable < 800) mintable = 800;
  }

  let averageMintable = sumMintable / numOfDays;

  let totalRewards = (averageMintable * mintPower * numOfDays) / 100;

  if (eaa > 0) {
    totalRewards += (totalRewards * eaa) / 100;
  }

  return totalRewards;
};

const MinerCalculator = ({ tokenPrice, bnbPrice }) => {
  const [duration, setDuration] = useState(280);
  const [power, setPower] = useState(100);
  const [nMiners, setNMiners] = useState(8);

  const currentMintCost = useCurrentMintCost();
  const currentMintable = useCurrentMintable();
  const currentEAA = useCurrentEAA();
  const genesisTs = useGenesisTs();
  const currentContractDay = useCurrentContractDay();

  const valueAtExpiry = useMemo(
    () =>
      calculateValueAtExpiry(power, duration, currentMintable, currentEAA) *
      nMiners,
    [power, duration, currentMintable, currentEAA, nMiners]
  );

  const priceOfMintCost = useMemo(
    () =>
      (((Date.now() > (+genesisTs + 86400 * currentContractDay) * 1000
        ? currentMintCost *
          5 *
          Math.pow(
            1.0012,
            Math.floor(
              1 +
                (Math.floor(Date.now() / 1000) -
                  (+genesisTs + 86400 * currentContractDay)) /
                  86400
            )
          )
        : currentMintCost * 1.01) *
        power) /
        100) *
      (nMiners * 1.01),
    [currentContractDay, genesisTs, currentMintCost, power, nMiners]
  );

  const usdPriceOfMintCost = useMemo(
    () => priceOfMintCost * bnbPrice,
    [priceOfMintCost, bnbPrice]
  );

  const marketValueAtExpiry = useMemo(
    () => valueAtExpiry * tokenPrice,
    [valueAtExpiry, tokenPrice]
  );

  const estROI = useMemo(
    () =>
      ((marketValueAtExpiry - usdPriceOfMintCost) / usdPriceOfMintCost) * 100,
    [marketValueAtExpiry, usdPriceOfMintCost]
  );

  return (
    <>
      <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-1 rounded-2xl rounded-tl-none shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col">
          <div className="flex justify-between text-sm text-white">
            <span className="opacity-50  flex gap-2 items-center">
              Duration
              <TooltipWrapper title={Tooltips.MINERS.DURATION} />
            </span>
            <span>
              <input
                type="number"
                className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                value={duration}
                max={280}
                min={8}
                onChange={({ target }) => setDuration(target.value)}
                onBlur={({ target }) =>
                  setDuration(
                    +target.value > 280
                      ? 280
                      : +target.value < 8
                      ? 8
                      : +target.value
                  )
                }
              />
              <span className="opacity-50 ml-2">DAYS</span>
            </span>
          </div>
          <div className="relative">
            <input
              type="range"
              min="8"
              max="280"
              className="w-full appearance-none relative z-[3] bg-transparent"
              value={duration}
              onChange={({ target }) => setDuration(target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-between text-sm text-white">
            <span className="opacity-50  flex gap-2 items-center">
              Power
              <TooltipWrapper title={Tooltips.MINERS.POWER} />
            </span>
            <input
              type="number"
              className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
              value={power}
              max={100}
              min={1}
              onChange={({ target }) => setPower(target.value)}
              onBlur={({ target }) =>
                setPower(
                  target.value > 100 ? 100 : target.value < 1 ? 1 : target.value
                )
              }
            />
          </div>
          <div className="relative">
            <input
              type="range"
              min="1"
              max="100"
              className="w-full appearance-none relative z-[3] bg-transparent"
              value={power}
              onChange={({ target }) => setPower(target.value)}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-between text-sm text-white">
            <span className="opacity-50">Number of Miners</span>
            <span>
              <input
                type="number"
                className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                value={nMiners}
                max={8}
                min={1}
                onChange={({ target }) => setNMiners(target.value)}
                onBlur={({ target }) =>
                  setNMiners(
                    +target.value > 8
                      ? 8
                      : +target.value < 1
                      ? 1
                      : +target.value
                  )
                }
              />
              <span className="opacity-50 ml-2">MINERS</span>
            </span>
          </div>
          <div className="relative">
            <input
              type="range"
              min="1"
              max="8"
              className="w-full appearance-none relative z-[3] bg-transparent"
              value={nMiners}
              onChange={({ target }) => setNMiners(target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between text-white">
        <span className="text-xl font-medium">Summary & Estimated ROI</span>
      </div>
      <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col">
          <div className="flex justify-between text-sm text-blue-400">
            <span className="opacity-50 flex gap-2 items-center">
              Est. DRIPX at End of Miner{nMiners > 1 && "s"}
              <TooltipWrapper title={Tooltips.MINERS.EST_DRIPX_AT_END} />
            </span>
            <span>{valueAtExpiry.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-sm text-orange-400">
            <span className="opacity-50 ">
              BNB to Start Miner{nMiners > 1 && "s"}
            </span>
            <span>{`${priceOfMintCost.toFixed(
              6
            )} BNB (~$${usdPriceOfMintCost.toFixed(2)})`}</span>
          </div>
          <div className="flex justify-between text-sm text-green-400">
            <span className="opacity-50 flex gap-2 items-center">
              $ Market Value of Miner{nMiners > 1 && "s"}
              <TooltipWrapper title={Tooltips.MINERS.MARKET_VALUE_MINER} />
            </span>
            <span>${marketValueAtExpiry.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-sm text-green-400">
            <span className="opacity-50">
              Est. ROI % at End of Miner{nMiners > 1 && "s"}
            </span>
            <span>{estROI.toFixed(2)}%</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinerCalculator;
