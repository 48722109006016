import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract } from "ethers";
import { FACUET_CONTRACT } from "../../../consts";
import abi from "../../../contracts/faucet.json";

const useDeposit = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(FACUET_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendDeposit, state } = useContractFunction(contract, "deposit");

  const deposit = async (dripxAmount, value) => {
    try {
      if (account) {
        await sendDeposit(dripxAmount, {
          value,
        });
      }
    } catch (error) {
      console.error("Error while contributing:", error);
    }
  };

  return { deposit, state };
};

export default useDeposit;
