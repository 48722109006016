import { useEthers, useContractFunction } from "@usedapp/core";
import { Contract } from "ethers";
import abi from "../../../contracts/swap.json";
import { SWAP_CONTRACT } from "../../../consts";

const useSwapTokensToTokens = () => {
  const { library, account, chainId } = useEthers();
  const contract =
    library && chainId && SWAP_CONTRACT[chainId]
      ? new Contract(SWAP_CONTRACT[chainId], abi, library.getSigner())
      : null;
  const { send: sendSwap, state } = useContractFunction(
    contract,
    "swapTokenForToken",
    { gasLimitBufferPercentage: 100 }
  );

  const swapTokensToTokens = async (
    amount,
    amountOutMin,
    path,
    pathToWeth_V2,
    pathToWeth_V3
  ) => {
    try {
      if (
        account &&
        amount &&
        amountOutMin != undefined &&
        (pathToWeth_V2.length >= 2 || pathToWeth_V3.length >= 43)
      ) {
        await sendSwap(
          amount,
          amountOutMin,
          path,
          pathToWeth_V2,
          pathToWeth_V3
        );
      }
    } catch (error) {
      console.error("Error while swapping tokens to tokens:", error);
    }
  };

  return { swapTokensToTokens, state };
};

export default useSwapTokensToTokens;
