import { IoSettingsOutline, IoSwapVerticalOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import EthLogo from "../assets/images/eth-logo.svg";
import MetamaskLogo from "../assets/images/metamask.svg";
import SwapTo from "../assets/images/swap-to.svg";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import SelectTokenModal from "../components/Swap/SelectTokenModal";
import qs from "qs";
import { BSC, Mainnet, useEtherBalance, useEthers } from "@usedapp/core";
import useTokenAllowance from "../hooks/Swap/read/useTokenAllowance";
import {
  DEX_SUBGRAPH,
  EXCLUDED_SOURCES,
  SWAP_CONTRACT,
  TOKEN_CONTRACT,
  TOKEN_LIST,
  WETH_CONTRACT,
} from "../consts";
import useApprove from "../hooks/Swap/write/useApprove";
import useTxToast from "../hooks/useTxToast";
import useSwapEthToTokens from "../hooks/Swap/write/useSwapEthToTokens";
import useSwapTokensToEth from "../hooks/Swap/write/useSwapTokensToEth";
import useSwapTokensToTokens from "../hooks/Swap/write/useSwapTokensToTokens";
import { utils } from "ethers";
import { useSearchParams } from "react-router-dom";
import useTokenBalance from "../hooks/Swap/read/useTokenBalance";
import SettingsModal from "../components/Swap/SettingsModal";
import useSwapExactInput from "../hooks/Swap/write/useSwapExactInput";

const Swap = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { account, chainId } = useEthers();
  const [showModal, setShowModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [burnActive, setBurnActive] = useState(true);
  const [side, setSide] = useState();
  const [currentTrade, setCurrentTrade] = useState({
    from: TOKEN_LIST[
      [Mainnet.chainId, BSC.chainId].includes(chainId) ? chainId : BSC.chainId
    ][0],
    to: TOKEN_LIST[
      [Mainnet.chainId, BSC.chainId].includes(chainId) ? chainId : BSC.chainId
    ][1],
  });

  const [dollarValueFrom, setDollarValueFrom] = useState(0.0);
  const [dollarValueTo, setDollarValueTo] = useState(0.0);
  const [fromValue, setFromValue] = useState();
  const [toValue, setToValue] = useState();
  const [gasEstimate, setGasEstimate] = useState();
  const [swapPriceJSON, setSwapPriceJSON] = useState();
  const [swapQuoteJSON, setSwapQuoteJSON] = useState();
  const [lastTouched, setLastTouched] = useState();
  const [isSwapping, setIsSwapping] = useState(false);
  const [slippage, setSlippage] = useState(0.5);

  const fromBalance = useTokenBalance(
    account,
    currentTrade?.from?.address,
    currentTrade?.from?.decimals
  );
  const toBalance = useTokenBalance(
    account,
    currentTrade?.to?.address,
    currentTrade?.to?.decimals
  );
  const ethBalance = useEtherBalance(account);

  const allowance = useTokenAllowance(
    account,
    SWAP_CONTRACT[chainId],
    currentTrade?.from?.address,
    currentTrade?.from?.decimals
  );

  const { approve, state: approveState } = useApprove(
    currentTrade?.from?.address
  );

  const { swapExactInput, state: swapExactInputState } = useSwapExactInput();
  const { swapEthToTokens, state: swapETTState } = useSwapEthToTokens();
  const { swapTokensToEth, state: swapTTEState } = useSwapTokensToEth();
  const { swapTokensToTokens, state: swapTTTState } = useSwapTokensToTokens();

  const handleSlippage = (value) => {
    setSlippage(value);
    localStorage.setItem("slippage", value);
  };

  useEffect(() => {
    const slippage = JSON.parse(localStorage.getItem("slippage"));
    if (slippage && !isNaN(slippage)) {
      setSlippage(slippage);
    }
  }, []);

  const isApproved = () =>
    currentTrade?.from?.isNative || (allowance && +allowance >= +fromValue);

  const handleOpenModal = (side) => {
    setSide(side);
    setShowModal(true);
  };

  const handleSelect = (token) => {
    if (!token) {
      setShowModal(false);
      return;
    }
    setCurrentTrade((prev) => {
      let curr = {
        ...prev,
        [side]: token,
      };
      if (
        currentTrade[side === "from" ? "to" : "from"].address === token.address
      )
        curr[side === "from" ? "to" : "from"] =
          TOKEN_LIST[chainId][token.isNative ? 1 : 0];
      return curr;
    });
    setToValue();
    setFromValue();
    setShowModal(false);
  };

  const getPrice = useCallback(async () => {
    console.log("Getting Price");

    if (!currentTrade.from || !currentTrade.to) return;
    if (!fromValue && !toValue) return;
    let amount = utils.parseUnits(
      lastTouched === "from" ? fromValue : toValue,
      currentTrade.from.decimals
    );

    const params = {
      sellToken: currentTrade.from.address,
      buyToken: currentTrade.to.address,
      [lastTouched === "from" ? "sellAmount" : "buyAmount"]: amount.toString(),
    };

    const headers = { "0x-api-key": "c1514a5d-79da-45bc-86d1-6335480c0b49" };

    const response = await fetch(
      `https://${
        chainId == BSC.chainId ? "bsc." : ""
      }api.0x.org/swap/v1/price?${qs.stringify(
        params
      )}&excludedSources=${EXCLUDED_SOURCES[chainId].join(",")}`,
      { headers }
    );

    const temp = await response.json();
    setSwapPriceJSON(temp);
    console.log("Price: ", temp);

    lastTouched === "from"
      ? setToValue(utils.formatUnits(temp.buyAmount, currentTrade.to.decimals))
      : setFromValue(
          utils.formatUnits(temp.sellAmount, currentTrade.from.decimals)
        );
    setGasEstimate(temp.estimatedGas);
  }, [currentTrade, fromValue, toValue, lastTouched, chainId]);

  const getQuote = useCallback(async () => {
    console.log("Getting Quote");

    if (!currentTrade.from || !currentTrade.to) return;
    if (!fromValue && !toValue) return;
    let amount = utils.parseUnits(
      lastTouched === "from" ? fromValue : toValue,
      currentTrade.from.decimals
    );

    const params = {
      sellToken: currentTrade.from.address,
      buyToken: currentTrade.to.address,
      [lastTouched === "from" ? "sellAmount" : "buyAmount"]: amount.toString(),
    };

    const headers = { "0x-api-key": "c1514a5d-79da-45bc-86d1-6335480c0b49" };

    const response = await fetch(
      `https://${
        chainId === BSC.chainId ? "bsc." : ""
      }api.0x.org/swap/v1/quote?${qs.stringify(
        params
      )}&excludedSources=${EXCLUDED_SOURCES[chainId].join(",")}`,
      { headers }
    );

    params.buyToken =
      params.sellToken.toLowerCase() === WETH_CONTRACT[chainId].toLowerCase()
        ? TOKEN_CONTRACT[chainId]
        : WETH_CONTRACT[chainId];
    params[lastTouched === "from" ? "sellAmount" : "buyAmount"] = utils
      .parseEther((+utils.formatEther(amount) * 0.5) / 100 + "")
      .toString();

    await new Promise((r) => setTimeout(r, 2000));

    const tokenToBurnsRes = await fetch(
      `https://${
        chainId === BSC.chainId ? "bsc." : ""
      }api.0x.org/swap/v1/quote?${qs.stringify(
        params
      )}&excludedSources=${EXCLUDED_SOURCES[chainId].join(",")}`,
      { headers }
    );

    const temp = await response.json();
    const tempBurns = await tokenToBurnsRes.json();
    setSwapQuoteJSON(temp);
    console.log("Quote: ", temp);

    lastTouched === "from"
      ? setToValue(utils.formatUnits(temp.buyAmount, currentTrade.to.decimals))
      : setFromValue(
          utils.formatUnits(temp.sellAmount, currentTrade.from.decimals)
        );

    setGasEstimate(temp.estimatedGas);

    return [temp, tempBurns];
  }, [currentTrade, fromValue, toValue, lastTouched, chainId]);

  const trySwap = async () => {
    if (!isApproved())
      await approve(
        SWAP_CONTRACT[chainId],
        utils.parseUnits(fromValue, currentTrade.from.decimals)
      );
    else {
      if (isSwapping) return;
      setIsSwapping(true);
      try {
        const [temp, tempToWeth] = await getQuote();

        const source = temp.sources.reduce((prev, current) =>
          prev.proportion > current.proportion ? prev : current
        );

        const order = temp.orders.find((el) => el.source === source.name);

        console.log("Order: ", order, "Source: ", source);

        const sourceToWeth = tempToWeth.sources.reduce((prev, current) =>
          prev.proportion > current.proportion ? prev : current
        );

        const orderToWeth = tempToWeth.orders.find(
          (el) => el.source === sourceToWeth.name
        );

        console.log(
          "OrderToWeth: ",
          orderToWeth,
          "SourceToWeth: ",
          sourceToWeth
        );

        if (currentTrade?.from?.isNative) {
          console.log("here2", source, source === "PancakeSwap_V2");
          if (source.name === "PancakeSwap_V2")
            await swapEthToTokens(
              utils.parseUnits(fromValue, currentTrade.from.decimals),
              utils.parseUnits(
                (
                  +utils.formatEther(temp.buyAmount) *
                  (1 - slippage / 100)
                ).toFixed(8),
                currentTrade.to.decimals
              ),
              order.fillData.tokenAddressPath,
              orderToWeth?.fillData?.tokenAddressPath ?? [],
              orderToWeth?.fillData?.path ?? "0x00"
            );
          else if (source.name === "PancakeSwap_V3")
            await swapExactInput(
              utils.parseUnits(fromValue, currentTrade.from.decimals),
              utils.parseUnits(
                (
                  +utils.formatEther(temp.buyAmount) *
                  (1 - slippage / 100)
                ).toFixed(8),
                currentTrade.to.decimals
              ),
              order?.fillData?.path ?? "0x00",
              orderToWeth?.fillData?.tokenAddressPath ?? [],
              orderToWeth?.fillData?.path ?? "0x00",
              true
            );
        } else if (currentTrade?.to?.isNative) {
          console.log("here3");
          if (source.name === "PancakeSwap_V2")
            await swapTokensToEth(
              utils.parseUnits(fromValue, currentTrade.from.decimals),
              utils.parseUnits(
                (
                  +utils.formatEther(temp.buyAmount) *
                  (1 - slippage / 100)
                ).toFixed(8),
                currentTrade.to.decimals
              ),
              order.fillData.tokenAddressPath,
              orderToWeth?.fillData?.tokenAddressPath ?? [],
              orderToWeth?.fillData?.path ?? "0x00"
            );
          else if (source.name === "PancakeSwap_V3")
            await swapExactInput(
              utils.parseUnits(fromValue, currentTrade.from.decimals),
              utils.parseUnits(
                (
                  +utils.formatEther(temp.buyAmount) *
                  (1 - slippage / 100)
                ).toFixed(8),
                currentTrade.to.decimals
              ),
              order?.fillData?.path ?? "0x00",
              orderToWeth?.fillData?.tokenAddressPath ?? [],
              orderToWeth?.fillData?.path ?? "0x00",
              true
            );
        } else {
          console.log("here4");
          if (source.name === "PancakeSwap_V2")
            await swapTokensToTokens(
              utils.parseUnits(fromValue, currentTrade.from.decimals),
              utils.parseUnits(
                (
                  +utils.formatEther(temp.buyAmount) *
                  (1 - slippage / 100)
                ).toFixed(8),
                currentTrade.to.decimals
              ),
              order.fillData.tokenAddressPath,
              orderToWeth?.fillData?.tokenAddressPath ?? [],
              orderToWeth?.fillData?.path ?? "0x00"
            );
          else if (source.name === "PancakeSwap_V3")
            await swapExactInput(
              utils.parseUnits(fromValue, currentTrade.from.decimals),
              utils.parseUnits(
                (
                  +utils.formatEther(temp.buyAmount) *
                  (1 - slippage / 100)
                ).toFixed(8),
                currentTrade.to.decimals
              ),
              order?.fillData?.path ?? "0x00",
              orderToWeth?.fillData?.tokenAddressPath ?? [],
              orderToWeth?.fillData?.path ?? "0x00",
              false
            );
        }
      } catch (err) {
        console.log(err);
        setIsSwapping(false);
      }
      setIsSwapping(false);
    }
  };

  useEffect(() => {
    const getTokenInfoFromAddress = async (side, address) => {
      const URL = DEX_SUBGRAPH[chainId];

      let query = `{
        tokens(where: { id: "${address.toLowerCase()}"}) {
            id
            symbol
            name
            decimals
        }
    }`;
      axios.post(URL, { query }).then((res) => {
        let token =
          res.data.data.tokens.map((el) => ({
            ...el,
            address: el.id,
            warning: true,
          }))[0] ?? undefined;

        setCurrentTrade((prev) => ({
          ...prev,
          [side]: token,
        }));
      });
    };

    if (searchParams.get("outputCurrency"))
      getTokenInfoFromAddress("to", searchParams.get("outputCurrency"));

    if (searchParams.get("inputCurrency"))
      getTokenInfoFromAddress("from", searchParams.get("inputCurrency"));
  }, [searchParams, chainId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPrice();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [fromValue, getPrice, toValue]);

  useEffect(() => {
    const fetchPrice = async () => {
      const response = await fetch(
        `https://api.dexscreener.com/latest/dex/tokens/${currentTrade.from.address}`
      );

      const temp = await response.json();

      setDollarValueFrom(
        temp.pairs.find((pair) =>
          ["pancakeswap", "uniswap"].includes(pair.dexId)
        ).priceUsd ?? 0
      );
    };

    if (currentTrade.from.address) fetchPrice();
  }, [currentTrade.from]);

  useEffect(() => {
    const fetchPrice = async () => {
      const response = await fetch(
        `https://api.dexscreener.com/latest/dex/tokens/${currentTrade.to.address}`
      );

      const temp = await response.json();

      setDollarValueTo(
        temp.pairs.find((pair) =>
          ["pancakeswap", "uniswap"].includes(pair.dexId)
        ).priceUsd ?? 0
      );
    };

    if (currentTrade.to.address) fetchPrice();
  }, [currentTrade.to]);

  useEffect(() => {
    setCurrentTrade({
      from: TOKEN_LIST[
        [Mainnet.chainId, BSC.chainId].includes(chainId) ? chainId : BSC.chainId
      ][0],
      to: TOKEN_LIST[
        [Mainnet.chainId, BSC.chainId].includes(chainId) ? chainId : BSC.chainId
      ][1],
    });
  }, [chainId, account]);

  useTxToast(approveState, "Successfully approved!");
  useTxToast(swapETTState, "Successfully swapped!");
  useTxToast(swapTTEState, "Successfully swapped!");
  useTxToast(swapTTTState, "Successfully swapped!");
  useTxToast(swapExactInputState, "Successfully swapped!");

  return (
    <>
      <div className="w-full mt-[95px] py-20 flex flex-col gap-6 justify-center items-center relative content">
        <div className="shadow-lg w-[95%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
          <div className="flex justify-between text-white">
            <span className="text-xl font-medium">Swap</span>
            <div
              className="flex gap-4 items-center"
              onClick={() => setShowSettingsModal(true)}
            >
              <IoSettingsOutline />
            </div>
          </div>
          <div className="bg-[#0C0518] shadow-[0px_4px_24px_rgba(0,0,0,0.25)] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl">
            <div className="flex flex-col gap-2 text-white">
              <div className="w-full text-right text-base font-medium pr-[6px]">
                <span className="text-xs opacity-50 mr-1 font-normal">
                  Balance
                </span>
                {currentTrade?.from?.isNative
                  ? (+utils.formatEther(ethBalance ? ethBalance : "0")).toFixed(
                      2
                    )
                  : fromBalance}
              </div>
              <div className="bg-[#0C0518] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[16px] pt-6 pb-4 px-6 flex flex-col items-end relative">
                <input
                  className="font-medium text-3xl bg-transparent outline-none text-right w-[calc(100%-2.25rem-42px)]"
                  onChange={({ target }) => {
                    setFromValue(target.value);
                    setLastTouched("from");
                  }}
                  value={
                    fromValue && !isNaN(fromValue) && lastTouched === "to"
                      ? parseFloat((+fromValue).toPrecision(6))
                      : fromValue
                  }
                  placeholder="0.000"
                />
                <div className="opacity-50 text-base">
                  $ {(dollarValueFrom * (fromValue ?? 0) ?? 0).toFixed(2)}
                </div>
                <div className="flex gap-2 mt-4">
                  <div
                    className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                    onClick={() => {
                      setFromValue(
                        (currentTrade?.from?.isNative
                          ? utils.formatEther(ethBalance ? ethBalance : "0")
                          : fromBalance) *
                          0.25 +
                          ""
                      );
                      setLastTouched("from");
                      getPrice();
                    }}
                  >
                    25%
                  </div>
                  <div
                    className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                    onClick={() => {
                      setFromValue(
                        (currentTrade?.from?.isNative
                          ? utils.formatEther(ethBalance ? ethBalance : "0")
                          : fromBalance) *
                          0.5 +
                          ""
                      );
                      setLastTouched("from");
                      getPrice();
                    }}
                  >
                    50%
                  </div>
                  <div
                    className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                    onClick={() => {
                      setFromValue(
                        (currentTrade?.from?.isNative
                          ? utils.formatEther(ethBalance ? ethBalance : "0")
                          : fromBalance) *
                          0.75 +
                          ""
                      );
                      setLastTouched("from");
                      getPrice();
                    }}
                  >
                    75%
                  </div>
                  <div
                    className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] bg-pinky text-sm font-regular"
                    onClick={() => {
                      setFromValue(
                        (currentTrade?.from?.isNative
                          ? +utils.formatEther(ethBalance ? ethBalance : "0") -
                            0.01
                          : fromBalance - 0.01) + ""
                      );
                      setLastTouched("from");
                      getPrice();
                    }}
                  >
                    MAX
                  </div>
                </div>
                <div
                  className="absolute left-9 top-0 transform -translate-y-[calc(50%-0.75rem)] flex flex-col items-center cursor-pointer"
                  onClick={() => handleOpenModal("from")}
                >
                  <img
                    alt="from-icon"
                    className="w-[42px] rounded-full"
                    src={
                      currentTrade?.from?.logoURI
                        ? currentTrade?.from.logoURI
                        : "./assets/images/no-logo.png"
                    }
                  />
                  <div className="relative">
                    {currentTrade?.from?.symbol
                      ? currentTrade?.from.symbol
                      : "SELECT"}
                    <IoIosArrowDown className="absolute top-1/2 left-full -translate-y-1/2" />
                  </div>
                </div>
                {/* {account && currentTrade?.from?.symbol && (
                  <img
                    src={MetamaskLogo}
                    className='absolute left-[calc(2.25rem+21px)] -translate-x-1/2 bottom-[calc(1rem+11px)]'
                  />
                )} */}
              </div>
            </div>
            <div className="w-full flex justify-center relative">
              <img
                className="cursor-pointer"
                src={SwapTo}
                onClick={() => {
                  setCurrentTrade((prev) => ({
                    from: prev.to,
                    to: prev.from,
                  }));
                  getPrice();
                }}
                alt="swap to"
              />
            </div>
            <div className="flex flex-col gap-2 text-white -mt-4">
              <div className="w-full text-right text-base font-medium pr-[6px]">
                <span className="text-xs opacity-50 mr-1 font-normal">
                  Balance
                </span>
                {currentTrade?.to?.isNative
                  ? utils.formatEther(ethBalance ? ethBalance : "0")
                  : toBalance}
              </div>
              <div className="bg-[#0C0518] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[16px] pt-6 pb-4 px-6 flex flex-col items-end relative">
                <input
                  className="font-medium text-3xl bg-transparent outline-none text-right w-[calc(100%-2.25rem-42px)]"
                  onBlur={getPrice}
                  onChange={({ target }) => {
                    setToValue(target.value);
                    setLastTouched("to");
                  }}
                  value={
                    toValue && !isNaN(toValue) && lastTouched === "from"
                      ? parseFloat((+toValue).toPrecision(6))
                      : toValue
                  }
                  placeholder="0.000"
                />
                <div className="opacity-50 text-base">
                  $ {(dollarValueTo * (toValue ?? 0) ?? 0).toFixed(2)}
                </div>
                {/* <div className='flex gap-2 mt-4'>
                  <div className='cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular'>
                    25%
                  </div>
                  <div className='cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular'>
                    50%
                  </div>
                  <div className='cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular'>
                    75%
                  </div>
                  <div className='cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] bg-pinky text-sm font-regular'>
                    MAX
                  </div>
                </div> */}
                <div
                  className="absolute left-9 top-0 transform -translate-y-[calc(50%-0.75rem)] flex flex-col items-center cursor-pointer"
                  onClick={() => handleOpenModal("to")}
                >
                  <img
                    alt="from-icon"
                    className="w-[42px] rounded-full"
                    src={
                      currentTrade?.to?.logoURI
                        ? currentTrade?.to.logoURI
                        : "./assets/images/no-logo.png"
                    }
                  />
                  <div className="relative">
                    {currentTrade?.to?.symbol
                      ? currentTrade?.to.symbol
                      : "SELECT"}
                    <IoIosArrowDown className="absolute top-1/2 left-full -translate-y-1/2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className={`${
              isSwapping && "opacity-25"
            } w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-2xl text-white not-italic`}
            onClick={trySwap}
            disabled={isSwapping}
          >
            {isApproved() ? "Swap" : "Approve"}
          </button>
          <p className="text-xs opacity-50 text-white text-center">
            0.50% of the transaction value will buy and burn DRIPX
          </p>
        </div>
        {/* <div className='shadow-lg w-[95%] md:w-[480px] bg-white rounded-2xl flex flex-col gap-4 p-4'>
          <div className='flex'>
            <span className='border-b-2 border-primary'>Trade Information</span>
          </div>
          <div className='flex justify-between'>
            <span className='text-gray-400'>Current Slippage</span>
            <span>Auto</span>
          </div>
          <div className='flex justify-between'>
            <span className='text-gray-400'>Price Impact</span>
            <span>
              {swapPriceJSON ? swapPriceJSON?.estimatedPriceImpact : 0}
            </span>
          </div>
          <div className='flex justify-between'>
            <span className='text-gray-400'>Price</span>
            <span>
              {swapPriceJSON ? swapPriceJSON?.price : 0}{' '}
              {currentTrade?.to?.symbol ? currentTrade?.to?.symbol : 'SELECT'} =
              1.00{' '}
              {currentTrade?.from?.symbol
                ? currentTrade?.from?.symbol
                : 'SELECT'}
            </span>
          </div>
        </div> */}
      </div>
      {showModal &&
        createPortal(
          <SelectTokenModal onSelect={handleSelect} />,
          document.body
        )}
      {showSettingsModal &&
        createPortal(
          <SettingsModal
            onSelect={() => setShowSettingsModal(false)}
            slippage={slippage}
            setSlippage={handleSlippage}
          />,
          document.body
        )}
    </>
  );
};

export default Swap;
