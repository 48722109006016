import ArrowIcon from "../assets/images/arrow.svg";
import "./Stake.module.css";
import { useEffect, useMemo, useState } from "react";
import { useEthers } from "@usedapp/core";
import { createPortal } from "react-dom";
import useTokenBalance from "../hooks/useTokenBalance";
import useCurrentContractDay from "../hooks/Stakes/read/useCurrentContractDay";
import useCurrentShareRate from "../hooks/Stakes/read/useCurrentShareRate";
import Countdown from "../components/Shared/Countdown";
import useGenesisTs from "../hooks/Stakes/read/useGenesisTs";
import useStartStake from "../hooks/Stakes/write/useStartStake";
import useTxToast from "../hooks/useTxToast";
import StakesModal from "../components/Stake/StakesModal";
import TooltipWrapper from "../components/Shared/TooltipWrapper";
import Tooltips from "../assets/tooltips";
// import StakingsModal from "../components/Stake/StakingsModal";
import dripXToken from "../assets/images/dripx_token.jpg";
import { TOKEN_CONTRACT } from "../consts";

const Stake = () => {
  const { account, chainId } = useEthers();

  const tokenBalance = useTokenBalance(account, TOKEN_CONTRACT[chainId]);

  const [tokenPrice, setTokenPrice] = useState(0);
  const [showStakings, setShowStakings] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [duration, setDuration] = useState(28);

  const currentContractDay = useCurrentContractDay();
  const genesisTs = useGenesisTs();
  const currentShareRate = useCurrentShareRate();

  const { startStake, state: startStakeState } = useStartStake();

  useTxToast(startStakeState, "Staked!");

  const preBonusShares = useMemo(
    () => quantity / +currentShareRate,
    [quantity, currentShareRate]
  );

  const longerPaysMorePercentage = useMemo(
    () => Math.min((350 / 2888) * duration, 350),
    [duration]
  );

  const longerPaysMoreBonusShares = useMemo(
    () => (longerPaysMorePercentage * preBonusShares) / 100,
    [longerPaysMorePercentage, preBonusShares]
  );

  const biggerPaysMorePercentage = useMemo(
    () => Math.min((8 / 1e11) * quantity, 8),
    [quantity]
  );

  const biggerPaysMoreBonusShares = useMemo(
    () => (biggerPaysMorePercentage * preBonusShares) / 100,
    [biggerPaysMorePercentage, preBonusShares]
  );

  const effectiveShares = useMemo(
    () =>
      preBonusShares + longerPaysMoreBonusShares + biggerPaysMoreBonusShares,
    [preBonusShares, longerPaysMoreBonusShares, biggerPaysMoreBonusShares]
  );

  const [wdripPrice, setWdripPrice] = useState(0);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x41e3149918f8EFeE8Ef6f47CF45D4CE580F837cB"
      )
        .then((res) => res.json())
        .then((data) => {
          setWdripPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0xcc9834a4e8b5b22bdfb0fcc7d43430ff9dabedca"
      )
        .then((res) => res.json())
        .then((data) => {
          setTokenPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // useEffect(() => {
  //   let interval = setInterval(async () => {
  //     fetch(
  //       `https://api.dexscreener.com/latest/dex/tokens/${TOKEN_CONTRACT[chainId]}`
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         const pair = data.pairs.filter(
  //           (el) => el.quoteToken.symbol == "WDRIP"
  //         )[0];
  //         setTokenPrice((pair.priceNative ?? 0) * (wdripPrice ?? 0));
  //       });
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [chainId, wdripPrice]);

  return (
    <div className="w-full mt-[95px] py-20 flex flex-col lg:flex-row gap-6 justify-center items-center lg:items-start relative content">
      <div className="shadow-lg w-[85%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Stake</span>
          {account && (
            <span
              className="text-lg self-end flex gap-2 cursor-pointer bg-gradient-to-r from-pinky to-yellowy px-2 rounded-md"
              onClick={() => setShowStakings(true)}
            >
              Manage staking
              <img alt="go" src={ArrowIcon} />
            </span>
          )}
        </div>{" "}
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col gap-2 text-white">
            <div className="w-full text-right text-base font-medium pr-[6px]">
              <span className="text-xs opacity-50 mr-1 font-normal">
                Balance
              </span>
              {tokenBalance}
            </div>
            <div className="bg-[#0C0518] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[16px] pt-4 pb-4 px-6 flex flex-col items-end relative">
              <input
                className="h-[1.875rem] font-medium text-3xl bg-transparent outline-none text-right w-[calc(100%-2.25rem-42px)]"
                onChange={({ target }) => {
                  setQuantity(target.value);
                }}
                value={quantity}
                placeholder="0.000"
              />
              <div className="opacity-50 text-base">
                $ {(+quantity * tokenPrice).toFixed(2)}
              </div>
              <div className="flex gap-2 mt-4">
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setQuantity(tokenBalance * 0.25 + "");
                  }}
                >
                  25%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setQuantity(tokenBalance * 0.5 + "");
                  }}
                >
                  50%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setQuantity(tokenBalance * 0.75 + "");
                  }}
                >
                  75%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] bg-pinky text-sm font-regular"
                  onClick={() => {
                    setQuantity(tokenBalance + "");
                  }}
                >
                  MAX
                </div>
              </div>
              <div className="leading-4 absolute left-6 w-[52px] top-0 md:top-1/2 transform -translate-y-[calc(50%-0.75rem)] md:-translate-y-1/2 flex flex-col h-full justify-center gap-1 pt-4 pb-4 items-center">
                <img
                  alt="from-icon"
                  className="rounded-full"
                  src={dripXToken}
                />
                DRIPX
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Duration
                <TooltipWrapper title={Tooltips.STAKES.DURATION} />
              </span>
              <span>
                <input
                  type="number"
                  className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                  value={duration}
                  max={3500}
                  min={28}
                  onChange={({ target }) => setDuration(target.value)}
                  onBlur={({ target }) =>
                    setDuration(
                      +target.value > 3500
                        ? 3500
                        : +target.value < 28
                        ? 28
                        : +target.value
                    )
                  }
                />
                <span className="opacity-50 ml-2">DAYS</span>
              </span>
            </div>
            <div className="relative">
              <input
                type="range"
                min="28"
                max="3500"
                className="w-full appearance-none relative z-[3] bg-transparent"
                value={duration}
                onChange={({ target }) => setDuration(target.value)}
              />
            </div>
          </div>
        </div>
        <button
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-2xl text-white not-italic"
          onClick={async () => await startStake(quantity, duration)}
        >
          Stake
        </button>
      </div>

      <div className="shadow-lg w-[85%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Summary</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-blue-400">
              <span className="opacity-50">DRIPX in Stake</span>
              <span>{(+quantity).toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-green-400">
              <span className="opacity-50  flex gap-2 items-center">
                # of Shares
                <TooltipWrapper title={Tooltips.STAKES.NUM_OF_SHARES} />
              </span>
              <span>{effectiveShares.toFixed(2)}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">DRIPX Details</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                DRIPX Market Price
                <TooltipWrapper title={Tooltips.MINERS.DRIPX_MARKET_PRICE} />
              </span>
              <span>${(+tokenPrice).toFixed(7)}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">
            DRIPX Stake Details (est.)
          </span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Current Share Rate (excl. bonuses)
                <TooltipWrapper title={Tooltips.STAKES.CURRENT_SHARE_RATE} />
              </span>
              <span>{(+currentShareRate).toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">
                Base Shares (excl. bonuses)
                <TooltipWrapper title={Tooltips.STAKES.BASE_SHARE} />
              </span>
              <span>{preBonusShares.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">
                Longer Pays More Bonus ({longerPaysMorePercentage.toFixed(2)}%)
                <TooltipWrapper title={Tooltips.STAKES.LONGER_PAYS_MORE} />
              </span>
              <span>+{longerPaysMoreBonusShares.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">
                Bigger Pays More Bonus ({biggerPaysMorePercentage.toFixed(2)}%)
                <TooltipWrapper title={Tooltips.STAKES.BIGGER_PAYS_MORE} />
              </span>
              <span>+{biggerPaysMoreBonusShares.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Effective Share Rate (incl. Bonuses)
                <TooltipWrapper title={Tooltips.STAKES.EFFECTIVE_SHARE_RATE} />
              </span>
              <span>{(quantity / effectiveShares).toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Effective Share (incl. Bonuses)
                <TooltipWrapper title={Tooltips.STAKES.EFFECTIVE_SHARES} />
              </span>
              <span>{effectiveShares.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">
                Next Difficulty Increase
                <TooltipWrapper
                  title={Tooltips.STAKES.NEXT_DIFFICULTY_INCREASE}
                />
              </span>
              <Countdown
                targetDate={(+genesisTs + 86400 * currentContractDay) * 1000}
              />
            </div>
          </div>
        </div>
      </div>
      {showStakings &&
        createPortal(
          <StakesModal
            onSelect={() => setShowStakings(false)}
            tokenPrice={tokenPrice}
          />,
          // <></>,
          document.body
        )}
    </div>
  );
};

export default Stake;
