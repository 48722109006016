import React, { useState, useEffect, useRef, useCallback } from "react";

function Countdown({ targetDate }) {
  const intervalRef = useRef(null);
  const [time, setTime] = useState([0, 0, 0, 0]);

  const calculateTimeDifference = useCallback(() => {
    const difference = new Date(targetDate).getTime() - Date.now();

    if (difference <= 0) {
      if (intervalRef.current) clearInterval(intervalRef.current);
      setTime([0, 0, 0, 0]);
      return [0, 0, 0, 0];
    }

    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    return [days, hours, minutes, seconds];
  }, [targetDate]);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime(calculateTimeDifference());
    }, 10);

    return () => clearInterval(intervalRef.current);
  }, [targetDate, calculateTimeDifference]);

  const formattedTime = time
    .map((value) => value.toString().padStart(2, "0"))
    .join(":");

  return !isNaN(targetDate) ? (
    <p className={formattedTime === "00:00:00:00" ? "text-red-400" : undefined}>
      {formattedTime}
    </p>
  ) : (
    <p>--</p>
  );
}

export default Countdown;
