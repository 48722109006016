import { BSC, BSCTestnet, Hardhat } from "@usedapp/core";
import { PulseChain } from "./PulseChain";

export const FACUET_CONTRACT = {
  [BSC.chainId]: "0x3036cf132131fFa5795dBe3BD551Df8ff6e2e91b",
  [Hardhat.chainId]: "0x3036cf132131fFa5795dBe3BD551Df8ff6e2e91b",
};

export const TOKEN_CONTRACT = {
  [BSCTestnet.chainId]: "0xc6595168eC07B9F868426F9DbAfD1e4aE8E62967",
  // [BSCTestnet.chainId]: "0x836158E619c70Ef543C33408d9EE21fA62D518AC",
  [BSC.chainId]: "0xeCbcE2c13d3A1248deBfD23DCc0558b495916756",
  [Hardhat.chainId]: "0xeCbcE2c13d3A1248deBfD23DCc0558b495916756",
};

export const MINER_CONTRACT = {
  [BSCTestnet.chainId]: "0x1b6cF3c90017eF7809CfbB541342A4e5C2ff4Ac0",
  // [BSCTestnet.chainId]: "0xD074925c99e6dD6997128B195BA8802cAfa2503B",
  [BSC.chainId]: "0xFD98e0fD21785A0b0444E8997e8CC91cf4EB61aB",
  [Hardhat.chainId]: "0xFD98e0fD21785A0b0444E8997e8CC91cf4EB61aB",
};

export const STAKE_CONTRACT = {
  [BSCTestnet.chainId]: "0xfBE5165c56bBee6909010e0159e064EAbe2BDf74",
  // [BSCTestnet.chainId]: "0x30b7d93815e08bb314677D30B777d5D934820Ed4",
  [BSC.chainId]: "0x3423527498C3f1fAc237F0727C9FAbcCcEB268cf",
  [Hardhat.chainId]: "0x3423527498C3f1fAc237F0727C9FAbcCcEB268cf",
};

export const WETH_CONTRACT = {
  // [BSCTestnet.chainId]: "0xE61fE74F5bFb44F0930Bb87754a77342fc5A6e22",
  [BSCTestnet.chainId]: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
  [BSC.chainId]: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
  [Hardhat.chainId]: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
};

export const WDRIP = {
  [BSC.chainId]: "0xf30224eb7104aca47235beb3362e331ece70616a",
  [Hardhat.chainId]: "0xf30224eb7104aca47235beb3362e331ece70616a",
};

export const BUY_AND_BURN_CONTRACTS = {
  [BSCTestnet.chainId]: [
    "0x29a1ed761DC2fFd172232D23e9c97D65AFfB67c9", // V1
    "0x1cD883Ab68Cb1213ee6A6D27b0f9d2453f8fcE4F", // V2
    // "0x17D51EBF4B37006832077992d134f59650E1158C", // V1
    // "0x17D51EBF4B37006832077992d134f59650E1158C", // V2
  ],
  [BSC.chainId]: [
    "0x19C72FcDaB869518AFcbcf0d02c6516d7f1C4c97", // V1
    "0xc56E00A01F6eE5c4eD588B8673977a551Ed0dB17", // V2
  ],
  [Hardhat.chainId]: [
    "0x19C72FcDaB869518AFcbcf0d02c6516d7f1C4c97", // V1
    "0xc56E00A01F6eE5c4eD588B8673977a551Ed0dB17", // V2
  ],
};

export const FARM_VAULT_ADDRESS = {
  [PulseChain.chainId]: "0x614C2656418f45b74C8C586C00AF0fa0a921293E",
};

export const MASTERCHEF_CONTRACT = {
  [PulseChain.chainId]: "0xB2Ca4A66d3e57a5a9A12043B6bAD28249fE302d4",
};

export const FARMS = {
  [PulseChain.chainId]: [
    {
      name: "DAI from Ethereum-WPLS LP",
      symbol: "eDAI-WPLS LP",
      pId: 1,
      chainId: PulseChain.chainId,
      poolAddress: "0xe56043671df55de5cdf8459710433c10324de0ae",
    },
    {
      name: "USDC from Ethereum-WPLS LP",
      symbol: "eUSDC-WPLS LP",
      pId: 2,
      chainId: PulseChain.chainId,
      poolAddress: "0x6753560538eca67617a9ce605178f788be7e524e",
    },
    {
      name: "WETH from Ethereum-WPLS LP",
      symbol: "eWETH-WPLS LP",
      pId: 4,
      chainId: PulseChain.chainId,
      poolAddress: "0x42AbdFDB63f3282033C766E72Cc4810738571609",
    },
    {
      name: "USDT from Ethereum-WPLS LP",
      symbol: "eUSDT-WPLS LP",
      pId: 5,
      chainId: PulseChain.chainId,
      poolAddress: "0x322df7921f28f1146cdf62afdac0d6bc0ab80711",
    },
  ],
};

export const SWAP_CONTRACT = {
  [BSCTestnet.chainId]: "0xdbd721c38b0f112f9c5197ddc80a2c46e857dcb6",
  [BSC.chainId]: "0x5aac2d0c0c38171ef0d65d28e825933a14acd8eb",
  [Hardhat.chainId]: "0x5aac2d0c0c38171ef0d65d28e825933a14acd8eb",
};

export const DEX_SUBGRAPH = {
  [BSC.chainId]:
    "https://data-platform.nodereal.io/graph/v1/fbc6265d861f40a2ac1d841b1e5a7d40/projects/pancakeswap",
  [Hardhat.chainId]:
    "https://data-platform.nodereal.io/graph/v1/fbc6265d861f40a2ac1d841b1e5a7d40/projects/pancakeswap",
};

export const EXCLUDED_SOURCES = {
  [BSC.chainId]: [
    "ACryptoS",
    "ApeSwap",
    "BakerySwap",
    "Belt",
    "BiSwap",
    "DODO",
    "DODO_V2",
    "Ellipsis",
    "FirebirdOneSwap",
    "KnightSwap",
    "KyberDMM",
    "MDex",
    "Mooniswap",
    "MultiHop",
    "Nerve",
    "PancakeSwap",
    "SushiSwap",
    "Synapse",
    "WaultSwap",
    "WOOFi",
  ],
  [Hardhat.chainId]: [
    "ACryptoS",
    "ApeSwap",
    "BakerySwap",
    "Belt",
    "BiSwap",
    "DODO",
    "DODO_V2",
    "Ellipsis",
    "FirebirdOneSwap",
    "KnightSwap",
    "KyberDMM",
    "MDex",
    "Mooniswap",
    "MultiHop",
    "Nerve",
    "PancakeSwap",
    "SushiSwap",
    "Synapse",
    "WaultSwap",
    "WOOFi",
  ],
};

export const TOKEN_LIST = {
  [BSC.chainId]: [
    {
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      decimals: 18,
      logoURI: "./assets/images/bnb-logo.svg",
      name: "BNB",
      symbol: "BNB",
      isNative: true,
    },
    {
      address: TOKEN_CONTRACT[BSC.chainId],
      decimals: 18,
      logoURI: "./assets/images/tokens/dripx_token.jpg",
      name: "DRIPX Token",
      symbol: "DRIPX",
    },
    // {
    //   address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    //   decimals: 18,
    //   logoURI: "./assets/images/tokens/busd.png",
    //   name: "Binance USD",
    //   symbol: "BUSD",
    // },
    {
      address: "0xF30224eB7104aca47235beb3362E331Ece70616A",
      decimals: 18,
      logoURI: "./assets/images/tokens/wdrip.png",
      name: "Wrapped DRIP",
      symbol: "WDRIP",
    },
    {
      address: "0x20f663cea80face82acdfa3aae6862d246ce0333",
      decimals: 18,
      logoURI: "./assets/images/tokens/drip.png",
      name: "Drip Network",
      symbol: "DRIP",
    },
  ],
  [Hardhat.chainId]: [
    {
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      decimals: 18,
      logoURI: "./assets/images/bnb-logo.svg",
      name: "BNB",
      symbol: "BNB",
      isNative: true,
    },
    {
      address: TOKEN_CONTRACT[Hardhat.chainId],
      decimals: 18,
      logoURI: "./assets/images/tokens/dripx_token.jpg",
      name: "DRIPX Token",
      symbol: "DRIPX",
    },
    // {
    //   address: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    //   decimals: 18,
    //   logoURI: "./assets/images/tokens/busd.png",
    //   name: "Binance USD",
    //   symbol: "BUSD",
    // },
    {
      address: "0xF30224eB7104aca47235beb3362E331Ece70616A",
      decimals: 18,
      logoURI: "./assets/images/tokens/wdrip.png",
      name: "Wrapped DRIP",
      symbol: "WDRIP",
    },
    {
      address: "0x20f663cea80face82acdfa3aae6862d246ce0333",
      decimals: 18,
      logoURI: "./assets/images/tokens/drip.png",
      name: "Drip Network",
      symbol: "DRIP",
    },
  ],
};

export const COMMON_TOKENS_LIST = {
  [BSC.chainId]: [
    {
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      decimals: 18,
      logoURI: "./assets/images/bnb-logo.svg",
      name: "BNB",
      symbol: "BNB",
      isNative: true,
    },
    {
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      decimals: 18,
      logoURI: "./assets/images/tokens/dai.png",
      name: "DAI Token",
      symbol: "DAI",
    },
    {
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      decimals: 18,
      logoURI: "./assets/images/tokens/usdc.png",
      name: "USD Coin",
      symbol: "USDC",
    },
    {
      address: "0x55d398326f99059ff775485246999027b3197955",
      decimals: 18,
      logoURI: "./assets/images/tokens/usdt.png",
      name: "Tether USD",
      symbol: "USDT",
    },
  ],
  [Hardhat.chainId]: [
    {
      address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      decimals: 18,
      logoURI: "./assets/images/bnb-logo.svg",
      name: "BNB",
      symbol: "BNB",
      isNative: true,
    },
    {
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      decimals: 18,
      logoURI: "./assets/images/tokens/dai.png",
      name: "DAI Token",
      symbol: "DAI",
    },
    {
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d",
      decimals: 18,
      logoURI: "./assets/images/tokens/usdc.png",
      name: "USD Coin",
      symbol: "USDC",
    },
    {
      address: "0x55d398326f99059ff775485246999027b3197955",
      decimals: 18,
      logoURI: "./assets/images/tokens/usdt.png",
      name: "Tether USD",
      symbol: "USDT",
    },
  ],
};
