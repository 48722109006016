import { Mainnet, useEthers } from "@usedapp/core";
import axios from "axios";
import { DEX_SUBGRAPH, TOKEN_LIST } from "../../consts";
import { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/close.svg";

const SettingsModal = ({ onSelect, slippage, setSlippage }) => {
  const { chainId, library } = useEthers();
  const tokenList = TOKEN_LIST[chainId];
  const [search, setSearch] = useState();
  const [searchResults, setSearchResults] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleSearch = () => {
    setIsLoading(true);
    const URL = DEX_SUBGRAPH[chainId];

    const filter = chainId == Mainnet.chainId ? "_nocase" : "";
    const query = `{
        tokens(first: 5, where: {or: [
          { name_contains${filter}: "${search}" },
          { symbol_contains${filter}: "${search}" }
        ],
        }) {
            id
            symbol
            name
            decimals
        }
    }`;

    axios.post(URL, { query }).then((res) => {
      setSearchResults(
        res.data.data.tokens.map((el) => ({ ...el, address: el.id }))
      );
      setIsLoading(false);
    });
  };

  const asyncFn = async () => {
    console.log(
      library ? (await library.getFeeData()).gasPrice.toString() : "ss"
    );
  };

  asyncFn();

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <div className="max-h-full overflow-y-auto z-[100] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg w-[95%] md:w-[580px] bg-[#0C0518] bg-opacity-90 lg:bg-opacity-70 rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
        <div className="flex justify-between items-center">
          <span className=" font-medium text-[19px] w-fit">Settings</span>
          <img
            alt="close"
            src={CloseIcon}
            className="cursor-pointer"
            onClick={() => onSelect()}
          />
        </div>
        <div className="flex justify-between items-center">
          <span className="font-medium">Slippage</span>
          <div
            className={`${
              slippage == 0.1 ? "bg-white bg-opacity-50" : ""
            }  border-[1px] border-white border-opacity-50 rounded-2xl px-2 py-1 cursor-pointer`}
            onClick={() => setSlippage(0.1)}
          >
            0.1%
          </div>
          <div
            className={`${
              slippage == 0.5 ? "bg-white bg-opacity-50" : ""
            }  border-[1px] border-white border-opacity-50 rounded-2xl px-2 py-1 cursor-pointer`}
            onClick={() => setSlippage(0.5)}
          >
            0.5%
          </div>
          <div
            className={`${
              slippage == 1.0 ? "bg-white bg-opacity-50" : ""
            }  border-[1px] border-white border-opacity-50 rounded-2xl px-2 py-1 cursor-pointer`}
            onClick={() => setSlippage(1)}
          >
            1.0%
          </div>
          <div className="flex gap-1 items-center">
            <div className="border-[1px] border-white border-opacity-50 rounded-2xl px-3 py-1">
              <input
                className="bg-transparent outline-none flex-1 text-sm font-normal w-20"
                placeholder="1%"
                value={slippage}
                type="number"
                onChange={(e) => setSlippage(e.target.value)}
              />
            </div>
            %
          </div>
        </div>
      </div>
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-20"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default SettingsModal;
