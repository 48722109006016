import { useEthers, useContractFunction } from "@usedapp/core";
import { Contract } from "ethers";
import abi from "../../../contracts/buyandburn.json";

const useCollect = (contract_) => {
  const { library, account } = useEthers();
  const contract =
    library && contract_
      ? new Contract(contract_, abi, library.getSigner())
      : null;
  const { send: sendCollect, state } = useContractFunction(
    contract,
    "collectLiquidity"
  );

  const collect = async () => {
    try {
      if (account) {
        await sendCollect();
      }
    } catch (error) {
      console.error("Error while activating:", error);
    }
  };

  return { collect, state };
};

export default useCollect;
