import React, { useEffect, useState } from "react";
import usePendingInc from "../../hooks/Farms/read/usePendingInc";
import useTotalSupply from "../../hooks/Farms/read/useTotalSupply";
import useUserInfo from "../../hooks/Farms/read/useUserInfo";

const formatNumber = (number, decimals = 2) => {
  return (+number).toLocaleString("en-US", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });
};

const FarmPool = ({
  index,
  farm,
  incPrice,
  setPendingIncs,
  setFarmedBalances,
}) => {
  const pendingInc = usePendingInc(farm.pId, farm.chainId);
  const farmedBalance = useUserInfo(farm.pId, farm.chainId);

  const [poolValueUsd, setPoolValueUsd] = useState(0);

  const totalSupply = useTotalSupply(farm.poolAddress, farm.chainId);

  useEffect(() => {
    fetch(
      `https://api.dexscreener.com/latest/dex/pairs/pulsechain/${farm.poolAddress}`
    )
      .then((res) => res.json())
      .then((data) => {
        setPoolValueUsd(data.pairs[0].liquidity.usd);
      });
  }, [farm.poolAddress]);

  const farmedBalanceUsd = (poolValueUsd / totalSupply) * farmedBalance;

  useEffect(() => {
    setPendingIncs((prev) => {
      let temp = [...prev];
      temp[index] = +pendingInc;
      return temp;
    });
  }, [pendingInc, index, setPendingIncs]);

  useEffect(() => {
    setFarmedBalances((prev) => {
      let temp = [...prev];
      temp[index] = +farmedBalanceUsd;
      return temp;
    });
  }, [farmedBalanceUsd, index, setFarmedBalances]);

  return (
    <>
      <div
        className={`shadow-lg bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4 col-span-1`}
      >
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium flex-1">{farm.name}</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            {/* <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Pending Rewards
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>${formatNumber(pendingInc * incPrice)}</span>
                <span className="text-sm opacity-50">
                  {formatNumber(+pendingInc)} INC
                </span>
              </div>
            </div> */}
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Staked Balance
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>${formatNumber(farmedBalanceUsd)}</span>
                <span className="text-sm opacity-50">
                  {formatNumber(+farmedBalance)} {farm.symbol}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FarmPool;
