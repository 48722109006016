import { useCall } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/buyandburn.json";

const useGetAvailableCollectBalance = (contract) => {
  const { value, error } =
    useCall(
      contract && {
        contract: new Contract(contract, abi),
        method: "getAvailableCollectBalance",
        args: [],
      }
    ) ?? {};
  if (error) {
    console.error("xx", error.message);
    return [];
  }
  return value ? value.map((v) => utils.formatEther(v)) : [];
};

export default useGetAvailableCollectBalance;
