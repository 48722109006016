import { useEffect, useRef } from "react";
import CloseIcon from "../../assets/images/close.svg";
import { toPng } from "html-to-image";

const formatNumber = (number, decimals = 2) => {
  return (+number).toLocaleString("en-US", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });
};

const ShareModal = ({
  onSelect,
  day,
  currentCyclePayoutIndex,
  globalCyclePayoutUsd,
  currentCyclePayoutAmount,
  userEstPayoutUsd,
  userEstPayout,
  nextCyclePayout,
  progress,
}) => {
  useEffect(() => {
    const html = document.querySelector("html");
    html.style.overflow = "hidden";

    window.scrollTo(0, 0);

    return () => {
      html.style.overflow = "auto";
    };
  }, []);

  const elementRef = useRef(null);

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "dripx-cycle-payout.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="max-h-full overflow-y-auto z-[102] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg bg-[#0C0518] rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
        <div className="flex justify-between items-center">
          <span className=" font-medium text-[19px] w-fit">Share</span>
          <img
            alt="close"
            src={CloseIcon}
            className="cursor-pointer"
            onClick={() => onSelect()}
          />
        </div>
        <div
          ref={elementRef}
          className="flex flex-col gap-6 w-[420px] rounded-lg items-center p-8 bg-gradient-to-br from-pinky from-[-95%] via-black via-[50%] to-[195%] to-yellowy"
        >
          <span className="text-3xl font-bold">
            Drip<span className="text-yellowy">X</span>.win
          </span>
          <div className="flex flex-col gap-1 w-full">
            <span className="self-center opacity-50 mb-4">
              {day}-Day Payout Cycle #{currentCyclePayoutIndex}
            </span>
            <div className="flex justify-between text--400 text-xl">
              <span>Global Cycle Payout</span>
              <div className="flex flex-col text-right text-green-400">
                <span>${globalCyclePayoutUsd.toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  {(+currentCyclePayoutAmount).toFixed(7)} BNB
                </span>
              </div>
            </div>
            <div className="flex justify-between text--400 text-xl">
              <span>Est. Payout</span>
              <div className="flex flex-col text-right text-green-400">
                <span>${userEstPayoutUsd.toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  {userEstPayout.toFixed(7)} BNB
                </span>
              </div>
            </div>
          </div>
          <hr className="w-full h-px bg-slate-500" />
          <div className="flex flex-col w-full gap-1">
            <div className="flex justify-between">
              <span>Next Payout</span>
              <span>
                Day {nextCyclePayout + Math.max(1 * currentCyclePayoutIndex, 0)}
              </span>
            </div>
            <div className="flex w-full justify-between items-center gap-2">
              <div className="w-full h-2 rounded-xl bg-[#D9D9D9] bg-opacity-20 overflow-hidden">
                <div
                  style={{
                    width: `${progress.toFixed(2)}%`,
                  }}
                  className={`h-full bg-gradient-to-r rounded-r-xl from-pinky to-yellowy `}
                />
              </div>
              <span>{progress.toFixed(2)}%</span>
            </div>
          </div>
        </div>
        <button
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic"
          onClick={htmlToImageConvert}
        >
          Download Image
        </button>
        <a
          target="_blank"
          rel="noreferrer"
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic text-center"
          href={`https://twitter.com/intent/tweet?url=https://dripx.win&via=dripxwin&text=${encodeURIComponent(
            `My estimated payout from staking on #DripX is currently at ${formatNumber(
              userEstPayout
            )} $BNB (~$${formatNumber(
              userEstPayoutUsd
            )}).\n\nGlobally, the payout is: ${formatNumber(
              currentCyclePayoutAmount
            )} $BNB (~$${formatNumber(globalCyclePayoutUsd)})\n\n`
          )}`}
        >
          Share on X (Twitter)
        </a>
      </div>
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-[101]"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default ShareModal;
