import "./Stake.module.css";
import { useEffect, useState } from "react";
import { useEthers } from "@usedapp/core";
import { TOKEN_CONTRACT } from "../consts";
import MinerCalculator from "../components/Calculator/MinerCalculator";
import StakeCalculator from "../components/Calculator/StakeCalculator";
import MinerLookup from "../components/Lookup/MinerLookup";

const Lookup = () => {
  const { chainId } = useEthers();

  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [isMiners, setIsMiners] = useState(true);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x172fcd41e0913e95784454622d1c3724f546f849"
      )
        .then((res) => res.json())
        .then((data) => {
          setBnbPrice(data.pairs[0].priceNative);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [wdripPrice, setWdripPrice] = useState(0);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x41e3149918f8EFeE8Ef6f47CF45D4CE580F837cB"
      )
        .then((res) => res.json())
        .then((data) => {
          setWdripPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0xcc9834a4e8b5b22bdfb0fcc7d43430ff9dabedca"
      )
        .then((res) => res.json())
        .then((data) => {
          setTokenPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // useEffect(() => {
  //   let interval = setInterval(async () => {
  //     fetch(
  //       `https://api.dexscreener.com/latest/dex/tokens/${TOKEN_CONTRACT[chainId]}`
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         const pair = data.pairs.filter(
  //           (el) => el.quoteToken.symbol == "WDRIP"
  //         )[0];
  //         setTokenPrice((pair.priceNative ?? 0) * (wdripPrice ?? 0));
  //       });
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [chainId, wdripPrice]);

  return (
    <div className="w-full mt-[95px] py-20 flex flex-col lg:flex-row gap-6 justify-center items-center lg:items-start relative content">
      <div className="shadow-lg w-[95%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Miner Look Up</span>
        </div>
        <MinerLookup bnbPrice={bnbPrice} tokenPrice={tokenPrice} />
      </div>
    </div>
  );
};

export default Lookup;
