import { useEthers, useContractFunction } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/token.json";

const useApprove = (contractAddress) => {
  const { library, account } = useEthers();
  const contract =
    library && contractAddress
      ? new Contract(contractAddress, abi, library.getSigner())
      : null;
  const { send: sendApprove, state } = useContractFunction(contract, "approve");

  const approve = async (spender, amount) => {
    try {
      if (account) {
        const weiAmount = utils.parseUnits((amount + 1).toString(), "ether");
        await sendApprove(spender, weiAmount);
      }
    } catch (error) {
      console.error("Error while approving:", error);
    }
  };

  return { approve, state };
};

export default useApprove;
