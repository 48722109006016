import { NavLink } from "react-router-dom";
import HeaderLogo from "../../assets/images/footer-logo.png";
import Telegram from "../../assets/images/socials/telegram.svg";
import Twitter from "../../assets/images/socials/twitter.svg";
import { TOKEN_CONTRACT, WDRIP } from "../../consts";
import { BSC } from "@usedapp/core";
import { IoIosCopy } from "react-icons/io";
import toast from "react-hot-toast";

const shortAddress = (address) => {
  return address.slice(0, 4) + "..." + address.slice(-4);
};

const Footer = () => {
  return (
    <div className="w-full bg-header flex flex-col md:flex-row gap-12 md:gap-0 px-36 py-12 text-white justify-between  items-center relative">
      <div className="flex-1 flex flex-col items-center md:items-start gap-6">
        <img src={HeaderLogo} className="w-40" />
        <div className="flex flex-col justify-center items-center w-40">
          <span className="flex gap-1 items-center">
            <span className="opacity-50">DRIPX:</span>{" "}
            <span
              className="flex gap-1 items-center hover:text-yellowy transition-all cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(TOKEN_CONTRACT[BSC.chainId]);
                toast.success("Copied to clipboard");
              }}
            >
              {shortAddress(TOKEN_CONTRACT[BSC.chainId])}
              <IoIosCopy />
            </span>
          </span>
          <span className="flex gap-1 items-center">
            <span className="opacity-50">WDRIP:</span>{" "}
            <span
              className="flex gap-1 items-center hover:text-yellowy transition-all cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(WDRIP[BSC.chainId]);
                toast.success("Copied to clipboard");
              }}
            >
              {shortAddress(WDRIP[BSC.chainId])}
              <IoIosCopy />
            </span>
          </span>
        </div>
        <div className="flex-1 flex md:hidden gap-8 justify-center md:justify-end">
          <a href="https://t.me/DRIPXWIN" target="_blank" rel="noreferrer">
            <img src={Telegram} className="w-fit" />
          </a>
          <a
            href="https://twitter.com/dripxwin"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} className="w-fit" />
          </a>
        </div>
        {/* <p className='text-xs font-normal text-center md:text-left'>
          Exploring the Power of Deflationary Swaps on the Blockchain.
        </p> */}
      </div>
      <div className="hidden md:flex-1"></div>
      <div className="hidden lg:flex flex-1"></div>
      <div className="hidden lg:flex gap-12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 justify-between">
        <div className="flex flex-col gap-4">
          <NavLink to="/mine">Mine</NavLink>
          <NavLink to="/faucets">Faucets</NavLink>
          <NavLink to="/stake">Stake</NavLink>
          <a
            href="https://dripx-win.gitbook.io/dripx/dripx-info/dripx-fundamentals"
            rel="noreferrer"
            target="_blank"
          >
            Documentation
          </a>
        </div>
        <div className="flex flex-col gap-4">
          <NavLink to="/payouts">Payouts</NavLink>
          <NavLink to="/buyandburn">Buy & Burn</NavLink>
          <NavLink to="/swap">Buy DRIPX</NavLink>
          {/* <a>App</a> */}
        </div>
      </div>
      <div className="flex flex-1 md:flex-col gap-12">
        <div className="flex-1 hidden md:flex gap-8 justify-end">
          <a href="https://t.me/DRIPXWIN" target="_blank" rel="noreferrer">
            <img src={Telegram} className="w-fit" />
          </a>
          <a
            href="https://twitter.com/dripxwin"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Twitter} className="w-fit" />
          </a>
        </div>
        <div className="flex lg:hidden justify-between gap-8">
          <div className="flex flex-col gap-4">
            <NavLink to="/mine">Mine</NavLink>
            <NavLink to="/stake">Stake</NavLink>
            <a
              href="https://dripx-win.gitbook.io/dripx/dripx-info/dripx-fundamentals"
              rel="noreferrer"
              target="_blank"
            >
              Documentation
            </a>
          </div>
          <div className="flex flex-col gap-4">
            <NavLink to="/payouts">Payouts</NavLink>
            <NavLink to="/buyandburn">Buy & Burn</NavLink>
            <NavLink to="/swap">Buy DRIPX</NavLink>
            {/* <a>App</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
