import { BSC, useCall, useEthers } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/stakes.json";
import { STAKE_CONTRACT } from "../../../consts";

const useUserAvailableRewardsAll = () => {
  const { account } = useEthers();

  const { value, error } =
    useCall(
      account &&
        STAKE_CONTRACT[BSC.chainId] && {
          contract: new Contract(STAKE_CONTRACT[BSC.chainId], abi),
          method: "getUserAvailableRewardsAll",
          args: [account],
        }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? utils.formatUnits(value?.[0], 18) : 0;
};

export default useUserAvailableRewardsAll;
