import { useEffect, useRef } from "react";
import CloseIcon from "../../assets/images/close.svg";
import { toPng } from "html-to-image";

const formatNumber = (number, decimals = 2) => {
  return (+number).toLocaleString("en-US", {
    maximumFractionDigits: decimals,
    minimumFractionDigits: decimals,
  });
};

const ShareModal = ({ onSelect, stats }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const elementRef = useRef(null);

  const htmlToImageConvert = () => {
    toPng(elementRef.current, { cacheBust: false })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "dripx-faucet.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="max-h-full overflow-y-auto z-[102] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg bg-[#0C0518] rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
        <div className="flex justify-between items-center">
          <span className=" font-medium text-[19px] w-fit">Share</span>
          <img
            alt="close"
            src={CloseIcon}
            className="cursor-pointer"
            onClick={() => onSelect()}
          />
        </div>
        <div
          ref={elementRef}
          className="flex flex-col gap-6 w-[420px] rounded-lg items-center p-8 bg-gradient-to-br from-pinky from-[-95%] via-black via-[50%] to-[195%] to-yellowy"
        >
          <span className="text-3xl font-bold">
            Drip<span className="text-yellowy">X</span>.win
          </span>
          <div className="flex flex-col gap-1 w-full">
            <span className="self-center opacity-50">
              {+stats.progress !== 100 ? "Active" : "Ended"} Faucet ROI
            </span>
            <div className="flex justify-between text-blue-400 text-xl">
              <span>DRIPX at End</span>
              <span>{stats.valueAtExpiry}</span>
            </div>
            <div className="flex justify-between text-blue-400 text-xl">
              <span>Daily DRIPX Rewards</span>
              <span>+{formatNumber(stats.dailyReward)}</span>
            </div>
            <div className="flex justify-between text-orange-400 text-xl">
              <span>Cost</span>
              <span>${formatNumber(stats.cost.replace("$", ""))}</span>
            </div>
            <div className="flex justify-between text-green-400 text-xl">
              <span>Market Value</span>
              <span>${formatNumber(stats.marketValue.replace("$", ""))}</span>
            </div>
          </div>
          <hr className="w-full h-px bg-slate-500" />
          <div className="flex flex-col w-full gap-1">
            <div className="flex justify-between">
              <span>Daily ROI</span>
              <span>{stats.dailyRoi}</span>
            </div>
            <div className="flex justify-between">
              <span>Progress</span>
              <span>{stats.progress}</span>
            </div>
          </div>
          <hr className="w-full h-px bg-slate-500" />
          <div className="flex flex-col gap-1 text-center text-green-400">
            <span className="text-lg">ROI</span>
            <span className="text-2xl">
              +{formatNumber(stats.roi.replace("%", ""))}%
            </span>
          </div>
        </div>
        <button
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic"
          onClick={htmlToImageConvert}
        >
          Download Image
        </button>
        <a
          target="_blank"
          rel="noreferrer"
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic text-center"
          href={`https://twitter.com/intent/tweet?url=https://dripx.win&via=dripxwin&text=${encodeURIComponent(
            `My ${stats.cost} faucet on #DripX will end with +${formatNumber(
              stats.roi.replace("%", "")
            )}% ROI with ${stats.valueAtExpiry} $DRIPX ($${formatNumber(
              stats.marketValue.replace("$", "")
            )}) in it.\n\nIt's printing ${stats.dailyReward} $DRIPX daily!\n\n`
          )}`}
        >
          Share on X (Twitter)
        </a>
      </div>
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-[101]"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default ShareModal;
