import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Layout from "./components/Layout";
import Miner from "./pages/Miner";
import Stake from "./pages/Stake";
import Payouts from "./pages/Payouts";
import BuyAndBurn from "./pages/BuyAndBurn";
import BuyNow from "./pages/BuyNow";
import Calculator from "./pages/Calculator";
import Profile from "./pages/Profile";
import Vault from "./pages/Vault";
import Swap from "./pages/Swap";
import Lookup from "./pages/Lookup";
import Admin from "./pages/Admin";
import Faucets from "./pages/Faucets";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/swap" replace />,
      },
      {
        path: "/stake",
        element: <Stake />,
      },
      {
        path: "/payouts",
        element: <Payouts />,
      },
      {
        path: "/mine",
        element: <Miner />,
      },
      {
        path: "/vault",
        element: <Vault />,
      },
      {
        path: "/reservoir",
        element: <BuyAndBurn />,
      },
      {
        path: "/buynow",
        element: <BuyNow />,
      },
      {
        path: "/swap",
        element: <Swap />,
      },
      {
        path: "/calculator",
        element: <Calculator />,
      },
      {
        path: "/lookup",
        element: <Lookup />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/admin",
        element: <Admin />,
      },
      {
        path: "/faucets",
        element: <Faucets />,
      },
      {
        path: "*",
        element: <Navigate to="/mine" replace />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
