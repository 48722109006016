import { useEthers } from "@usedapp/core";
import { createPortal } from "react-dom";
import useDisableScroll from "../../hooks/useDisableScroll";

const ChainSwitch = ({ targetChain }) => {
  const { chainId, switchNetwork, account } = useEthers();
  console.log(targetChain, chainId, targetChain.includes(chainId));
  useDisableScroll(account && targetChain && !targetChain.includes(chainId));
  return (
    account &&
    targetChain &&
    !targetChain.includes(chainId) &&
    createPortal(
      <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[rgba(0,0,0,0.4)] transition-opacity ease-in z-10">
        <div className="max-w-xs w-full m-5 bg- bg-bg bg-opacity-80 border-2 border-pinky p-5 rounded-xl relative z-20 text-white gap-4 flex flex-col">
          <div className="flex text-white items-center justify-center text-2xl">
            Wrong chain!
          </div>
          <button
            onClick={() => switchNetwork?.(targetChain[0])}
            className="w-full px-3 py-3 rounded-xl my-3 text-lg flex items-center justify-center text-light-200 font-bold bg-pinky border-2 border-pinky hover:bg-opacity-40 uppercase transition-colors"
          >
            Change Chain
          </button>
        </div>
      </div>,
      document.body
    )
  );
};

export default ChainSwitch;
