import { useEthers, useContractFunction} from "@usedapp/core";
import { Contract } from "ethers";
import { STAKE_CONTRACT } from "../../../consts";
import abi from "../../../contracts/buyandburn.json";

const useActivate = (contract_) => {
  const { library, account } = useEthers();
  const contract =
    library && contract_
      ? new Contract(contract_, abi, library.getSigner())
      : null;
  const { send: sendActivate, state } = useContractFunction(
    contract,
    "activate"
  );

  const activate = async () => {
    try {
      if (account) {
        await sendActivate();
      }
    } catch (error) {
      console.error("Error while activating:", error);
    }
  };

  return { activate, state };
};

export default useActivate;
