import { useEtherBalance, useEthers } from "@usedapp/core";
import ArrowIcon from "../assets/images/arrow.svg";
import dripXToken from "../assets/images/dripx_token.jpg";
import bnbLogo from "../assets/images/bnb-logo.svg";
import { useEffect, useMemo, useState } from "react";
import { TOKEN_CONTRACT } from "../consts";
import useTokenBalance from "../hooks/useTokenBalance";
import useUserBonds from "../hooks/Faucet/read/useUserBonds";
import usePlayers from "../hooks/Faucet/read/usePlayers";
import useAvailableRewards from "../hooks/Faucet/read/useAvailableRewards";
import useDeposit from "../hooks/Faucet/write/useDeposit";
import useClaimAll from "../hooks/Faucet/write/useClaimAll";
import useTxToast from "../hooks/useTxToast";
import TooltipWrapper from "../components/Shared/TooltipWrapper";
import Tooltips from "../assets/tooltips";
import { createPortal } from "react-dom";
import { formatEther, parseEther } from "ethers/lib/utils";
import useUniswapV3QuoteIn from "../hooks/useUniswapV3QuoteIn";
import useUniswapV3QuoteOut from "../hooks/useUniswapV3QuoteOut";
import FaucetsModal from "../components/Faucets/FaucetsModal";

const Faucets = () => {
  const { account, chainId } = useEthers();

  const [showFaucets, setShowFaucets] = useState(false);
  const [amount, setAmount] = useState();
  const [lastTouched, setLastTouched] = useState("bnb");

  const [tokenPrice, setTokenPrice] = useState(0);
  const [bnbPrice, setBnbPrice] = useState(0);

  const tokenBalance = useTokenBalance(account, TOKEN_CONTRACT[chainId]);
  const bnbBalanceUnformatted = useEtherBalance(account);

  const bnbBalance = useMemo(() => {
    return bnbBalanceUnformatted
      ? parseFloat(formatEther(bnbBalanceUnformatted)).toFixed(6)
      : 0;
  }, [bnbBalanceUnformatted]);

  const bnbAmount = useUniswapV3QuoteOut(
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    TOKEN_CONTRACT[chainId],
    10000,
    parseEther(amount !== undefined && amount.length >= 1 ? amount : "0")
  );

  const dripxAmount = useUniswapV3QuoteIn(
    "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    TOKEN_CONTRACT[chainId],
    10000,
    parseEther(amount !== undefined && amount.length >= 1 ? amount : "0")
  );

  const valueAtExpiry = useMemo(
    () =>
      parseFloat(
        lastTouched === "bnb" ? formatEther(dripxAmount ?? 0) : amount
      ).toFixed(2) *
      2 *
      2,
    [dripxAmount, amount, lastTouched]
  );

  const usdPriceOfMintCost = useMemo(
    () =>
      tokenPrice *
        parseFloat(
          lastTouched === "bnb" ? formatEther(dripxAmount ?? 0) : amount
        ).toFixed(2) +
      bnbPrice *
        parseFloat(
          lastTouched === "dripx" ? formatEther(bnbAmount ?? 0) : amount
        ),
    [tokenPrice, bnbPrice, amount, dripxAmount, lastTouched, bnbAmount]
  );

  const marketValueAtExpiry = useMemo(
    () => valueAtExpiry * tokenPrice,
    [valueAtExpiry, tokenPrice]
  );

  const estROI = useMemo(
    () =>
      ((marketValueAtExpiry - usdPriceOfMintCost) / usdPriceOfMintCost) * 100,
    [marketValueAtExpiry, usdPriceOfMintCost]
  );

  const userBonds = useUserBonds();
  console.log(userBonds);
  const playerStats = usePlayers();
  const availableRewards = useAvailableRewards();

  const { deposit, state: depositState } = useDeposit();
  const { claimAll, state: claimAllState } = useClaimAll();

  useTxToast(depositState, "Faucet created successfully");
  useTxToast(claimAllState, "Claimed successfully");

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x172fcd41e0913e95784454622d1c3724f546f849"
      )
        .then((res) => res.json())
        .then((data) => {
          setBnbPrice(data.pairs[0].priceNative);
        });

      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0xcc9834a4e8b5b22bdfb0fcc7d43430ff9dabedca"
      )
        .then((res) => res.json())
        .then((data) => {
          setTokenPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="w-full mt-[95px] py-20 flex flex-col lg:flex-row gap-6 justify-center items-center lg:items-start relative content">
      <div className="shadow-lg w-[85%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Create a faucet</span>
          {account && (
            <span
              className="text-lg self-end flex gap-2 cursor-pointer bg-gradient-to-r from-pinky to-yellowy px-2 rounded-md"
              onClick={() => setShowFaucets(true)}
            >
              Manage faucets
              <img alt="go" src={ArrowIcon} />
            </span>
          )}
        </div>{" "}
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col gap-2 text-white">
            <div className="w-full text-right text-base font-medium pr-[6px]">
              <span className="text-xs opacity-50 mr-1 font-normal">
                Balance
              </span>
              {bnbBalance}
            </div>
            <div className="bg-[#0C0518] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[16px] pt-4 pb-4 px-6 flex flex-col items-end relative">
              <input
                className="h-[1.875rem] font-medium text-3xl bg-transparent outline-none text-right w-[calc(100%-2.25rem-42px)]"
                onChange={({ target }) => {
                  setLastTouched("bnb");
                  setAmount(target.value);
                }}
                value={
                  lastTouched === "bnb"
                    ? amount
                    : bnbAmount
                    ? parseFloat(formatEther(bnbAmount)).toFixed(6)
                    : undefined
                }
                placeholder="0.000"
              />
              <div className="opacity-50 text-base">
                ${" "}
                {(
                  +(lastTouched === "bnb"
                    ? amount
                    : parseFloat(formatEther(bnbAmount ?? 0)).toFixed(6) ?? 0) *
                  bnbPrice
                ).toFixed(2)}
              </div>
              <div className="flex gap-2 mt-4">
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setAmount(bnbBalance * 0.25 + "");
                  }}
                >
                  25%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setAmount(bnbBalance * 0.5 + "");
                  }}
                >
                  50%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setAmount(bnbBalance * 0.75 + "");
                  }}
                >
                  75%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] bg-pinky text-sm font-regular"
                  onClick={() => {
                    setAmount(bnbBalance + "");
                  }}
                >
                  MAX
                </div>
              </div>
              <div className="leading-4 absolute left-6 w-[52px] top-0 md:top-1/2 transform -translate-y-[calc(50%-0.75rem)] md:-translate-y-1/2 flex flex-col h-full justify-center gap-1 pt-4 pb-4 items-center">
                <img
                  alt="from-icon"
                  className="rounded-full w-[52px] h-[52px]"
                  src={bnbLogo}
                />
                BNB
              </div>
            </div>
          </div>
          <span className="text-center text-4xl text-white">+</span>
          <div className="flex flex-col gap-2 text-white">
            <div className="w-full text-right text-base font-medium pr-[6px]">
              <span className="text-xs opacity-50 mr-1 font-normal">
                Balance
              </span>
              {tokenBalance}
            </div>
            <div className="bg-[#0C0518] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[16px] pt-4 pb-4 px-6 flex flex-col items-end relative">
              <input
                className="h-[1.875rem] font-medium text-3xl bg-transparent outline-none text-right w-[calc(100%-2.25rem-42px)]"
                onChange={({ target }) => {
                  setLastTouched("dripx");
                  setAmount(target.value);
                }}
                value={
                  lastTouched === "dripx"
                    ? amount
                    : dripxAmount
                    ? parseFloat(formatEther(dripxAmount ?? 0)).toFixed(2)
                    : undefined
                }
                placeholder="0.000"
              />
              <div className="opacity-50 text-base">
                ${" "}
                {(
                  +(lastTouched === "dripx"
                    ? amount
                    : parseFloat(formatEther(dripxAmount ?? 0)).toFixed(2) ??
                      0) * tokenPrice
                ).toFixed(2)}
              </div>
              <div className="flex gap-2 mt-4">
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setAmount(tokenBalance * 0.25 + "");
                  }}
                >
                  25%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setAmount(tokenBalance * 0.5 + "");
                  }}
                >
                  50%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] text-sm font-regular"
                  onClick={() => {
                    setAmount(tokenBalance * 0.75 + "");
                  }}
                >
                  75%
                </div>
                <div
                  className="cursor-pointer flex px-2 box-border border-[1.5px] border-pinky rounded-[30px] bg-pinky text-sm font-regular"
                  onClick={() => {
                    setAmount(tokenBalance + "");
                  }}
                >
                  MAX
                </div>
              </div>
              <div className="leading-4 absolute left-6 w-[52px] top-0 md:top-1/2 transform -translate-y-[calc(50%-0.75rem)] md:-translate-y-1/2 flex flex-col h-full justify-center gap-1 pt-4 pb-4 items-center">
                <img
                  alt="from-icon"
                  className="rounded-full"
                  src={dripXToken}
                />
                DRIPX
              </div>
            </div>
          </div>
        </div>
        <button
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-2xl text-white not-italic"
          onClick={async () =>
            await deposit(
              lastTouched === "bnb" ? dripxAmount : parseEther(amount),
              lastTouched === "dripx" ? bnbAmount : parseEther(amount)
            )
          }
        >
          Create faucet
        </button>
      </div>
      <div className="shadow-lg w-[85%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Summary & Estimated ROI</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-blue-400">
              <span className="opacity-50 flex gap-2 items-center">
                Est. DRIPX at End of Faucet
                <TooltipWrapper title={Tooltips.FAUCETS.EST_DRIPX_AT_END} />
              </span>
              <span>{valueAtExpiry.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-green-400">
              <span className="opacity-50  flex gap-2 items-center">
                $ Market Value of Faucet
                <TooltipWrapper title={Tooltips.FAUCETS.MARKET_VALUE_MINER} />
              </span>
              <span>${marketValueAtExpiry.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-green-400">
              <span className="opacity-50  flex gap-2 items-center">
                Est. ROI % at End of Faucet
              </span>
              <span>100.00%</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">DRIPX Details</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                DRIPX Market Price
                <TooltipWrapper title={Tooltips.FAUCETS.DRIPX_MARKET_PRICE} />
              </span>
              <span>${(+tokenPrice).toFixed(7)}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                BNB Market Price
                <TooltipWrapper title={Tooltips.FAUCETS.BNB_MARKET_PRICE} />
              </span>
              <span>${(+bnbPrice).toFixed(2)}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Faucet Details</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Daily ROI
                <TooltipWrapper title={Tooltips.FAUCETS.DAILY_ROI} />
              </span>
              <span>0.50%</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Max. Daily ROI
                <TooltipWrapper title={Tooltips.FAUCETS.MAX_DAILY_ROI} />
              </span>
              <span>4.00%</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Max. Total ROI
                <TooltipWrapper title={Tooltips.FAUCETS.MAX_TOTAL_ROI} />
              </span>
              <span>200.00%</span>
            </div>
          </div>
        </div>
      </div>
      {showFaucets &&
        createPortal(
          <FaucetsModal
            onSelect={() => setShowFaucets(false)}
            bnbPrice={bnbPrice}
            tokenPrice={tokenPrice}
          />,
          document.body
        )}
    </div>
  );
};

export default Faucets;
