import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/miners.json";
import { MINER_CONTRACT } from "../../../consts";

const useUpdateMaxDripMintingOnDay = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(MINER_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendUpdateMaxDripMintingOnDay, state } = useContractFunction(
    contract,
    "updateMaxDripMintingOnDay"
  );

  const updateMaxDripMintingOnDay = async (newMax) => {
    try {
      if (account) {
        await sendUpdateMaxDripMintingOnDay(utils.parseUnits(newMax + "", 18));
      }
    } catch (error) {
      console.error("Error while activating:", error);
    }
  };

  return { updateMaxDripMintingOnDay, state };
};

export default useUpdateMaxDripMintingOnDay;
