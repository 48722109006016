import { useMemo, useState } from "react";
import useMiners from "../../hooks/Lookup/read/useMiners";
import { utils } from "ethers";
import useMinerMintPowerBonus from "../../hooks/Lookup/read/useMinerMintPowerBonus";
import useMinerName from "../../hooks/Lookup/read/useMinerName";

const MinerLookup = ({ tokenPrice, bnbPrice }) => {
  const [tokenId, setTokenId] = useState();
  const [tempTokenId, setTempTokenId] = useState();

  const miner = useMiners(tokenId);
  const minersMintPowerBonus = useMinerMintPowerBonus(tokenId);
  const minerName = useMinerName(tokenId);

  const minerData = useMemo(() => {
    if (miner.length === 0)
      return {
        dRank: 0,
        name: "Unnamed",
        power: 0,
        period: 0,
        valueAtExpiry: 0,
        dRankBonus: 0,
        cost: 0,
        marketValue: 0,
        roi: 0,
        endTimestamp: 0,
        startTimestamp: 0,
        vyperReadyTimestamp: 0,
        progress: 0,
        active: false,
      };
    const dRankBonus = utils.formatUnits(minersMintPowerBonus, 18 + 7);
    const marketValueAtExpiry =
      (+utils.formatUnits(miner[4], 18) + +dRankBonus) * tokenPrice;
    const usdPriceOfMintCost = utils.formatUnits(miner[9], 18) * bnbPrice;
    const roi =
      ((marketValueAtExpiry - usdPriceOfMintCost) / usdPriceOfMintCost) * 100;
    const startTimestamp = utils.formatUnits(miner[7], 0);
    const endTimestamp = utils.formatUnits(miner[8], 0);
    const vyperReadyTimestamp =
      +startTimestamp + +utils.formatUnits(miner[3], 0) * 0.51 * 86400;
    const progress = Math.min(
      100,
      ((Date.now() / 1000 - startTimestamp) / (endTimestamp - startTimestamp)) *
        100
    );
    const id = utils.formatUnits(miner[0], 0);

    const shareStats = {
      valueAtExpiry: (+utils.formatUnits(miner[4], 18)).toFixed(2),
      dRankBonus,
      cost: "$" + usdPriceOfMintCost.toFixed(2),
      marketValue: "$" + (+marketValueAtExpiry).toFixed(2),
      roi: roi.toFixed(2) + "%",
      endTimestamp,
      startTimestamp,
      bnbCost: utils.formatUnits(miner[9], 18),
    };

    return {
      dRank: id,
      name: minerName,
      power: utils.formatUnits(miner[1], 0),
      period: utils.formatUnits(miner[3], 0),
      ...shareStats,
      vyperReadyTimestamp,
      progress: progress.toFixed(2) + "%",
      active: miner[11],
    };
  }, [bnbPrice, miner, minersMintPowerBonus, tokenPrice, minerName]);

  return (
    <>
      <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-1 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col">
          <div className="flex flex-col gap-2 justify-between text-sm text-white">
            <span className="opacity-50  flex gap-2 items-center">
              Miner DRank
            </span>
            <div className="bg-[#0C0518] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[16px] pt-4 pb-4 px-6 flex flex-col items-end relative">
              <input
                className="font-medium text-2xl bg-transparent outline-none text-right w-[calc(100%)]"
                onChange={({ target }) => {
                  setTempTokenId(target.value);
                }}
                value={tempTokenId}
                placeholder="0"
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-2xl text-white not-italic"
        onClick={() => setTokenId(tempTokenId)}
      >
        Look up
      </button>
      <div className="flex justify-between text-white">
        <span className="text-xl font-medium">Miner Information</span>
      </div>
      <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col text-white">
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">DRank</span>
            <span>{minerData.dRank}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">Name</span>
            <span>{minerData.name}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">Period</span>
            <span>{minerData.period}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">Power</span>
            <span>{minerData.power}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">
              Est. DRIPX
            </span>
            <span>{minerData.valueAtExpiry}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">
              DRank Bonus
            </span>
            <span>{minerData.dRankBonus}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">Cost</span>
            <span>{minerData.cost}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">
              Market Value
            </span>
            <span>{minerData.marketValue}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">ROI</span>
            <span>{minerData.roi}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">Progress</span>
            <span>{minerData.progress}</span>
          </div>
          <div className="flex justify-between text-md ">
            <span className="opacity-50 flex gap-2 items-center">Claimed</span>
            <span>{minerData.active ? "No" : "Yes"}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinerLookup;
