import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract } from "ethers";
import { MINER_CONTRACT } from "../../../consts";
import abi from "../../../contracts/miners.json";

const useUpdateMinerName = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(MINER_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendUpdateMinerName, state } = useContractFunction(
    contract,
    "updateMinerName"
  );

  const updateMinerName = async (tokenId, name) => {
    try {
      if (account) {
        await sendUpdateMinerName(tokenId, name);
      }
    } catch (error) {
      console.error("Error while contributing:", error);
    }
  };

  return { updateMinerName, state };
};

export default useUpdateMinerName;
