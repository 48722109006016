import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract, utils } from "ethers";
import { STAKE_CONTRACT } from "../../../consts";
import abi from "../../../contracts/stakes.json";

const useStartStake = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(STAKE_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendStartStake, state } = useContractFunction(
    contract,
    "startState"
  );

  const startStake = async (quantity, duration) => {
    try {
      if (account) {
        await sendStartStake(utils.parseEther(quantity), duration);
      }
    } catch (error) {
      console.error("Error while staking:", error);
    }
  };

  return { startStake, state };
};

export default useStartStake;
