import { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import { ConfigProvider, Table, theme } from "antd";
import useUserMiners from "../../hooks/Miners/read/useUserMiners";
import { utils } from "ethers";
import Countdown from "../Shared/Countdown";
import useGlobalMintPower from "../../hooks/Miners/read/useGlobalMintPower";
import useClaimMint from "../../hooks/Miners/write/useClaimMint";
import useTxToast from "../../hooks/useTxToast";
import { createPortal } from "react-dom";
import ShareModal from "./ShareModal";
import { IoPencil, IoShareOutline } from "react-icons/io5";
import useVyperMigrateMint from "../../hooks/Miners/write/useVyperMigrateMint";
import useClaimAll from "../../hooks/Miners/write/useClaimAll";
import useMinersMintPowerBonus from "../../hooks/Miners/read/useMinersMintPowerBonus";
import useUserMinersName from "../../hooks/Miners/read/useUserMinersName";
import RenameMinerModal from "./RenameMinerModal";

const columns = [
  {
    title: "DRank",
    dataIndex: "dRank",
    key: "dRank",
    width: 100,
    sorter: (a, b) => a.dRank - b.dRank,
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 100,
  },
  {
    title: "Miner Details",
    children: [
      {
        title: "Period",
        dataIndex: "period",
        key: "period",
        width: 100,
        sorter: (a, b) => a.period - b.period,
      },
      {
        title: "Power",
        dataIndex: "power",
        key: "power",
        width: 100,
        sorter: (a, b) => a.power - b.power,
      },
    ],
  },
  {
    title: "Est. DRIPX",
    dataIndex: "valueAtExpiry",
    key: "valueAtExpiry",
    width: 150,
    sorter: (a, b) => a.valueAtExpiry - b.valueAtExpiry,
  },
  {
    title: "DRank Bonus",
    dataIndex: "dRankBonus",
    key: "dRankBonus",
    width: 150,
    sorter: (a, b) => a.dRankBonus - b.dRankBonus,
  },
  {
    title: "Miner ROI",
    children: [
      {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
        width: 75,
        sorter: (a, b) => a.cost.replace("$", "") - b.cost.replace("$", ""),
      },
      {
        title: "Market Value",
        dataIndex: "marketValue",
        key: "marketValue",
        width: 75,
        sorter: (a, b) =>
          a.marketValue.replace("$", "") - b.marketValue.replace("$", ""),
      },
      {
        title: "ROI",
        dataIndex: "roi",
        key: "roi",
        width: 75,
        sorter: (a, b) => a.roi.replace("%", "") - b.roi.replace("%", ""),
      },
    ],
  },
  {
    title: "Progress",
    dataIndex: "progress",
    key: "progress",
    width: 100,
    sorter: (a, b) => a.progress.replace("%", "") - b.progress.replace("%", ""),
  },
  {
    title: "Redeem",
    dataIndex: "redeem",
    key: "redeem",
    width: 100,
    sorter: (a, b) => a.endTimestamp - b.endTimestamp,
  },
  {
    title: "Vyper",
    dataIndex: "vyperReady",
    key: "vyperReady",
    width: 100,
    sorter: (a, b) => a.vyperReadyTimestamp - b.vyperReadyTimestamp,
  },
  {
    title: "Share",
    dataIndex: "share",
    key: "share",
    width: 60,
  },
];

const calculateMintPowerBonus = (
  mintPowerBonus,
  mintPower,
  gMintPower,
  _globalMintPower,
  id
) => {
  if (_globalMintPower - gMintPower <= 0) {
    return 0;
  }
  return (mintPowerBonus * mintPower * (_globalMintPower - gMintPower)) / 100;
};

const Show = {
  Active: "Active",
  Claimable: "Claimable",
  Ended: "Ended",
  VyperReady: "VyperReady",
};

const MinersModal = ({ onSelect, tokenPrice }) => {
  const [bnbPrice, setBnbPrice] = useState(0);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    fetch(
      "https://api.dexscreener.com/latest/dex/pairs/bsc/0x172fcd41e0913e95784454622d1c3724f546f849"
    )
      .then((res) => res.json())
      .then((data) => {
        setBnbPrice(data.pairs[0].priceNative);
      });
  }, []);

  const globalMintPower = useGlobalMintPower();
  const userMiners = useUserMiners();
  const minersMintPowerBonus = useMinersMintPowerBonus();
  const minersName = useUserMinersName();

  const [showRenameMiner, setShowRenameMiner] = useState(undefined);
  const [showShareModal, setShowShareModal] = useState(undefined);
  const [show, setShow] = useState(Show.Active);

  const { claimMint, state: claimMintState } = useClaimMint();
  const { claimAll, state: claimAllState } = useClaimAll();
  const { vyperMigrateMint, state: vyperMigrateMintState } =
    useVyperMigrateMint();

  useTxToast(claimMintState, "Claimed Mint");
  useTxToast(claimAllState, "Claimed All");
  useTxToast(vyperMigrateMintState, "Migrated Mint");

  const data = userMiners.map((miner, index) => {
    const mintPower = utils.formatUnits(miner[1], 0);
    const mintPowerBonus = utils.formatUnits(miner[5], 7);
    const gMintPower = utils.formatUnits(miner[2], 0);

    // const dRankBonus = calculateMintPowerBonus(
    //   mintPowerBonus,
    //   mintPower,
    //   gMintPower,
    //   globalMintPower,
    //   utils.formatUnits(miner[0], 0)
    // ).toFixed(2);

    const name = minersName[index] || "Unnamed";
    const dRankBonus = utils.formatUnits(minersMintPowerBonus[index], 18 + 7);

    const marketValueAtExpiry =
      (+utils.formatUnits(miner[4], 18) + +dRankBonus) * tokenPrice;
    const usdPriceOfMintCost = utils.formatUnits(miner[9], 18) * bnbPrice;
    const roi =
      ((marketValueAtExpiry - usdPriceOfMintCost) / usdPriceOfMintCost) * 100;
    const startTimestamp = utils.formatUnits(miner[7], 0);
    const endTimestamp = utils.formatUnits(miner[8], 0);
    const vyperReadyTimestamp =
      +startTimestamp + +utils.formatUnits(miner[3], 0) * 0.51 * 86400;
    const progress = Math.min(
      100,
      ((Date.now() / 1000 - startTimestamp) / (endTimestamp - startTimestamp)) *
        100
    );
    const id = utils.formatUnits(miner[0], 0);

    const shareStats = {
      valueAtExpiry: (+utils.formatUnits(miner[4], 18)).toFixed(2),
      dRankBonus,
      cost: "$" + usdPriceOfMintCost.toFixed(2),
      marketValue: "$" + (+marketValueAtExpiry).toFixed(2),
      roi: roi.toFixed(2) + "%",
      endTimestamp,
      startTimestamp,
      bnbCost: utils.formatUnits(miner[9], 18),
    };

    return {
      dRank: id,
      name: (
        <div className="flex justify-between items-center">
          {name}{" "}
          <IoPencil
            className="text-blue-400 cursor-pointer"
            onClick={() => setShowRenameMiner(id)}
          />
        </div>
      ),
      power: utils.formatUnits(miner[1], 0),
      period: utils.formatUnits(miner[3], 0),
      ...shareStats,
      vyperReadyTimestamp,
      progress: progress.toFixed(2) + "%",
      active: miner[11],
      redeem:
        Date.now() > endTimestamp * 1000 || !miner[11] ? (
          <button
            disabled={!miner[11]}
            onClick={async () => await claimMint(id)}
            className={`${
              miner[11] ? "opacity-100" : "opacity-30"
            } w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic`}
          >
            {miner[11] ? "Claim" : "Ended"}
          </button>
        ) : (
          <Countdown targetDate={endTimestamp * 1000} />
        ),
      vyperReady:
        Date.now() > vyperReadyTimestamp * 1000 ? (
          <div className="relative group">
            <div className="w-full h-full bg-gradient-to-r from-red-400 to-orange-600 z-[5] rounded-lg opacity-0 group-hover:opacity-100 group-hover:animate-[ping_1s_ease-in-out] absolute top-0 left-0"></div>
            <button
              disabled={!miner[11]}
              onClick={async () => await vyperMigrateMint(id)}
              className={`${
                miner[11] ? "opacity-100 animate-bg" : "opacity-30"
              } w-full bg-gradient-to-r from-red-400 via-orange-400 to-yellow-500 z-[10] relative shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic`}
            >
              {miner[11] ? "Vyper" : "Ended"}
            </button>
          </div>
        ) : (
          <Countdown targetDate={vyperReadyTimestamp * 1000} />
        ),
      share: (
        <button
          className="text-xl font-bold bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg ml-[50%] -translate-x-5 text-white not-italic"
          onClick={() => setShowShareModal(shareStats)}
        >
          <IoShareOutline />
        </button>
      ),
    };
  });

  return (
    <>
      <div className="max-h-full overflow-y-auto z-[100] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg w-[95%] bg-[#0C0518] rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
        <div className="flex justify-between items-center">
          <span className=" font-medium text-[19px] w-fit">Your Miners</span>
          <img
            alt="close"
            src={CloseIcon}
            className="cursor-pointer"
            onClick={() => onSelect()}
          />
        </div>
        <div className="flex gap-1 -mb-4">
          <div
            className={`${
              show === Show.Active ? "opacity-100" : "opacity-50"
            } transition-all bg-[#191325] min-w-[70px] text-center text-white py-2 px-2 w-fit rounded-t-lg cursor-pointer`}
            onClick={() => setShow(Show.Active)}
          >
            Active
          </div>
          <div
            className={`${
              show === Show.VyperReady ? "opacity-100" : "opacity-50"
            } transition-all bg-[#191325] min-w-[70px] text-center text-white py-2 px-2 w-fit rounded-t-lg cursor-pointer`}
            onClick={() => setShow(Show.VyperReady)}
          >
            Vyper
          </div>
          <div
            className={`${
              show === Show.Claimable ? "opacity-100" : "opacity-50"
            } transition-all bg-[#191325] min-w-[70px] text-center text-white py-2 px-2 w-fit rounded-t-lg cursor-pointer`}
            onClick={() => setShow(Show.Claimable)}
          >
            Claimable
          </div>
          <div
            className={`${
              show === Show.Ended ? "opacity-100" : "opacity-50"
            } transition-all bg-[#191325] min-w-[70px] text-center text-white py-2 px-2 w-fit rounded-t-lg cursor-pointer`}
            onClick={() => setShow(Show.Ended)}
          >
            Ended
          </div>
        </div>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
            token: {
              // Seed Token
              colorPrimary: "#fff",
              borderRadius: 2,

              // Alias Token
              colorBgContainer: "#D9D9D902",
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={data.filter((miner) => {
              if (show === Show.VyperReady)
                return (
                  Date.now() > miner.vyperReadyTimestamp * 1000 && miner.active
                );
              if (show === Show.Claimable)
                return Date.now() > miner.endTimestamp * 1000 && miner.active;
              if (show === Show.Ended) return !miner.active;
              if (show === Show.Active) return miner.active;
            })}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: "400px",
            }}
            //make the table dark mode
            className="rouned-xl rounded-lg scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-700 scrollbar-track-slate-300"
            pagination={false}
            summary={(pageData) => {
              let totalValueAtExpiry = 0;
              let totalDRankBonus = 0;
              let totalCost = 0;
              let totalMarketValue = 0;

              pageData.forEach(
                ({ valueAtExpiry, dRankBonus, cost, marketValue, roi }) => {
                  totalValueAtExpiry += +valueAtExpiry;
                  totalDRankBonus += +dRankBonus;
                  totalCost += +cost.replace("$", "");
                  totalMarketValue += +marketValue.replace("$", "");
                }
              );

              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={4} index={0}>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      {totalValueAtExpiry.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      {totalDRankBonus.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      ${totalCost.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      ${totalMarketValue.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      {(
                        ((totalMarketValue - totalCost) / totalCost) *
                        100
                      ).toFixed(2)}
                      %
                    </Table.Summary.Cell>
                    {![Show.Ended, Show.VyperReady].includes(show) && (
                      <>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          <button
                            onClick={async () => await claimAll()}
                            className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic"
                          >
                            Claim All
                          </button>
                        </Table.Summary.Cell>
                      </>
                    )}
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </ConfigProvider>
      </div>
      {showShareModal &&
        createPortal(
          <ShareModal
            onSelect={() => setShowShareModal(undefined)}
            stats={showShareModal}
          />,
          document.body
        )}
      {showRenameMiner &&
        createPortal(
          <RenameMinerModal
            onSelect={() => setShowRenameMiner(undefined)}
            minerId={showRenameMiner}
          />,
          document.body
        )}
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-20"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default MinersModal;
