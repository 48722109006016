import { BSC, Mainnet, useEthers } from "@usedapp/core";
import axios from "axios";
import { COMMON_TOKENS_LIST, DEX_SUBGRAPH, TOKEN_LIST } from "../../consts";
import { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import WarningIcon from "../../assets/images/warning.svg";
import LookUpIcon from "../../assets/images/LookUp.svg";
import ShareIcon from "../../assets/images/share.svg";
import CopyIcon from "../../assets/images/copy.svg";
import { utils } from "ethers";

const SelectTokenModal = ({ onSelect }) => {
  const { chainId } = useEthers();

  const [tokenList, setTokenList] = useState();
  const [commonTokensList, setCommonTokenList] = useState();
  const [search, setSearch] = useState();
  const [searchResults, setSearchResults] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [currentList, setCurrentList] = useState();
  const [openWarning, setOpenWarning] = useState(false);

  useEffect(() => {
    setTokenList(TOKEN_LIST[chainId]);
    setCommonTokenList(COMMON_TOKENS_LIST[chainId]);
  }, [chainId]);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    const fetchList = async () => {
      const response = await fetch(
        `https://tokens.coingecko.com/${
          chainId === Mainnet.chainId ? "uniswap" : "binance-smart-chain"
        }/all.json`
      );

      const temp = await response.json();

      setCurrentList(temp.tokens ?? {});
    };

    fetchList();
  }, [chainId]);

  const handleSearch = async ({ target }) => {
    setSearch(target.value);
    setIsLoading(true);

    if (utils.isAddress(target.value)) {
      console.log("isAddress");
      getTokenInfoFromAddress(target.value);
    } else {
      console.log("isNotAddress");
      let filtered = currentList.filter(
        (token) =>
          token.name.toLowerCase().includes(target.value.toLowerCase()) ||
          token.symbol.toLowerCase().includes(target.value.toLowerCase())
      );

      filtered = filtered.splice(0, 5);
      setSearchResults(filtered);
    }

    setIsLoading(false);
  };

  const getTokenInfoFromAddress = async (address) => {
    const URL = `https://api.geckoterminal.com/api/v2/networks/bsc/tokens/${address}`;

    axios
      .get(URL)
      .then((res) => {
        console.log(res);
        const token = res.data.data.attributes;
        setSearchResults([
          {
            address: address,
            decimals: token.decimals,
            logoURI: token.image_url === "missing.png" ? null : token.image_url,
            name: token.name,
            symbol: token.symbol,
            warning: true,
          },
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const getTokenInfoFromAddress = async (address) => {
  //   const URL = DEX_SUBGRAPH[chainId]

  //   let query = `{
  //       tokens(where: { id: "${address.toLowerCase()}"}) {
  //           id
  //           symbol
  //           name
  //           decimals
  //       }
  //   }`
  //   axios.post(URL, { query }).then((res) => {
  //     setSearchResults(
  //       res.data.data.tokens.map((el) => ({
  //         ...el,
  //         address: el.id,
  //         warning: true,
  //       }))
  //     )
  //   })
  // }

  // const handleSearch = async () => {
  //   setIsLoading(true)
  //   const URL = DEX_SUBGRAPH[chainId]

  //   if (chainId == Mainnet) {
  //     let query = `{
  //       tokens(first: 5, where: {or: [
  //         { name_contains_nocase: "${search}" },
  //         { symbol_contains_nocase: "${search}" },
  //         { id: "${search.toLowerCase()}" },
  //       ],
  //       }) {
  //           id
  //           symbol
  //           name
  //           decimals
  //       }
  //   }`
  //     axios.post(URL, { query }).then((res) => {
  //       setSearchResults(
  //         res.data.data.tokens.map((el) => ({ ...el, address: el.id }))
  //       )
  //       setIsLoading(false)
  //     })
  //   } else {
  //     let queryArr = [
  //       `{ name_contains: "${search}" }`,
  //       `{ symbol_contains: "${search}" }`,
  //       `{ id: "${search.toLowerCase()}" }`,
  //     ]
  //     let resArr = []
  //     for (let i = 0; i < 3; i++) {
  //       let query = `{
  //       tokens(first: 5, where:
  //         ${queryArr[i]}
  //       ) {
  //           id
  //           symbol
  //           name
  //           decimals
  //       }
  //   }`
  //       const res = await axios.post(URL, { query })
  //       resArr.push(
  //         res.data.data.tokens.map((el) => ({ ...el, address: el.id }))
  //       )
  //     }
  //     setSearchResults([...resArr[0], ...resArr[1], ...resArr[2]].slice(0, 5))
  //     setIsLoading(false)
  //   }
  // }

  return (
    <>
      {openWarning ? (
        <div className="max-h-full overflow-y-auto z-[100] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg w-[95%] md:w-[480px] bg-[#0C0518] bg-opacity-90 lg:bg-opacity-70 rounded-2xl border-[2px] border-white text-white flex flex-col items-center py-6 px-7">
          <img
            alt="close"
            src={CloseIcon}
            className="cursor-pointer absolute right-7 top-6"
            onClick={() => onSelect()}
          />
          <img
            className="w-20 h-20 rounded-full mb-1"
            src={
              openWarning.logoURI
                ? openWarning.logoURI
                : "./assets/images/no-logo.png"
            }
            alt="warning"
          />
          <p className=" mb-11">{openWarning.symbol}</p>
          <img src={WarningIcon} alt="warning" />
          <p className="text-center text-sm w-[320px] mt-5 leading-5">
            This token isn't traded on leading U.S. centralized exchanges or
            frequently swapped on Burnswap. Always conduct your own research
            before trading.
          </p>
          <div className="bg-[#D9D9D9] h-[46px] w-[340px] px-6 bg-opacity-[0.14] rounded-[10px] mt-12 flex items-center justify-between">
            <p className="text-sm w-[240px] truncate">
              {chainId === BSC.chainId
                ? BSC.blockExplorerUrl
                : Mainnet.blockExplorerUrl}
              /token/{openWarning.address}
            </p>
            <div className="flex gap-3">
              <a
                href={`${
                  chainId === BSC.chainId
                    ? BSC.blockExplorerUrl
                    : Mainnet.blockExplorerUrl
                }/token/${openWarning.address}`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={ShareIcon} alt="go" />
              </a>
              <img
                className="cursor-pointer"
                src={CopyIcon}
                alt="copy"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${
                      chainId === BSC.chainId
                        ? BSC.blockExplorerUrl
                        : Mainnet.blockExplorerUrl
                    }/token/${openWarning.address}`
                  );
                }}
              />
            </div>
          </div>
          <button
            className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-md p-4 rounded-2xl font-medium text-2xl text-white not-italic mt-14"
            onClick={() => onSelect(openWarning)}
          >
            I understand
          </button>
        </div>
      ) : (
        <div className="max-h-full overflow-y-auto z-[100] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg w-[95%] md:w-[480px] bg-[#0C0518] bg-opacity-90 lg:bg-opacity-70 rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
          <div className="flex justify-between items-center">
            <span className=" font-medium text-[19px] w-fit">
              Select a Token
            </span>
            <img
              alt="close"
              src={CloseIcon}
              className="cursor-pointer"
              onClick={() => onSelect()}
            />
          </div>
          <div className="flex shadow-md rounded-2xl border-[1px] border-white border-opacity-50 px-5 py-3 gap-5">
            <img src={LookUpIcon} alt="lookup" />
            <input
              className="bg-transparent outline-none flex-1 text-sm font-normal"
              placeholder="Search name or paste address"
              value={search}
              onChange={handleSearch}
            />
          </div>
          <div className="relative flex flex-col gap-2 mb-4 after:absolute after:h-[2px] after:w-[108%] after:left-1/2 after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:bg-white after:-bottom-4">
            <span className="text-[13px]">Common Tokens</span>
            <div className="flex justify-between">
              {commonTokensList &&
                commonTokensList.map((el) => (
                  <div
                    className="flex gap-2 w-fit flex-shrink-0 cursor-pointer"
                    onClick={() => onSelect(el)}
                  >
                    <img
                      alt="logo"
                      className="w-[26px] rounded-full"
                      src={
                        el.logoURI ? el.logoURI : "./assets/images/no-logo.png"
                      }
                    />{" "}
                    {el.symbol}
                  </div>
                ))}
            </div>
          </div>
          {isLoading ? (
            <p>Loading data...</p>
          ) : (
            <div className="flex flex-col gap-2">
              {search && search.length > 0 && searchResults ? (
                searchResults.length > 0 ? (
                  searchResults.map((el) => (
                    <div
                      className="flex gap-2 p-4 rounded-lg cursor-pointer items-center"
                      onClick={() =>
                        el.warning ? setOpenWarning(el) : onSelect(el)
                      }
                    >
                      <img
                        src={
                          el.logoURI
                            ? el.logoURI
                            : "./assets/images/no-logo.png"
                        }
                        className="w-[26px] h-[26px] rounded-full"
                        alt="logo"
                      />
                      <div className="flex flex-col font-medium gap-2">
                        <p className="text-[19px] leading-[13px]">{el.name}</p>
                        <p className="text-white opacity-50 text-[13px] leading-[10px]">
                          {el.symbol}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Not found</p>
                )
              ) : (
                tokenList &&
                tokenList.length > 0 &&
                tokenList.map((el) => (
                  <div
                    className="flex gap-2 p-4 rounded-lg cursor-pointer items-center"
                    onClick={() => onSelect(el)}
                  >
                    <img
                      src={
                        el.logoURI ? el.logoURI : "./assets/images/no-logo.png"
                      }
                      className="w-[26px] h-[26px] rounded-full"
                      alt="logo"
                    />
                    <div className="flex flex-col font-medium gap-2">
                      <p className="text-[19px] leading-[13px]">{el.name}</p>
                      <p className="text-white opacity-50 text-[13px] leading-[10px]">
                        {el.symbol}
                      </p>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>
      )}
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-20"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default SelectTokenModal;
