import { useEthers } from "@usedapp/core";
import PayoutCycle from "../components/Payouts/PayoutCycle";
import useGlobalShares from "../hooks/Stakes/read/useGlobalShares";
import useUserStakes from "../hooks/Stakes/read/useUserStakes";
import useUserAvailableRewardsAll from "../hooks/Stakes/read/useUserAvailableRewardsAll";
import { useEffect, useState } from "react";
import useClaimAllRewards from "../hooks/Stakes/write/useClaimAllRewards";
import useTxToast from "../hooks/useTxToast";
import useTriggerAllPayouts from "../hooks/Stakes/write/useTriggerAllPayouts";
import useCurrentContractDay from "../hooks/Stakes/read/useCurrentContractDay";
import useGenesisTs from "../hooks/Stakes/read/useGenesisTs";
import { utils } from "ethers";
import Tooltips from "../assets/tooltips";
import TooltipWrapper from "../components/Shared/TooltipWrapper";

const Payouts = () => {
  const { account } = useEthers();

  const [bnbPrice, setBnbPrice] = useState(0);

  const genesisTs = useGenesisTs();
  const currentContractDay = useCurrentContractDay();
  const globalShares = useGlobalShares();
  const userStakes = useUserStakes();

  const userShares = userStakes.reduce((acc, el) => {
    // if (el.active && +utils.formatUnits(el.endDay, 0) >= currentContractDay)
    //   console.log(
    //     utils.formatUnits(el.id, 0),
    //     el.active,
    //     utils.formatUnits(el.endDay, 0),
    //     currentContractDay,
    //     utils.formatUnits(el.shares, 18)
    //   );
    return (
      acc +
      (el.active && +utils.formatUnits(el.endDay, 0) >= currentContractDay
        ? +utils.formatUnits(el.shares, 18)
        : 0)
    );
  }, 0);

  const userAvailableRewardsAll = useUserAvailableRewardsAll();

  const { claimAllRewards, state: claimAllRewardsState } = useClaimAllRewards();
  const { triggerAllPayouts, state: triggerAllPayoutsState } =
    useTriggerAllPayouts();

  useTxToast(claimAllRewardsState, "Claimed all rewards!");
  useTxToast(triggerAllPayoutsState, "Triggered all payouts!");

  useEffect(() => {
    fetch(
      "https://api.dexscreener.com/latest/dex/pairs/bsc/0x172fcd41e0913e95784454622d1c3724f546f849"
    )
      .then((res) => res.json())
      .then((data) => {
        setBnbPrice(data.pairs[0].priceNative);
      });
  }, []);

  return (
    <div className="w-full mt-[95px] py-20 flex flex-col gap-6 justify-center items-center relative content">
      <div className="shadow-lg w-[85%] md:w-[780px] lg:w-[1024px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">
            Your Claimable BNB Payouts
          </span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col ">
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Your Active Shares
                <TooltipWrapper title={Tooltips.PAYOUTS.ACTIVE_SHARES} />
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>{(+userShares).toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  ≈{" "}
                  {parseFloat(
                    ((userShares / globalShares) * 100).toPrecision(4)
                  )}
                  % of global shares
                </span>
              </div>
            </div>
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                BNB Claimable
                <TooltipWrapper title={Tooltips.PAYOUTS.BNB_CLAIMABLE} />
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>${(bnbPrice * userAvailableRewardsAll).toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  {(+userAvailableRewardsAll).toFixed(2)} BNB
                </span>
              </div>
            </div>
          </div>
        </div>
        <button
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-2xl text-white not-italic"
          onClick={async () => await claimAllRewards()}
        >
          Claim
        </button>
      </div>
      <div className="grid w-[85%] md:w-[780px] lg:w-[1024px] grid-cols-1 lg:grid-cols-2 gap-4">
        <button
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-2xl text-white not-italic col-span-1 lg:col-span-2"
          onClick={async () => await triggerAllPayouts()}
        >
          Trigger Payouts
        </button>
        {[8, 28, 90, 369, 888].map((el, index) => (
          <PayoutCycle
            key={index}
            days={el}
            bnbPrice={bnbPrice}
            userShares={userShares}
            globalShares={globalShares}
            currentContractDay={currentContractDay}
            genesisTs={genesisTs}
          />
        ))}
      </div>
    </div>
  );
};

export default Payouts;
