import { useCall } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/masterchef.json";
import { FARM_VAULT_ADDRESS, MASTERCHEF_CONTRACT } from "../../../consts";

const usePendingInc = (pId, chainId) => {
  const { value, error } =
    useCall(
      MASTERCHEF_CONTRACT[chainId] && {
        contract: new Contract(MASTERCHEF_CONTRACT[chainId], abi),
        method: "pendingInc",
        args: [pId, FARM_VAULT_ADDRESS[chainId]],
      },
      {
        chainId: chainId,
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? utils.formatEther(value?.[0]) : 0;
};

export default usePendingInc;
