import { useCall } from "@usedapp/core";
import { Contract } from "ethers";
import abi from "../../../contracts/token.json";
import { formatEther, formatUnits } from "ethers/lib/utils";

const useTokenAllowance = (
  address,
  spender,
  token,
  decimals = 18,
  queryParams = {}
) => {
  const { value, error } =
    useCall(
      address &&
        spender &&
        token && {
          contract: new Contract(token, abi),
          method: "allowance",
          args: [address, spender],
        },
      queryParams
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? formatUnits(value?.[0], decimals) : 0;
};

export default useTokenAllowance;
