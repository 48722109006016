import { useEthers, useContractFunction } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/swap.json";
import { SWAP_CONTRACT, WETH_CONTRACT } from "../../../consts";

const useSwapExactInput = () => {
  const { library, account, chainId } = useEthers();
  const contract =
    library && chainId && SWAP_CONTRACT[chainId]
      ? new Contract(SWAP_CONTRACT[chainId], abi, library.getSigner())
      : null;
  const { send: sendSwap, state } = useContractFunction(
    contract,
    "swapExactInput",
    { gasLimitBufferPercentage: 100 }
  );

  const swapExactInput = async (
    amount,
    amountOutMin,
    path,
    pathToWeth_V2,
    pathToWeth_V3,
    isNative
  ) => {
    try {
      console.log(
        "here swapExactInput",
        account,
        utils.formatEther(amount),
        utils.formatEther(amountOutMin),
        path,
        pathToWeth_V2,
        pathToWeth_V3,
        isNative,
        path.slice(0, 42)
      );

      if (
        account &&
        amount &&
        amountOutMin != undefined &&
        (pathToWeth_V2.length >= 2 || pathToWeth_V3.length >= 43) &&
        isNative != undefined
      ) {
        await sendSwap(
          amount,
          amountOutMin,
          path,
          pathToWeth_V2,
          pathToWeth_V3,
          isNative,
          {
            value:
              isNative && path.slice(0, 42) == WETH_CONTRACT[chainId]
                ? amount
                : 0,
          }
        );
      }
    } catch (error) {
      console.error("Error while swapping ETH to tokens:", error);
    }
  };

  return { swapExactInput, state };
};

export default useSwapExactInput;
