import { useCall } from "@usedapp/core";
import { Contract } from "ethers";
import abi from "../contracts/token.json";
import { formatEther, formatUnits } from "ethers/lib/utils";

const useTokenBalance = (
  address,
  contract,
  decimals = 18,
  queryParams = {}
) => {
  const { value, error } =
    useCall(
      address &&
        contract && {
          contract: new Contract(contract, abi),
          method: "balanceOf",
          args: [address],
        },
      queryParams
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0]
    ? parseFloat(formatUnits(value?.[0], decimals)).toFixed(6)
    : 0;
};

export default useTokenBalance;
