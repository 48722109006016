import { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import { ConfigProvider, Table, theme } from "antd";
import { utils } from "ethers";
import Countdown from "../Shared/Countdown";
import useTxToast from "../../hooks/useTxToast";
import { createPortal } from "react-dom";
import { IoPencil, IoShareOutline } from "react-icons/io5";
import ShareModal from "./ShareModal";
import useUserBonds from "../../hooks/Faucet/read/useUserBonds";
import usePlayers from "../../hooks/Faucet/read/usePlayers";
import useAvailableRewards from "../../hooks/Faucet/read/useAvailableRewards";
import useClaimAll from "../../hooks/Faucet/write/useClaimAll";
import { formatEther } from "ethers/lib/utils";
import useAvailableRewardsList from "../../hooks/Faucet/read/useAvailableRewardsFaucets";

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    width: 80,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "DRIPX Bonded",
    dataIndex: "amount",
    key: "amount",
    width: 100,
    sorter: (a, b) => a.amount - b.amount,
  },
  {
    title: "Last claim",
    dataIndex: "lastClaimFormatted",
    key: "lastClaimFormatted",
    width: 100,
    sorter: (a, b) => a.lastClaim - b.lastClaim,
  },
  {
    title: "Daily ROI",
    dataIndex: "dailyRoi",
    key: "dailyRoi",
    width: 80,
    sorter: (a, b) => a.dailyRoi - b.dailyRoi,
  },
  {
    title: "Available DRIPX Reward",
    dataIndex: "availableReward",
    key: "availableReward",
    width: 140,
    sorter: (a, b) => a.availableRewards - b.availableRewards,
  },
  {
    title: "Daily DRIPX Reward",
    dataIndex: "dailyReward",
    key: "dailyReward",
    width: 140,
    sorter: (a, b) => a.dailyReward - b.dailyReward,
  },
  {
    title: "DRIPX at End",
    dataIndex: "valueAtExpiry",
    key: "valueAtExpiry",
    width: 150,
    sorter: (a, b) => a.valueAtExpiry - b.valueAtExpiry,
  },
  {
    title: "Faucet ROI",
    children: [
      {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
        width: 75,
        sorter: (a, b) => a.cost.replace("$", "") - b.cost.replace("$", ""),
      },
      {
        title: "Market Value",
        dataIndex: "marketValue",
        key: "marketValue",
        width: 75,
        sorter: (a, b) =>
          a.marketValue.replace("$", "") - b.marketValue.replace("$", ""),
      },
      {
        title: "ROI",
        dataIndex: "roi",
        key: "roi",
        width: 75,
        sorter: (a, b) => a.roi.replace("%", "") - b.roi.replace("%", ""),
      },
    ],
  },
  {
    title: "Progress",
    dataIndex: "progress",
    key: "progress",
    width: 100,
    sorter: (a, b) => a.progress.replace("%", "") - b.progress.replace("%", ""),
  },
  {
    title: "Share",
    dataIndex: "share",
    key: "share",
    width: 80,
  },
];

const Show = {
  Active: "Active",
  Ended: "Ended",
};

const availableRewardsForFaucet = (faucet) => {
  const secondsElapsed = Date.now() / 1000 - faucet.lastClaim;
  let bonus = (secondsElapsed / 86400) * 10;
  if (bonus > 350) bonus = 350;
  const roi = faucet.baseRoi + bonus;
  let claimAmount =
    (+formatEther(faucet.amount) * secondsElapsed * roi) / 86400 / 10000;
  if (
    +formatEther(faucet.totalClaimed) + claimAmount >
    (+formatEther(faucet.amount) * 200) / 10000
  )
    claimAmount =
      (+formatEther(faucet.amount) * 200) / 10000 -
      +formatEther(faucet.totalClaimed);
  return claimAmount;
};

const FaucetsModal = ({ onSelect, bnbPrice, tokenPrice }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const [showShareModal, setShowShareModal] = useState(undefined);
  const [show, setShow] = useState(Show.Active);

  const userBonds = useUserBonds();
  const availableRewardsList = useAvailableRewardsList();

  const { claimAll, state: claimAllState } = useClaimAll();

  useTxToast(claimAllState, "Claimed All");

  const data = userBonds.map((faucet, index) => {
    const daysAgo = (Date.now() / 1000 - +faucet.lastClaim.toString()) / 86400;

    const id = index;
    const amount = +formatEther(faucet.amount);
    const lastClaim = faucet.lastClaim;
    const lastClaimFormatted = daysAgo.toFixed(2) + " days ago";
    const dailyRoi = Math.min(
      +faucet.baseRoi.toString() / 100 + Math.floor(daysAgo) * 0.1,
      4
    );
    const dailyReward = ((amount * dailyRoi) / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    const valueAtExpiry = amount * 2;
    const cost = amount * tokenPrice;
    const marketValue = valueAtExpiry * tokenPrice;
    const roi =
      ((marketValue - cost) / cost).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      }) * 100;
    const progress = Math.min(
      100,
      ((+formatEther(faucet.totalClaimed) + availableRewardsForFaucet(faucet)) /
        (amount * 2)) *
        100
    );

    const availableReward = availableRewardsForFaucet(faucet);

    return {
      id,
      amount: amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
      }),
      lastClaim,
      lastClaimFormatted,
      dailyRoi: dailyRoi.toFixed(2) + "%",
      dailyReward,
      valueAtExpiryUnformatted: valueAtExpiry,
      valueAtExpiry: valueAtExpiry.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      availableReward: (+formatEther(
        availableRewardsList[index] ?? 0
      )).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      costUnformatted: cost,
      marketValueUnformatted: marketValue,
      cost: "$" + cost.toFixed(2),
      marketValue: "$" + marketValue.toFixed(2),
      roi: roi.toFixed(2) + "%",
      progress: progress.toFixed(2) + "%",
      share: (
        <button
          className="text-xl font-bold bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg ml-[50%] -translate-x-5 text-white not-italic"
          onClick={() =>
            setShowShareModal({
              valueAtExpiry: valueAtExpiry.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              }),
              cost: "$" + cost.toFixed(2),
              marketValue: "$" + marketValue.toFixed(2),
              dailyRoi: dailyRoi.toFixed(2) + "%",
              dailyReward,
              roi: roi.toFixed(2) + "%",
              progress: progress.toFixed(2) + "%",
            })
          }
        >
          <IoShareOutline />
        </button>
      ),
    };
  });

  return (
    <>
      <div className="max-h-full overflow-y-auto z-[100] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg w-[95%] bg-[#0C0518] rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
        <div className="flex justify-between items-center">
          <span className=" font-medium text-[19px] w-fit">Your Faucets</span>
          <img
            alt="close"
            src={CloseIcon}
            className="cursor-pointer"
            onClick={() => onSelect()}
          />
        </div>
        <div className="flex gap-1 -mb-4">
          <div
            className={`${
              show === Show.Active ? "opacity-100" : "opacity-50"
            } transition-all bg-[#191325] min-w-[70px] text-center text-white py-2 px-2 w-fit rounded-t-lg cursor-pointer`}
            onClick={() => setShow(Show.Active)}
          >
            Active
          </div>
          <div
            className={`${
              show === Show.Ended ? "opacity-100" : "opacity-50"
            } transition-all bg-[#191325] min-w-[70px] text-center text-white py-2 px-2 w-fit rounded-t-lg cursor-pointer`}
            onClick={() => setShow(Show.Ended)}
          >
            Ended
          </div>
        </div>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
            token: {
              // Seed Token
              colorPrimary: "#fff",
              borderRadius: 2,

              // Alias Token
              colorBgContainer: "#D9D9D902",
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={data.filter((faucet) => {
              if (show === Show.Ended)
                return faucet.totalClaimed === faucet.amount * 2;
              if (show === Show.Active)
                return faucet.totalClaimed !== faucet.amount * 2;
            })}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: "400px",
            }}
            //make the table dark mode
            className="rouned-xl rounded-lg scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-700 scrollbar-track-slate-300"
            pagination={false}
            summary={(pageData) => {
              let totalDailyRewards = 0;
              let totalAvailableRewards = 0;
              let totalValueAtExpiry = 0;
              let totalCost = 0;
              let totalMarketValue = 0;

              pageData.forEach(
                ({
                  availableReward,
                  dailyReward,
                  valueAtExpiryUnformatted,
                  costUnformatted,
                  marketValueUnformatted,
                }) => {
                  totalAvailableRewards += +availableReward.replace(",", "");
                  totalDailyRewards += +dailyReward.replace(",", "");
                  totalValueAtExpiry += +valueAtExpiryUnformatted;
                  totalCost += +costUnformatted;
                  totalMarketValue += +marketValueUnformatted;
                }
              );

              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={4} index={0}>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      {totalAvailableRewards.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      {totalDailyRewards.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      {totalValueAtExpiry.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      ${totalCost.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      ${totalMarketValue.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5}>
                      {(
                        ((totalMarketValue - totalCost) / totalCost) *
                        100
                      ).toFixed(2)}
                      %
                    </Table.Summary.Cell>
                    {![Show.Ended, Show.VyperReady].includes(show) && (
                      <>
                        <Table.Summary.Cell index={6}></Table.Summary.Cell>
                        <Table.Summary.Cell index={7}>
                          <button
                            onClick={async () => await claimAll()}
                            className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-lg font-medium text-white not-italic"
                          >
                            Claim All
                          </button>
                        </Table.Summary.Cell>
                      </>
                    )}
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </ConfigProvider>
      </div>
      {showShareModal &&
        createPortal(
          <ShareModal
            onSelect={() => setShowShareModal(undefined)}
            stats={showShareModal}
          />,
          document.body
        )}
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-20"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default FaucetsModal;
