import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract } from "ethers";
import { MINER_CONTRACT } from "../../../consts";
import abi from "../../../contracts/miners.json";

const useMintMiner = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(MINER_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendMintMiner, state } = useContractFunction(
    contract,
    "mintMiner"
  );

  const mintMiner = async (power, length, value) => {
    try {
      if (account) {
        await sendMintMiner(power, length, {
          value,
        });
      }
    } catch (error) {
      console.error("Error while contributing:", error);
    }
  };

  return { mintMiner, state };
};

export default useMintMiner;
