import { useEffect } from "react";
import { toast } from "react-hot-toast";

const useTxToast = (txState, success, callback = undefined) => {
  useEffect(() => {
    if (txState.status === "Exception" || txState.status === "Fail") {
      toast(txState.errorMessage.split(":").splice(-1), {
        icon: "❌",
        style: {
          borderRadius: "15px",
          background: "#fff",
          color: "black",
        },
      });
    } else if (txState.status === "Success") {
      toast(success, {
        icon: "✔️",
        style: {
          borderRadius: "15px",
          background: "#fff",
          color: "black",
        },
      });
      if (callback) callback();
    }
  }, [txState, success, callback]);
};

export default useTxToast;
