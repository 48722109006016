import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract, utils } from "ethers";
import { MINER_CONTRACT } from "../../../consts";
import abi from "../../../contracts/miners.json";

const useVyperMigrateMint = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(MINER_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendVyperMigrateMint, state } = useContractFunction(
    contract,
    "vyperMigrateMiner"
  );

  const vyperMigrateMint = async (id) => {
    try {
      if (account) {
        await sendVyperMigrateMint(id, {
          value: utils.parseEther("0.1"),
        });
      }
    } catch (error) {
      console.error("Error while contributing:", error);
    }
  };

  return { vyperMigrateMint, state };
};

export default useVyperMigrateMint;
