import { useCall } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/buyandburn.json";

const useTotalBurnedToken = (contract) => {
  const { value, error } =
    useCall(
      contract && {
        contract: new Contract(contract, abi),
        method: "totalBurnedToken",
        args: [],
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? utils.formatUnits(value?.[0], 18) : 0;
};

export default useTotalBurnedToken;
