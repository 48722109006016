import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Toaster } from "react-hot-toast";
import {
  BSC,
  DAppProvider,
  DEFAULT_SUPPORTED_CHAINS,
  Hardhat,
  Localhost,
  MetamaskConnector,
} from "@usedapp/core";
import { getDefaultProvider } from "ethers";
import { WalletConnectV2Connector } from "@usedapp/wallet-connect-v2-connector";
import ChainSwitch from "../Shared/ChainSwitch"; //
import { PulseChain } from "../../PulseChain";

const Layout = () => {
  const config = {
    readOnlyChainId: BSC.chainId,
    readOnlyUrls: {
      [BSC.chainId]: getDefaultProvider("https://bsc-rpc.publicnode.com"),
      [PulseChain.chainId]: getDefaultProvider(PulseChain.rpcUrl),
      [Hardhat.chainId]: getDefaultProvider("http://localhost:8545"),
    },
    connectors: {
      metamask: new MetamaskConnector(),
      walletConnectV2: new WalletConnectV2Connector({
        projectId: "cf9c926fc193a2b2b5dfcfcc54b34625",
        chains: [BSC],
        rpcMap: {
          56: "https://bsc-rpc.publicnode.com",
          97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
          [PulseChain.chainId]: PulseChain.rpcUrl,
        },
      }),
    },
    networks: [...DEFAULT_SUPPORTED_CHAINS, PulseChain, Hardhat],
  };

  return (
    <DAppProvider config={config}>
      <ChainSwitch targetChain={[BSC.chainId, Hardhat.chainId]} />
      <Toaster position="bottom-center" />
      <Header />
      <Outlet />
      <Footer />
    </DAppProvider>
  );
};

export default Layout;
