import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract, utils } from "ethers";
import { STAKE_CONTRACT } from "../../../consts";
import abi from "../../../contracts/stakes.json";

const useWithdrawFromPool = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(STAKE_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendWithdrawFromPool, state } = useContractFunction(
    contract,
    "withdrawFromPool"
  );

  const withdrawFromPool = async (pool, amount) => {
    try {
      if (account) {
        await sendWithdrawFromPool(pool, utils.parseEther(amount + ""));
      }
    } catch (error) {
      console.error("Error while staking:", error);
    }
  };

  return { withdrawFromPool, state };
};

export default useWithdrawFromPool;
