import { useCall, useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import { STAKE_CONTRACT } from "../../../consts";
import abi from "../../../contracts/stakes.json";

const useUserStakes = () => {
  const { chainId, account } = useEthers();

  const { value, error } =
    useCall(
      account &&
        STAKE_CONTRACT[chainId] && {
          contract: new Contract(STAKE_CONTRACT[chainId], abi),
          method: "userStakes",
          args: [account],
        }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? value?.[0] : [];
};

export default useUserStakes;
