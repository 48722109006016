const Tooltips = {
  MINERS: {
    DURATION:
      "Number of days you want your miner to run for before becoming claimable. This determines how much DRIPX you're getting, in general: longer is better, always.",
    POWER:
      "The power you want your miner to have, from 1 to 100. This determines the BNB cost for this miner. Cost per DRIPX stays the same, 1 power is just cheaper than 100, can also do 22, 58, etc... any number between and up to 1 and 100. general rule: higher is always better. Also easier to manage.",
    EST_DRIPX_AT_END:
      "Estimated DRIPX to be given at the end of your miner(s).",
    MARKET_VALUE_MINER:
      "Total # of DRIP X * Current Market Price. This number will change with time as the DRIPX price goes up or down on the market, this is NOT set in stone, just a projected estimation if all stays the same as now. Which it will not. Price can go up or it can go down.",
    DRIPX_MARKET_PRICE:
      "Current market price of DRIPX in WDRIP/DRIPX Pancakeswap v3 pool",
    EAA: "First 350 days of DRIPX, you get an early adoption amplifier on the # of DRIPX tokens you can mine, this works its way down to 0% on day 350.",
    NEXT_DIFFICULTY_INCREASE:
      "Every day it becomes a little bit harder to mine DRIPX, you get a little bit less & pay a little bit more. This creates scarcity & is more aggressive than a Bitcoin halving, and happens every day. Read 'The Daily Update' in the docs for more info. The general rule is: today is always better than tomorrow.",
    DAILY_CAP:
      "Once the maximum daily minting is reached, that day's quota is filled, and users must select a different day to end their miner(s).",
  },
  FAUCETS: {
    EST_DRIPX_AT_END: "Estimated DRIPX to be given at the end of your faucet.",
    MARKET_VALUE_MINER:
      "Total # of DRIP X * Current Market Price. This number will change with time as the DRIPX price goes up or down on the market, this is NOT set in stone, just a projected estimation if all stays the same as now. Which it will not. Price can go up or it can go down.",
    DRIPX_MARKET_PRICE:
      "Current market price of DRIPX in WDRIP/DRIPX Pancakeswap v3 pool.",
    BNB_MARKET_PRICE: "Current market price of BNB.",
    DAILY_ROI:
      "Daily ROI in %, this is the % of your initial investment you get back in DRIPX every day. It increases by 0.10% every day, and resets on claims.",
    MAX_DAILY_ROI: "The maximum daily ROI you can get from a faucet is 4.00%.",
    MAX_TOTAL_ROI:
      "The maximum total ROI you can get from a faucet is 200.00%.",
  },
  STAKES: {
    DURATION:
      "Number of days you want to stake for. In general: longer is always better because longer pays more bonus.",
    NUM_OF_SHARES:
      "Total shares your stake will give you, including Longer Pays More & Bigger Pays More bonuses. Minimum 1 share is required per stake, you cannot create stakes that have less than 1 share.",
    CURRENT_SHARE_RATE: "Moves up by 0.03% every day up until 2800 cap",
    BASE_SHARE:
      "This would be your number of shares in this stake without any Longer Pays More or Bigger Pays More bonuses",
    LONGER_PAYS_MORE:
      "The longer you stake for, the more shares you get. This bonus starts at 0% and goes all the way up to 350% more shares at day 2888, you can go up to day 3500 to keep your shares for longer (called share preservation).",
    BIGGER_PAYS_MORE:
      "The bigger your stake is, you get more shares. This goes up to 8% max at 100B DRIPX in 1 stake. This is linear - so if you stake 12.5B for example, it would be 1%.",
    EFFECTIVE_SHARE_RATE:
      "This is your effective share rate including all bonuses. This is what will be used (estimated) to give you your shares in your stake.",
    EFFECTIVE_SHARES:
      "This is the number of shares in your stake and includes the Bigger Pays More and Longer Pays More bonuses.",
    NEXT_DIFFICULTY_INCREASE:
      "Every day it becomes a little bit harder to get shares by staking. It increases by 0.03% every day, meaning that with every passing day, you need more DRIPX to create shares & get BNB payouts. The general rule is: today is always better than tomorrow.",
  },
  PAYOUTS: {
    ACTIVE_SHARES:
      "Your active shares from your DRIPX stakes. Once you end a stake, the shares become inactive and are not counted. This determines your % rewards from the payouts.",
    BNB_CLAIMABLE:
      "Your unclaimed amount of BNB from all payout cycles (excl. BNB you have already claimed)",
    GLOBAL_CYCLE_PAYOUT:
      "Total BNB currently in this cycle payout waiting to be paid out to stakers of DRIPX.",
    EST_PAYOUT:
      "Your estimated payout when this cycle changes, this constantly changes based on the amount of BNB in the cycle & your % of total user shares. Keep an eye on this. You can get more by staking more DRIPX & staking more DRIPX for longer",
    NEXT_PAYOUT:
      "Countdown until this payout cycle triggers & pays out all DRIPX stakers. These cycles are 'rolling', meaning that once the cycle has paid out, a next cycle starts. The payout cycles NEVER end, they are forever & will keep running forever.",
  },
};

export default Tooltips;
