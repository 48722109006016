import { useCall, useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import { MINER_CONTRACT } from "../../../consts";
import abi from "../../../contracts/miners.json";

const useUserMiners = () => {
  const { chainId, account } = useEthers();

  const { value, error } =
    useCall(
      account &&
        MINER_CONTRACT[chainId] && {
          contract: new Contract(MINER_CONTRACT[chainId], abi),
          method: "userMiners",
          args: [account],
        }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? value?.[0] : [];
};

export default useUserMiners;
