import { BSC, useCall } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/miners.json";
import { MINER_CONTRACT } from "../../../consts";

const useGenesisTs = () => {
  const { value, error } =
    useCall(
      MINER_CONTRACT[BSC.chainId] && {
        contract: new Contract(MINER_CONTRACT[BSC.chainId], abi),
        method: "genesisTs",
        args: [],
      },
      { chainId: BSC.chainId }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0] ? utils.formatUnits(value?.[0], 0) : 0;
};

export default useGenesisTs;
