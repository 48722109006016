import { useEffect, useMemo, useState } from "react";
import Tooltips from "../../assets/tooltips";
import TooltipWrapper from "../Shared/TooltipWrapper";
import dripXToken from "../../assets/images/dripx_token.jpg";
import useCurrentShareRate from "../../hooks/Stakes/read/useCurrentShareRate";
import useGlobalShares from "../../hooks/Stakes/read/useGlobalShares";
import useCurrentCyclePayoutAmout from "../../hooks/Stakes/read/useCurrentCyclePayoutAmout";

const PayoutAmount = ({ days, percentage, bnbPrice, setAmount, index }) => {
  const currentCyclePayoutAmount = useCurrentCyclePayoutAmout(days);

  useEffect(() => {
    setAmount((prev) => {
      console.log("prev", prev);
      prev[index] = (+currentCyclePayoutAmount * percentage * bnbPrice).toFixed(
        2
      );
      console.log("prev2", prev);
      return [...prev];
    });
  }, [currentCyclePayoutAmount, percentage, bnbPrice, index, setAmount]);

  return (+currentCyclePayoutAmount * percentage * bnbPrice).toFixed(2);
};

const StakeCalculator = ({ tokenPrice, bnbPrice }) => {
  const [quantity, setQuantity] = useState("");
  const [duration, setDuration] = useState(3500);
  const [amount, setAmount] = useState([0, 0, 0, 0, 0]);

  const currentShareRate = useCurrentShareRate();
  const globalShares = useGlobalShares();

  const preBonusShares = useMemo(
    () => quantity / +currentShareRate,
    [quantity, currentShareRate]
  );

  const longerPaysMorePercentage = useMemo(
    () => Math.min((350 / 2888) * duration, 350),
    [duration]
  );

  const longerPaysMoreBonusShares = useMemo(
    () => (longerPaysMorePercentage * preBonusShares) / 100,
    [longerPaysMorePercentage, preBonusShares]
  );

  const biggerPaysMorePercentage = useMemo(
    () => Math.min((8 / 1e11) * quantity, 8),
    [quantity]
  );

  const biggerPaysMoreBonusShares = useMemo(
    () => (biggerPaysMorePercentage * preBonusShares) / 100,
    [biggerPaysMorePercentage, preBonusShares]
  );

  const effectiveShares = useMemo(
    () =>
      preBonusShares + longerPaysMoreBonusShares + biggerPaysMoreBonusShares,
    [preBonusShares, longerPaysMoreBonusShares, biggerPaysMoreBonusShares]
  );

  console.log(amount);
  return (
    <>
      <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-tl-none rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col gap-2 text-white">
          <div className="bg-[#0C0518] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[16px] py-6 px-6 flex flex-col items-end relative">
            <input
              className="h-[1.875rem] font-medium text-3xl bg-transparent outline-none text-right w-[calc(100%-2.25rem-42px)]"
              onChange={({ target }) => {
                setQuantity(target.value);
              }}
              value={quantity}
              placeholder="0.000"
            />
            <div className="opacity-50 text-base">
              $ {(+quantity * tokenPrice).toFixed(2)}
            </div>
            <div className="leading-4 absolute left-6 w-[52px] top-0 md:top-1/2 transform -translate-y-[calc(50%-0.75rem)] md:-translate-y-1/2 flex flex-col h-full justify-center gap-1 pt-4 pb-4 items-center">
              <img alt="from-icon" className="rounded-full" src={dripXToken} />
              DRIPX
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-between text-sm text-white">
            <span className="opacity-50 flex gap-2 items-center">
              Duration
              <TooltipWrapper title={Tooltips.STAKES.DURATION} />
            </span>
            <span>
              <input
                type="number"
                className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                value={duration}
                max={3500}
                min={28}
                onChange={({ target }) => setDuration(target.value)}
                onBlur={({ target }) =>
                  setDuration(
                    +target.value > 3500
                      ? 3500
                      : +target.value < 28
                      ? 28
                      : +target.value
                  )
                }
              />
              <span className="opacity-50 ml-2">DAYS</span>
            </span>
          </div>
          <div className="relative">
            <input
              type="range"
              min="28"
              max="3500"
              className="w-full appearance-none relative z-[3] bg-transparent"
              value={duration}
              onChange={({ target }) => setDuration(target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between text-white">
        <span className="text-xl font-medium">Share Details</span>
      </div>
      <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col">
          <div className="flex justify-between text-sm text-white">
            <span className="opacity-50 flex gap-2 items-center">
              Effective Share Rate (incl. Bonuses)
              <TooltipWrapper title={Tooltips.STAKES.EFFECTIVE_SHARE_RATE} />
            </span>
            <span>{(quantity / effectiveShares).toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-sm text-white">
            <span className="opacity-50 flex gap-2 items-center">
              Effective Share (incl. Bonuses)
              <TooltipWrapper title={Tooltips.STAKES.EFFECTIVE_SHARES} />
            </span>
            <span>{effectiveShares.toFixed(2)}</span>
          </div>
          <div className="flex justify-between text-sm text-white">
            <span className="opacity-50 flex gap-2 items-center">
              % of Global Shares
            </span>
            <span>
              {parseFloat(
                (effectiveShares / (+globalShares + effectiveShares)) * 100
              ).toPrecision(4)}
              %
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-between text-white">
        <span className="text-xl font-medium">
          Summary & Your Est. % of Staker Payouts
        </span>
      </div>
      <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
        <div className="flex flex-col">
          {[8, 28, 90, 369, 888].map((day, index) => (
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Next {day}-Day Payout
                <TooltipWrapper title={Tooltips.PAYOUTS.EST_PAYOUT} />
              </span>
              <span className="text-green-400">
                $
                <PayoutAmount
                  key={index}
                  index={index}
                  setAmount={setAmount}
                  bnbPrice={bnbPrice}
                  days={day}
                  percentage={
                    effectiveShares / (+globalShares + effectiveShares)
                  }
                />
              </span>
            </div>
          ))}
        </div>
        <div className="flex justify-between text-green-400">
          <span className="opacity-50">Est. ROI % Throughout Stake</span>
          <span>
            {(
              (amount.reduce(
                (accumulator, currentValue) => accumulator + +currentValue,
                0
              ) /
                (+quantity * tokenPrice)) *
              100
            ).toFixed(2)}
            %
          </span>
        </div>
      </div>
    </>
  );
};

export default StakeCalculator;
