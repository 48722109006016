import { Tooltip } from "antd";
import { IoMdHelpCircleOutline } from "react-icons/io";

const TooltipWrapper = ({ title, className }) => {
  return (
    <Tooltip title={title}>
      <IoMdHelpCircleOutline className={className ? className : "text-lg"} />
    </Tooltip>
  );
};

export default TooltipWrapper;
