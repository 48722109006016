import { ethers } from "ethers";
import QUOTER_ABI from "../contracts/quoter.json";
import { BSC } from "@usedapp/core";
import { useEffect, useMemo, useState } from "react";

const provider = new ethers.providers.JsonRpcProvider(BSC.rpcUrl); // Replace with your RPC URL
const quoterContract = new ethers.Contract(
  "0xb048bbc1ee6b733fffcfb9e9cef7375518e25997",
  QUOTER_ABI,
  provider
);

const useUniswapV3QuoteOut = (tokenIn, tokenOut, fee, amountOut) => {
  const [amountIn, setAmountIn] = useState();

  const memoizedInputs = useMemo(() => {
    return { tokenIn, tokenOut, fee, amountOut };
  }, [tokenIn, tokenOut, fee, amountOut]);

  useEffect(() => {
    const getQuote = async () => {
      if (
        !memoizedInputs.tokenIn ||
        !memoizedInputs.tokenOut ||
        !memoizedInputs.fee ||
        !memoizedInputs.amountOut
      ) {
        return;
      }

      try {
        const data = await quoterContract.callStatic.quoteExactOutputSingle({
          tokenIn: memoizedInputs.tokenIn,
          tokenOut: memoizedInputs.tokenOut,
          fee: memoizedInputs.fee,
          amount: memoizedInputs.amountOut,
          sqrtPriceLimitX96: 0,
        });

        const newAmountIn = data[0];
        if (!amountIn || !ethers.BigNumber.from(amountIn).eq(newAmountIn)) {
          setAmountIn(newAmountIn);
        }
      } catch (error) {
        console.error("Error getting quote:", error);
      }
    };

    getQuote();
  }, [memoizedInputs, amountIn]);

  return amountIn;
};

export default useUniswapV3QuoteOut;
