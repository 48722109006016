import { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import { ConfigProvider, Table, theme } from "antd";
import { utils } from "ethers";
import Countdown from "../Shared/Countdown";
import useClaimMint from "../../hooks/Miners/write/useClaimMint";
import useTxToast from "../../hooks/useTxToast";
import useUserStakes from "../../hooks/Stakes/read/useUserStakes";
import useUnstake from "../../hooks/Stakes/write/useUnstake";
import useUnstakeAll from "../../hooks/Stakes/write/useUnstakeAll";

const columns = [
  {
    title: "Stake ID",
    dataIndex: "id",
    key: "id",
    width: 100,
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: "Stake Details",
    children: [
      {
        title: "Start Day",
        dataIndex: "start",
        key: "start",
        width: 100,
        sorter: (a, b) => a.start - b.start,
      },
      {
        title: "End Day",
        dataIndex: "end",
        key: "end",
        width: 100,
        sorter: (a, b) => a.end - b.end,
      },
    ],
  },
  {
    title: "DRIPX Staked",
    dataIndex: "value",
    key: "value",
    width: 150,
    sorter: (a, b) => a.value - b.value,
  },
  {
    title: "Stake Shares",
    children: [
      {
        title: "Eff. Share Rate",
        dataIndex: "rate",
        key: "rate",
        width: 150,
        sorter: (a, b) => a.rate - b.rate,
      },
      {
        title: "Shares",
        dataIndex: "shares",
        key: "shares",
        width: 75,
        sorter: (a, b) => a.shares - b.shares,
      },
      {
        title: "Market Value",
        dataIndex: "marketValue",
        key: "marketValue",
        width: 75,
        sorter: (a, b) =>
          a.marketValue.replace("$", "") - b.marketValue.replace("$", ""),
      },
    ],
  },
  {
    title: "Progress",
    dataIndex: "progress",
    key: "progress",
    width: 100,
    sorter: (a, b) => a.progress.replace("%", "") - b.progress.replace("%", ""),
  },
  {
    title: "Redeem",
    dataIndex: "redeem",
    key: "redeem",
    width: 100,
    sorter: (a, b) => a.endTimestamp - b.endTimestamp,
  },
];

const StakesModal = ({ onSelect, tokenPrice }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const userStakes = useUserStakes();

  const { unstake, state: unstakeState } = useUnstake();
  const { unstakeAll, state: unstakeAllState } = useUnstakeAll();

  useTxToast(unstakeState, "Unstaked!");
  useTxToast(unstakeAllState, "Unstaked!");

  const data = userStakes.map((stake, index) => {
    const value = +utils.formatUnits(stake[3], 18);
    const marketValue = utils.formatUnits(stake[3], 18) * tokenPrice;
    const shares = utils.formatUnits(stake[1], 18);
    const startTimestamp = utils.formatUnits(stake[7], 0);
    const endTimestamp = utils.formatUnits(stake[8], 0);
    const progress = Math.min(
      100,
      ((Date.now() / 1000 - startTimestamp) / (endTimestamp - startTimestamp)) *
        100
    );
    const id = utils.formatUnits(stake[0], 0);

    return {
      id: id,
      start: utils.formatUnits(stake[4], 0),
      end: utils.formatUnits(stake[5], 0),
      value: value.toFixed(2),
      marketValue: "$" + marketValue.toFixed(2),
      rate: (value / shares).toFixed(2),
      shares: (+shares).toFixed(2),
      progress: progress.toFixed(2) + "%",
      redeem:
        Date.now() > endTimestamp * 1000 ? (
          <button
            disabled={!stake[9]}
            onClick={async () => await unstake(id)}
            className={`${
              stake[9] ? "opacity-100" : "opacity-30"
            } w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-2xl font-medium text-white not-italic`}
          >
            {stake[9] ? "Unstake" : "Unstaked"}
          </button>
        ) : (
          <Countdown targetDate={endTimestamp * 1000} />
        ),
      endTimestamp,
      active: stake[9],
    };
  });

  return (
    <>
      <div className="max-h-full overflow-y-auto z-[100] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg w-[95%] bg-[#0C0518] rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
        <div className="flex justify-between items-center">
          <span className=" font-medium text-[19px] w-fit">Your Stakes</span>
          <img
            alt="close"
            src={CloseIcon}
            className="cursor-pointer"
            onClick={() => onSelect()}
          />
        </div>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
            token: {
              // Seed Token
              colorPrimary: "#fff",
              borderRadius: 2,

              // Alias Token
              colorBgContainer: "#D9D9D902",
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={data}
            bordered
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: "400px",
            }}
            //make the table dark mode
            className="rouned-xl rounded-lg scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-slate-700 scrollbar-track-slate-300"
            pagination={false}
            summary={(pageData) => {
              let totalValue = 0;
              let totalShares = 0;
              let totalMarketValue = 0;
              let unstakeable = 0;

              pageData.forEach(
                ({ value, shares, marketValue, active, endTimestamp }) => {
                  totalValue += +value;
                  totalShares += +shares;
                  totalMarketValue += +marketValue.replace("$", "");
                  if (active && Date.now() > endTimestamp * 1000) {
                    unstakeable++;
                  }
                }
              );

              return (
                <Table.Summary fixed>
                  <Table.Summary.Row>
                    <Table.Summary.Cell colSpan={3} index={0}>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                      {totalValue.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                      {(totalValue / totalShares).toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                      {totalShares.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4}>
                      ${totalMarketValue.toFixed(2)}
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                    <Table.Summary.Cell index={7}>
                      <button
                        disabled={unstakeable === 0}
                        onClick={async () => await unstakeAll()}
                        className={`${
                          unstakeable !== 0 ? "opacity-100" : "opacity-30"
                        } w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-2 rounded-2xl font-medium text-white not-italic`}
                      >
                        Unstake All
                      </button>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }}
          />
        </ConfigProvider>
      </div>
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-20"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default StakesModal;
