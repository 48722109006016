import { NavLink } from "react-router-dom";
import HeaderLogo from "../../assets/images/dripx.png";
import Web3ModalButton from "../Shared/Web3ModalButton";
import { IoMenu } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import { useEffect, useState } from "react";
import { BSC, Mainnet, useEthers } from "@usedapp/core";
import EthLogo from "../../assets/images/eth-logo.svg";
import BscLogo from "../../assets/images/bsc-logo.svg";
import Countdown from "../Shared/Countdown";
import useGenesisTs from "../../hooks/Miners/read/useGenesisTs";
import useCurrentContractDay from "../../hooks/Miners/read/useCurrentContractDay";
import { utils } from "ethers";

const HeaderLinks = ({ handleClick }) => {
  const { account } = useEthers();
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  return (
    <>
      <NavLink
        to={"/mine"}
        className={({ isActive }) =>
          isActive &&
          "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
        }
        onClick={handleClick}
      >
        Mine
      </NavLink>
      {/* <NavLink
        to={'/bridge'}
        className={({ isActive }) =>
          isActive &&
          'text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy'
        }
        onClick={handleClick}
      >
        Bridge
      </NavLink> */}
      <NavLink
        to={"/faucets"}
        className={({ isActive }) =>
          isActive &&
          "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
        }
        onClick={handleClick}
      >
        Faucets
      </NavLink>
      <NavLink
        to={"/stake"}
        className={({ isActive }) =>
          isActive &&
          "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
        }
        onClick={handleClick}
      >
        Stake
      </NavLink>
      <NavLink
        to={"/payouts"}
        className={({ isActive }) =>
          isActive &&
          "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
        }
        onClick={handleClick}
      >
        Payouts
      </NavLink>
      <a
        href={"https://icebridge.dripx.win"}
        target="_blank"
        rel="noreferrer"
        onClick={handleClick}
      >
        Bridge
      </a>
      <div
        className="relative"
        onMouseEnter={() => setIsMoreOpen(true)}
        onMouseLeave={() => setIsMoreOpen(false)}
      >
        <div to={"/buynow"} className="relative z-[2] cursor-pointer">
          More <IoIosArrowDown className="inline" />
        </div>
        {isMoreOpen && (
          <div className="absolute top-8 md:top-0 md:pt-16 -right-4 md:-left-4 flex flex-col gap-1 bg-header p-4 rounded-lg w-[160px]">
            <NavLink
              to={"/reservoir"}
              className={({ isActive }) =>
                isActive &&
                "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
              }
              onClick={handleClick}
            >
              Reservoir
            </NavLink>
            <NavLink
              to={"/vault"}
              className={({ isActive }) =>
                isActive &&
                "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
              }
              onClick={() => {
                setIsMoreOpen(false);
                handleClick();
              }}
            >
              Vault
            </NavLink>
            <NavLink
              to={"/lookup"}
              className={({ isActive }) =>
                isActive &&
                "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
              }
              onClick={() => {
                setIsMoreOpen(false);
                handleClick();
              }}
            >
              Look Up
            </NavLink>
            <NavLink
              to={"/calculator"}
              className={({ isActive }) =>
                isActive &&
                "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
              }
              onClick={() => {
                setIsMoreOpen(false);
                handleClick();
              }}
            >
              Calculator
            </NavLink>
            <NavLink
              to={"/profile"}
              className={({ isActive }) =>
                isActive &&
                "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
              }
              onClick={() => {
                setIsMoreOpen(false);
                handleClick();
              }}
            >
              PFP Creator
            </NavLink>
            <NavLink
              to={"/swap"}
              className={({ isActive }) =>
                isActive &&
                "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
              }
              onClick={() => {
                setIsMoreOpen(false);
                handleClick();
              }}
            >
              Buy DRIPX
            </NavLink>
            <a href="https://kitchensink.gg/" target="_blank" rel="noreferrer">
              Kitchen Sink
            </a>
            {account &&
              account.toLowerCase() ===
                "0xe90C5C1D36aB80FfcCCca40C4989633026EF45Fa".toLowerCase() && (
                <NavLink
                  to={"/admin"}
                  className={({ isActive }) =>
                    isActive &&
                    "text-transparent bg-clip-text bg-gradient-to-r from-pinky to-yellowy"
                  }
                  onClick={() => {
                    setIsMoreOpen(false);
                    handleClick();
                  }}
                >
                  Admin
                </NavLink>
              )}
          </div>
        )}
      </div>
      {/* <NavLink
          to={'/charts'}
          className={({ isActive }) =>
            isActive
              ? 'border-b-primary border-b-2'
              : 'border-b-2 border-transparent'
          }
        onClick={handleClick}
        >
          Charts
        </NavLink> */}
    </>
  );
};

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const genesisTs = useGenesisTs();
  const currentContractDay = useCurrentContractDay();

  return (
    <>
      <div
        className={`${
          isMobileMenuOpen ? "rounded-b-0" : "drop-shadow-lg"
        } z-50 transition-all flex flex-col fixed transform -translate-x-1/2 left-1/2 top-0 w-full h-[95px] bg-header px-4 md:px-6 py-4 gap-6`}
      >
        <div
          className={`h-full max-h-16 flex justify-between items-center relative font-medium`}
        >
          <NavLink to={"/swap"}>
            <img
              src={HeaderLogo}
              className="w-[105px] md:w-fit md:h-full max-h-7"
              alt="logo"
            />
          </NavLink>
          <div className="hidden md:flex gap-12 items-center text-xl text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <HeaderLinks
              handleClick={() => {
                setIsMobileMenuOpen(false);
                window.scrollTo(0, 0);
              }}
            />
          </div>
          <div className="flex gap-2 md:gap-3 relative">
            {/* <div
              className={`${
                isNetworkMenuOpen ? 'flex' : 'hidden'
              } absolute -bottom-2 left-0 translate-y-full leading-[14px] flex-col gap-2 bg-[#D9D9D9] bg-opacity-20 rounded-2xl text-white p-4`}
            >
              <div>Select a network</div>
              <div className='h-px w-full bg-white'></div>
              <div className='flex flex-col gap-4'>
                {Object.keys(chains).map((el) => (
                  <div
                    className='flex items-center gap-2 cursor-pointer'
                    onClick={() => {
                      switchNetwork(+el)
                      setIsNetworkMenuOpen(false)
                    }}
                  >
                    <img className='h-5' src={chains[el].logo} alt='chain' />
                    {chains[el].name}
                  </div>
                ))}
              </div>
            </div> */}
            <div className="bg-gradient-to-r from-pinky to-yellowy bg-opacity-30 rounded-[30px] p-[2px]">
              <div className="group flex gap-2 items-center h-full bg-header px-4 md:px-5 py-2 rounded-[30px] text-white font-medium text-sm md:text-base">
                <span className="group-hover:hidden">
                  Day {utils.formatUnits(currentContractDay, 0)}
                </span>
                <span className="hidden group-hover:flex">
                  <Countdown
                    targetDate={
                      (+genesisTs + 86400 * currentContractDay) * 1000
                    }
                  />
                </span>
              </div>
            </div>
            <div className="bg-gradient-to-r from-pinky to-yellowy rounded-[30px] p-[2px]">
              <Web3ModalButton className="flex gap-2 items-center h-full bg-header px-4 md:px-5 py-2 rounded-[30px] text-white font-medium text-sm md:text-base" />
            </div>
          </div>
        </div>
      </div>
      <div className="z-[45] flex md:hidden absolute top-[calc(95px+2.5rem)] text-white w-[90%] py-2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg-[#0C0518] bg-opacity-75 rounded-[10px] px-4 justify-between">
        <HeaderLinks handleClick={() => setIsMobileMenuOpen(false)} />
      </div>
      <div
        className={`${
          isMobileMenuOpen ? "top-16 " : "-top-full "
        } absolute w-full px-6 py-4 bg-white rounded-b-2xl z-10 transition-all duration-150 md:h-0 flex flex-col gap-4 items-center`}
      >
        <HeaderLinks handleClick={() => setIsMobileMenuOpen(false)} />
        <Web3ModalButton />
      </div>
    </>
  );
};

export default Header;
