import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract } from "ethers";
import { FACUET_CONTRACT } from "../../../consts";
import abi from "../../../contracts/faucet.json";

const useClaimAll = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(FACUET_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendClaimAll, state } = useContractFunction(
    contract,
    "claimAll"
  );

  const claimAll = async () => {
    try {
      if (account) {
        await sendClaimAll();
      }
    } catch (error) {
      console.error("Error while contributing:", error);
    }
  };

  return { claimAll, state };
};

export default useClaimAll;
