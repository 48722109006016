import { ethers } from "ethers";
import QUOTER_ABI from "../contracts/quoter.json";
import { BSC } from "@usedapp/core";
import { useEffect, useMemo, useState } from "react";

const provider = new ethers.providers.JsonRpcProvider(BSC.rpcUrl); // Replace with your RPC URL
const quoterContract = new ethers.Contract(
  "0xb048bbc1ee6b733fffcfb9e9cef7375518e25997",
  QUOTER_ABI,
  provider
);

const useUniswapV3QuoteIn = (tokenIn, tokenOut, fee, amountIn) => {
  const [amountOut, setAmountOut] = useState();

  const memoizedInputs = useMemo(() => {
    return { tokenIn, tokenOut, fee, amountIn };
  }, [tokenIn, tokenOut, fee, amountIn]);

  useEffect(() => {
    const getQuote = async () => {
      if (
        !memoizedInputs.tokenIn ||
        !memoizedInputs.tokenOut ||
        !memoizedInputs.fee ||
        !memoizedInputs.amountIn
      ) {
        return;
      }

      try {
        const data = await quoterContract.callStatic.quoteExactInputSingle({
          tokenIn: memoizedInputs.tokenIn,
          tokenOut: memoizedInputs.tokenOut,
          fee: memoizedInputs.fee,
          amountIn: memoizedInputs.amountIn,
          sqrtPriceLimitX96: 0,
        });
        const newAmountOut = data[0];
        if (!amountOut || !ethers.BigNumber.from(amountOut).eq(newAmountOut)) {
          setAmountOut(newAmountOut);
        }
      } catch (error) {
        console.error("Error getting quote:", error);
      }
    };

    getQuote();
  }, [memoizedInputs, amountOut]);

  return amountOut;
};

export default useUniswapV3QuoteIn;
