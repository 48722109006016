import { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/close.svg";
import useUpdateMinerName from "../../hooks/Miners/write/useUpdateMinerName";
import useTxToast from "../../hooks/useTxToast";

const RenameMinerModal = ({ onSelect, minerId }) => {
  const [name, setName] = useState("");
  const { updateMinerName, state } = useUpdateMinerName();

  useTxToast(state, "Miner name updated successfully", onSelect);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <>
      <div className="max-h-full overflow-y-auto z-[102] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg bg-[#0C0518] rounded-2xl border-[2px] border-white text-white flex flex-col gap-4 py-6 px-7">
        <div className="flex justify-between items-center">
          <span className=" font-medium text-[19px] w-fit">Rename Miner</span>
          <img
            alt="close"
            src={CloseIcon}
            className="cursor-pointer"
            onClick={() => onSelect()}
          />
        </div>
        <div className="bg-[#191325] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-[16px] pt-4 pb-4 px-6 flex flex-col items-end relative">
          <input
            className="h-[1.375rem] font-medium text-xl bg-transparent outline-none w-[calc(100%)]"
            onChange={({ target }) => {
              setName(target.value);
            }}
            value={name}
            placeholder="Enter new name"
          />
        </div>
        <button
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-xl text-white not-italic"
          onClick={async () => await updateMinerName(minerId, name)}
        >
          Rename
        </button>
      </div>
      <div
        className="bg-[#0C0518] opacity-80 fixed top-0 left-0 h-full w-full z-[101]"
        onClick={() => onSelect()}
      ></div>
    </>
  );
};

export default RenameMinerModal;
