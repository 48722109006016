import { useEthers, useContractFunction } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/buyandburn.json";

const useOwnerBuyAndBurn = (contract_) => {
  const { library, account } = useEthers();
  const contract =
    library && contract_
      ? new Contract(contract_, abi, library.getSigner())
      : null;
  const { send: sendOwnerBuyAndBurn, state } = useContractFunction(
    contract,
    "ownerBuyAndBurn"
  );

  const ownerBuyAndBurn = async (newCap) => {
    try {
      if (account) {
        await sendOwnerBuyAndBurn(utils.parseEther(newCap + ""));
      }
    } catch (error) {
      console.error("Error while activating:", error);
    }
  };

  return { ownerBuyAndBurn, state };
};

export default useOwnerBuyAndBurn;
