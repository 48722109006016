import { BSC, useCall } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/miners.json";
import { MINER_CONTRACT } from "../../../consts";

const useMiners = (id) => {
  const { value, error } =
    useCall(
      MINER_CONTRACT[BSC.chainId] &&
        id !== undefined && {
          contract: new Contract(MINER_CONTRACT[BSC.chainId], abi),
          method: "miners",
          args: [id],
        }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return [];
  }
  console.log("miners lookup", value);
  return value ? value : [];
};

export default useMiners;
