import { useEthers, useContractFunction } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/buyandburn.json";

const useUpdateCap = (contract_) => {
  const { library, account } = useEthers();
  const contract =
    library && contract_
      ? new Contract(contract_, abi, library.getSigner())
      : null;
  const { send: sendUpdateCap, state } = useContractFunction(
    contract,
    "updateCap"
  );

  const updateCap = async (newCap) => {
    try {
      if (account) {
        await sendUpdateCap(utils.parseEther(newCap + ""));
      }
    } catch (error) {
      console.error("Error while activating:", error);
    }
  };

  return { updateCap, state };
};

export default useUpdateCap;
