import { useEthers, useContractFunction, BSC } from "@usedapp/core";
import { Contract, utils } from "ethers";
import { STAKE_CONTRACT } from "../../../consts";
import abi from "../../../contracts/stakes.json";

const useDepositToPool = () => {
  const { library, account } = useEthers();
  const contract = library
    ? new Contract(STAKE_CONTRACT[BSC.chainId], abi, library.getSigner())
    : null;
  const { send: sendDepositToPool, state } = useContractFunction(
    contract,
    "depositToPool"
  );

  const depositToPool = async (pool, amount) => {
    try {
      if (account) {
        await sendDepositToPool(pool, {
          value: utils.parseEther(amount),
        });
      }
    } catch (error) {
      console.error("Error while staking:", error);
    }
  };

  return { depositToPool, state };
};

export default useDepositToPool;
