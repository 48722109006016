export const PulseChain = {
  chainId: 369,
  chainName: "PulseChain",
  isTestChain: false,
  isLocalChain: false,
  multicallAddress: "0xcA11bde05977b3631167028862bE2a173976CA11",
  getExplorerAddressLink: (address) =>
    `https://scan.pulsechain.com/address/${address}`,
  getExplorerTransactionLink: (transactionHash) =>
    `https://scan.pulsechain.com/tx/${transactionHash}`,
  rpcUrl: "https://pulsechain.publicnode.com",
  blockExplorerUrl: "https://scan.pulsechain.com",
  nativeCurrency: {
    name: "PulseChain",
    symbol: "PLS",
    decimals: 18,
  },
};
