import ArrowIcon from "../assets/images/arrow.svg";
import { IoMdHelpCircleOutline } from "react-icons/io";
import "./Stake.module.css";
import { useEffect, useMemo, useState } from "react";
import { useEtherBalance, useEthers } from "@usedapp/core";
import { createPortal } from "react-dom";
import useMintMiner from "../hooks/Miners/write/useMintMiner";
import useTxToast from "../hooks/useTxToast";
import useCurrentMintCost from "../hooks/Miners/read/useCurrentMintCost";
import useCurrentMintable from "../hooks/Miners/read/useCurrentMintable";
import useCurrentEAA from "../hooks/Miners/read/useCurrentEAA";
import useTotalSupply from "../hooks/Miners/read/useTotalSupply";
import Countdown from "../components/Shared/Countdown";
import useGenesisTs from "../hooks/Miners/read/useGenesisTs";
import { utils } from "ethers";
import MinersModal from "../components/Miners/MinersModal";
import useCurrentContractDay from "../hooks/Miners/read/useCurrentContractDay";
import useBatchMintMiner from "../hooks/Miners/write/useBatchMintMiner";
import { Tooltip } from "antd";
import Tooltips from "../assets/tooltips";
import TooltipWrapper from "../components/Shared/TooltipWrapper";
import useDripMintingOnDay from "../hooks/Miners/read/useDripMintingOnDay";
import useMaxDripMintingOnDay from "../hooks/Miners/read/useMaxDripMintingOnDay";
import { TOKEN_CONTRACT } from "../consts";

function formatNumber(num) {
  if (num >= 1e9) {
    return (num / 1e9).toFixed(2) + "B";
  } else if (num >= 1e6) {
    return (num / 1e6).toFixed(2) + "M";
  } else if (num >= 1e3) {
    return (num / 1e3).toFixed(2) + "K";
  }
  return num.toString();
}

// const calculateValueAtExpiry = (mintPower, numOfDays, mintable, eaa) => {
//   let baseReward = mintable * mintPower * numOfDays;
//   if (numOfDays != 1) baseReward -= (baseReward * 11 * (numOfDays - 1)) / 10000;

//   let reward = baseReward;
//   if (eaa > 0) {
//     reward += (baseReward * eaa) / 100;
//   }
//   return reward / 100;
// };

const calculateValueAtExpiry = (mintPower, numOfDays, mintable, eaa) => {
  let sumMintable = 0;

  mintable = +mintable;

  for (let i = 0; i < numOfDays; i++) {
    sumMintable += mintable;
    mintable = (mintable * 99_90) / 100_00;
    if (mintable < 800) mintable = 800;
  }

  let averageMintable = sumMintable / numOfDays;

  let totalRewards = (averageMintable * mintPower * numOfDays) / 100;

  if (eaa > 0) {
    totalRewards += (totalRewards * eaa) / 100;
  }

  return totalRewards;
};

const Miner = () => {
  const { account, chainId } = useEthers();

  const [showStakings, setShowStakings] = useState(false);

  const [tokenPrice, setTokenPrice] = useState(0.0);
  const [bnbPrice, setBnbPrice] = useState(0);
  const [duration, setDuration] = useState(280);
  const [power, setPower] = useState(100);
  const [nMiners, setNMiners] = useState(8);
  const [isBatch, setIsBatch] = useState(false);
  const bnbBalance = useEtherBalance(account);

  const currentMintCost = useCurrentMintCost();
  const currentMintable = useCurrentMintable();
  const currentEAA = useCurrentEAA();
  const totalSupply = useTotalSupply();
  const genesisTs = useGenesisTs();
  const currentContractDay = useCurrentContractDay();

  const dripMintingOnDay = useDripMintingOnDay(+duration + +currentContractDay);
  const maxDripMintingOnDay = useMaxDripMintingOnDay();

  const { mintMiner, state: mintMinerState } = useMintMiner();
  const { batchMintMiner, state: batchMintMinerState } = useBatchMintMiner();

  useTxToast(mintMinerState, "Miner created successfully");
  useTxToast(batchMintMinerState, "Miners created successfully");

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x172fcd41e0913e95784454622d1c3724f546f849"
      )
        .then((res) => res.json())
        .then((data) => {
          setBnbPrice(data.pairs[0].priceNative);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [wdripPrice, setWdripPrice] = useState(0);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x41e3149918f8EFeE8Ef6f47CF45D4CE580F837cB"
      )
        .then((res) => res.json())
        .then((data) => {
          setWdripPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0xcc9834a4e8b5b22bdfb0fcc7d43430ff9dabedca"
      )
        .then((res) => res.json())
        .then((data) => {
          setTokenPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // useEffect(() => {
  //   let interval = setInterval(async () => {
  //     fetch(
  //       `https://api.dexscreener.com/latest/dex/tokens/${TOKEN_CONTRACT[chainId]}`
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         const pair = data.pairs.filter(
  //           (el) => el.quoteToken.symbol == "WDRIP"
  //         )[0];
  //         console.log(
  //           pair.priceNative,
  //           wdripPrice,
  //           pair.priceNative * wdripPrice
  //         );
  //         setTokenPrice((pair.priceNative ?? 0) * (wdripPrice ?? 0));
  //       });
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [chainId, wdripPrice]);

  const valueAtExpiry = useMemo(
    () =>
      calculateValueAtExpiry(power, duration, currentMintable, currentEAA) *
      (isBatch ? nMiners : 1),
    [power, duration, currentMintable, currentEAA, nMiners, isBatch]
  );

  const priceOfMintCost = useMemo(
    () =>
      (((Date.now() > (+genesisTs + 86400 * currentContractDay) * 1000
        ? currentMintCost *
          5 *
          Math.pow(
            1.0012,
            Math.floor(
              1 +
                (Math.floor(Date.now() / 1000) -
                  (+genesisTs + 86400 * currentContractDay)) /
                  86400
            )
          )
        : currentMintCost * 1.01) *
        power) /
        100) *
      (isBatch ? nMiners * 1.01 : 1),
    [currentContractDay, genesisTs, currentMintCost, power, nMiners, isBatch]
  );

  const usdPriceOfMintCost = useMemo(
    () => priceOfMintCost * bnbPrice,
    [priceOfMintCost, bnbPrice]
  );

  const marketValueAtExpiry = useMemo(
    () => valueAtExpiry * tokenPrice,
    [valueAtExpiry, tokenPrice]
  );

  const estROI = useMemo(
    () =>
      ((marketValueAtExpiry - usdPriceOfMintCost) / usdPriceOfMintCost) * 100,
    [marketValueAtExpiry, usdPriceOfMintCost]
  );

  const handleCreate = async () => {
    if (isBatch) {
      await batchMintMiner(
        power,
        duration,
        nMiners,
        utils.parseEther(priceOfMintCost.toFixed(6))
      );
      return;
    }
    await mintMiner(
      power,
      duration,
      utils.parseEther(priceOfMintCost.toFixed(6))
    );
  };

  return (
    <div className="w-full mt-[95px] py-20 flex flex-col lg:flex-row gap-6 justify-center items-center lg:items-start relative content">
      <div className="shadow-lg w-[85%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Mine</span>
          {account && (
            <span
              className="text-lg self-end flex gap-2 cursor-pointer bg-gradient-to-r from-pinky to-yellowy px-2 rounded-md"
              onClick={() => setShowStakings(true)}
            >
              Manage miners
              <img alt="go" src={ArrowIcon} />
            </span>
          )}
        </div>
        <div className="flex justify-between -mb-3">
          <div className="flex gap-1">
            <div
              className={`${
                isBatch && "opacity-50"
              } transition-all bg-[#0C0518] bg-opacity-70 text-white py-2 px-2 w-fit rounded-t-lg cursor-pointer`}
              onClick={() => setIsBatch(false)}
            >
              Single
            </div>
            <div
              className={`${
                !isBatch && "opacity-50"
              } transition-all bg-[#0C0518] bg-opacity-70 text-white py-2 px-2 w-fit rounded-t-lg cursor-pointer`}
              onClick={() => setIsBatch(true)}
            >
              Batch
            </div>
          </div>
          <div className="w-full text-right text-base font-medium pr-[6px] text-white">
            <span className="text-xs opacity-50 mr-1 font-normal">Balance</span>
            {bnbBalance ? (+utils.formatEther(bnbBalance)).toFixed(6) : 0} BNB
          </div>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl rounded-tl-none shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">
                Duration
                <TooltipWrapper title={Tooltips.MINERS.DURATION} />
              </span>
              <span>
                <input
                  type="number"
                  className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                  value={duration}
                  max={280}
                  min={8}
                  onChange={({ target }) => setDuration(target.value)}
                  onBlur={({ target }) =>
                    setDuration(
                      +target.value > 280
                        ? 280
                        : +target.value < 8
                        ? 8
                        : +target.value
                    )
                  }
                />
                <span className="opacity-50 ml-2">DAYS</span>
              </span>
            </div>
            <div className="relative">
              <input
                type="range"
                min="8"
                max="280"
                className="w-full appearance-none relative z-[3] bg-transparent"
                value={duration}
                onChange={({ target }) => setDuration(target.value)}
              />
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">
                DripX Daily Cap
                <TooltipWrapper title={Tooltips.MINERS.DAILY_CAP} />
              </span>
              {formatNumber(+dripMintingOnDay)} /{" "}
              {formatNumber(+maxDripMintingOnDay)}
            </div>
          </div>
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">
                Power
                <TooltipWrapper title={Tooltips.MINERS.POWER} />
              </span>
              <input
                type="number"
                className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                value={power}
                max={100}
                min={1}
                onChange={({ target }) => setPower(target.value)}
                onBlur={({ target }) =>
                  setPower(
                    target.value > 100
                      ? 100
                      : target.value < 1
                      ? 1
                      : target.value
                  )
                }
              />
            </div>
            <div className="relative">
              <input
                type="range"
                min="1"
                max="100"
                className="w-full appearance-none relative z-[3] bg-transparent"
                value={power}
                onChange={({ target }) => setPower(target.value)}
              />
            </div>
          </div>
          {isBatch && (
            <div className="flex flex-col">
              <div className="flex justify-between text-sm text-white">
                <span className="opacity-50">Number of Miners</span>
                <span>
                  <input
                    type="number"
                    className=" text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                    value={nMiners}
                    max={8}
                    min={2}
                    onChange={({ target }) => setNMiners(target.value)}
                    onBlur={({ target }) =>
                      setNMiners(
                        +target.value > 8
                          ? 8
                          : +target.value < 2
                          ? 2
                          : +target.value
                      )
                    }
                  />
                  <span className="opacity-50 ml-2">MINERS</span>
                </span>
              </div>
              <div className="relative">
                <input
                  type="range"
                  min="2"
                  max="8"
                  className="w-full appearance-none relative z-[3] bg-transparent"
                  value={nMiners}
                  onChange={({ target }) => setNMiners(target.value)}
                />
              </div>
            </div>
          )}
        </div>
        <button
          className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] p-4 rounded-2xl font-medium text-2xl text-white not-italic"
          onClick={handleCreate}
        >
          Create
        </button>
      </div>

      <div className="shadow-lg w-[85%] md:w-[480px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Summary & Estimated ROI</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-blue-400">
              <span className="opacity-50 flex gap-2 items-center">
                Est. DRIPX at End of Miner{nMiners > 1 && isBatch && "s"}
                <TooltipWrapper title={Tooltips.MINERS.EST_DRIPX_AT_END} />
              </span>
              <span>{valueAtExpiry.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-orange-400">
              <span className="opacity-50 ">
                BNB to Start Miner{nMiners > 1 && isBatch && "s"}
              </span>
              <span>{`${priceOfMintCost.toFixed(
                6
              )} BNB (~$${usdPriceOfMintCost.toFixed(2)})`}</span>
            </div>
            <div className="flex justify-between text-sm text-green-400">
              <span className="opacity-50 flex gap-2 items-center">
                $ Market Value of Miner{nMiners > 1 && isBatch && "s"}
                <TooltipWrapper title={Tooltips.MINERS.MARKET_VALUE_MINER} />
              </span>
              <span>${marketValueAtExpiry.toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-green-400">
              <span className="opacity-50">
                Est. ROI % at End of Miner{nMiners > 1 && isBatch && "s"}
              </span>
              <span>{estROI.toFixed(2)}%</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">DRIPX Details</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                DRIPX Market Price
                <TooltipWrapper title={Tooltips.MINERS.DRIPX_MARKET_PRICE} />
              </span>
              <span>${(+tokenPrice).toFixed(7)}</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">DRIPX Miner Details</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50">Global DRank</span>
              <span>{+totalSupply + 1}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50">
                Current DRIPX Per Day of Mining
              </span>
              <span>{(+currentMintable).toFixed(2)}</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50  flex gap-2 items-center">
                Early Adoption Amplifier
                <TooltipWrapper title={Tooltips.MINERS.EAA} />
              </span>
              <span>+{(+currentEAA).toFixed(6)}%</span>
            </div>
            <div className="flex justify-between text-sm text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Next Difficulty Increase
                <TooltipWrapper
                  title={Tooltips.MINERS.NEXT_DIFFICULTY_INCREASE}
                />
              </span>
              <span>
                <Countdown
                  targetDate={(+genesisTs + 86400 * currentContractDay) * 1000}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      {showStakings &&
        createPortal(
          <MinersModal
            onSelect={() => setShowStakings(false)}
            tokenPrice={tokenPrice}
          />,
          document.body
        )}
    </div>
  );
};

export default Miner;
