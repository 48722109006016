import { BSC, useCall, useEthers } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/faucet.json";
import { FACUET_CONTRACT } from "../../../consts";

const useAvailableRewards = () => {
  const { account } = useEthers();

  const { value, error } =
    useCall(
      FACUET_CONTRACT[BSC.chainId] && {
        contract: new Contract(FACUET_CONTRACT[BSC.chainId], abi),
        method: "availableRewards",
        args: [account],
      }
    ) ?? {};

  console.log("ava", value);

  if (error) {
    console.error(error.message);
    return 0;
  }
  return value?.[0] ? utils.formatEther(value?.[0]) : 0;
};

export default useAvailableRewards;
