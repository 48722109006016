import "./Stake.module.css";
import { useEffect, useState } from "react";

import BuyAndBurnComponent from "../components/BuyAndBurn/BuyAndBurnComponent";
import { TOKEN_CONTRACT } from "../consts";
import { useEthers } from "@usedapp/core";

const BuyAndBurn = () => {
  const { library } = useEthers();
  const [tokenPrice, setTokenPrice] = useState(0.00004);
  const [bnbPrice, setBnbPrice] = useState(0);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x172fcd41e0913e95784454622d1c3724f546f849"
      )
        .then((res) => res.json())
        .then((data) => {
          setBnbPrice(data.pairs[0].priceNative);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const [wdripPrice, setWdripPrice] = useState(0);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0x41e3149918f8EFeE8Ef6f47CF45D4CE580F837cB"
      )
        .then((res) => res.json())
        .then((data) => {
          setWdripPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    let interval = setInterval(async () => {
      fetch(
        "https://api.dexscreener.com/latest/dex/pairs/bsc/0xcc9834a4e8b5b22bdfb0fcc7d43430ff9dabedca"
      )
        .then((res) => res.json())
        .then((data) => {
          setTokenPrice(data.pairs[0].priceUsd);
        });
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const addChain = async () => {
    try {
      await library.provider.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0x38", // Hexadecimal version of 56, for BSC Mainnet
            chainName: "BSC Mainnet (PancakeSwap Private RPC)",
            nativeCurrency: {
              name: "Binance Coin",
              symbol: "BNB", // 2-6 characters long
              decimals: 18,
            },
            rpcUrls: ["https://pancake-bnb.rpc.blxrbdn.com"],
            blockExplorerUrls: ["https://bscscan.com/"],
          },
        ],
      });
    } catch (addError) {
      console.error("Error adding chain: ", addError);
      // create a new tab to
      window.open(
        "https://docs.pancakeswap.finance/products/pancakeswap-private-rpc",
        "_blank"
      );
    }
  };

  // useEffect(() => {
  //   let interval = setInterval(async () => {
  //     fetch(
  //       `https://api.dexscreener.com/latest/dex/tokens/${TOKEN_CONTRACT[chainId]}`
  //     )
  //       .then((res) => res.json())
  //       .then((data) => {
  //         const pair = data.pairs.filter(
  //           (el) => el.quoteToken.symbol == "WDRIP"
  //         )[0];
  //         setTokenPrice((pair.priceNative ?? 0) * (wdripPrice ?? 0));
  //       });
  //   }, 3000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [chainId, wdripPrice]);

  return (
    <div className="w-full min-h-[calc(100vh-338px)] mt-[95px] py-20 flex flex-col gap-6 justify-center items-center relative content">
      {/* <div className="shadow-lg w-[85%] md:w-[480px] lg:w-[984px] bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4">
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium">Required</span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col ">
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                <p>
                  Use{" "}
                  <button
                    className="text-blue-400 hover:underline"
                    // href="https://docs.pancakeswap.finance/products/pancakeswap-private-rpc"
                    target="_blank"
                    rel="noreferrer"
                    onClick={addChain}
                  >
                    PancakeSwap Private RPC
                  </button>{" "}
                  network when calling the Buy &amp; Burn.
                </p>
              </span>
            </div>
          </div>
        </div>
      </div> */}
      <div className="flex flex-col lg:flex-row gap-6 justify-center lg:items-start items-center">
        <BuyAndBurnComponent
          bnbPrice={bnbPrice}
          tokenPrice={tokenPrice}
          version={2}
        />
        {/* <BuyAndBurnComponent
          bnbPrice={bnbPrice}
          tokenPrice={tokenPrice}
          version={1}
        /> */}
      </div>
    </div>
  );
};

export default BuyAndBurn;
