import { BSC, useCall, useEthers } from "@usedapp/core";
import { Contract, utils } from "ethers";
import abi from "../../../contracts/faucet.json";
import { FACUET_CONTRACT } from "../../../consts";

const usePlayers = () => {
  const { account } = useEthers();

  const { value, error } =
    useCall(
      FACUET_CONTRACT[BSC.chainId] && {
        contract: new Contract(FACUET_CONTRACT[BSC.chainId], abi),
        method: "players",
        args: [account],
      }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  console.log("players stats", value);
  return value?.[0] ? value?.[0] : 0;
};

export default usePlayers;
