import { useCall, useEthers } from "@usedapp/core";
import { Contract } from "ethers";
import abi from "../../../contracts/faucet.json";
import { FACUET_CONTRACT } from "../../../consts";

const useAvailableRewardsList = () => {
  const { chainId, account } = useEthers();

  const { value, error } =
    useCall(
      account &&
        FACUET_CONTRACT[chainId] && {
          contract: new Contract(FACUET_CONTRACT[chainId], abi),
          method: "availableRewardsList",
          args: [account],
        }
    ) ?? {};
  if (error) {
    console.error(error.message);
    return [];
  }
  return value?.[0] ? value?.[0] : [];
};

export default useAvailableRewardsList;
