import { useEffect, useState } from "react";
import { useEthers, shortenAddress, useLookupAddress } from "@usedapp/core";
import { IoLogOutOutline } from "react-icons/io5";
import { createPortal } from "react-dom";
import ConnectModal from "./ConnectModal";

const Web3ModalButton = ({ className }) => {
  const { account, deactivate } = useEthers();
  const { ens } = useLookupAddress(account);
  const [activateError, setActivateError] = useState("");
  const { error } = useEthers();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (error) {
      setActivateError(error.message);
    }
  }, [error]);

  return (
    <>
      {/* {activateError && <div>{activateError}</div>} */}
      {account ? (
        <button
          onClick={() => deactivate()}
          className={
            className
              ? className
              : `bg-primary shadow-md rounded-2xl text-white px-6 py-3 font-medium flex gap-2 items-center`
          }
        >
          {/* Disconnect{' '} */}
          <p className="hidden lg:inline">{ens ?? shortenAddress(account)}</p>
          <IoLogOutOutline />
        </button>
      ) : (
        <>
          <button
            onClick={() => setShowModal(true)}
            className={
              className
                ? className
                : `bg-primary shadow-md rounded-2xl text-white px-6 py-3 font-medium`
            }
          >
            Connect Wallet
          </button>
          {showModal &&
            createPortal(
              <ConnectModal onSelect={() => setShowModal(false)} />,
              document.body
            )}
        </>
      )}
    </>
  );
};

export default Web3ModalButton;
