import React, { useState } from "react";
import useCurrentCyclePayoutAmout from "../../hooks/Stakes/read/useCurrentCyclePayoutAmout";
import useCurrentCyclePayoutIndex from "../../hooks/Stakes/read/useCurrentCyclePayoutIndex";
import Tooltips from "../../assets/tooltips";
import TooltipWrapper from "../Shared/TooltipWrapper";
import usePeggedBNBAmount from "../../hooks/Admin/read/usePeggedBNBAmount";
import useWithdrawFromPool from "../../hooks/Admin/write/useWithdrawFromPool";
import useDepositToPool from "../../hooks/Admin/write/useDepositToPool";
import useTxToast from "../../hooks/useTxToast";
import { utils } from "ethers";

const AdminPayoutCycle = ({ days, bnbPrice, bnbBalance }) => {
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [amount, setAmount] = useState(0);

  const currentCyclePayoutAmount = useCurrentCyclePayoutAmout(days);
  const globalCyclePayoutUsd = currentCyclePayoutAmount * bnbPrice;

  const peggedBNBAmount = usePeggedBNBAmount(days);
  const peggedBNBAmountUsd = peggedBNBAmount * bnbPrice;

  const { withdrawFromPool, state: withdrawState } = useWithdrawFromPool();
  const { depositToPool, state: depositState } = useDepositToPool();

  useTxToast(depositState, "Deposited!");
  useTxToast(withdrawState, "Withdrawn!");

  return (
    <>
      <div
        className={`shadow-lg bg-[#D9D9D9] bg-opacity-20 rounded-[27px] flex flex-col gap-3 p-6 pt-4 col-span-1 ${
          days === 888 && "lg:col-span-2"
        }`}
      >
        <div className="flex justify-between text-white">
          <span className="text-xl font-medium flex-1">
            {days}-Day Payout Cycle
          </span>
        </div>
        <div className="bg-[#0C0518] bg-opacity-70 px-8 py-6 flex flex-col gap-4 rounded-2xl shadow-[0px_4px_24px_rgba(0,0,0,0.25)]">
          <div className="flex flex-col">
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Global Cycle Payout
                <TooltipWrapper
                  title={
                    "The amount of BNB that are in the pool including dx.BNB."
                  }
                />
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>${globalCyclePayoutUsd.toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  {(+currentCyclePayoutAmount).toFixed(10)} BNB
                </span>
              </div>
            </div>
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Pegged BNB
                <TooltipWrapper
                  title={
                    "The amount of BNB that has been previously withdrawn from the pool."
                  }
                />
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>${peggedBNBAmountUsd.toFixed(2)}</span>
                <span className="text-sm opacity-50">
                  {(+peggedBNBAmount).toFixed(10)} BNB
                </span>
              </div>
            </div>
            <div className="flex justify-between text-lg text-white">
              <span className="opacity-50 flex gap-2 items-center">
                Available to withdraw
                <TooltipWrapper
                  title={
                    "The amount of BNB that is currently in the pool (excluding dx.BNB)."
                  }
                />
              </span>
              <div className="flex flex-col text-right text-green-400">
                <span>
                  ${(+globalCyclePayoutUsd - peggedBNBAmountUsd).toFixed(2)}
                </span>
                <span className="text-sm opacity-50">
                  {(+currentCyclePayoutAmount - peggedBNBAmount).toFixed(10)}{" "}
                  BNB
                </span>
              </div>
            </div>
            <div className="w-full h-px bg-white bg-opacity-50 my-8" />
            <div className="flex gap-4 flex-col">
              <div className="flex flex-col gap-2 justify-between text-white">
                <span className="opacity-50 flex gap-2 items-center justify-between">
                  Amount to withdraw{" "}
                </span>
                <span className="flex">
                  <input
                    type="number"
                    className="flex-1 text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                    value={withdrawAmount}
                    onChange={({ target }) => setWithdrawAmount(target.value)}
                  />
                  <span className="opacity-50 ml-2">BNB</span>
                </span>
              </div>
              <button
                className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] px-4 py-2 rounded-2xl font-medium text-2xl text-white not-italic"
                onClick={async () =>
                  await withdrawFromPool(days, withdrawAmount)
                }
              >
                Withdraw
              </button>
            </div>
            <div className="w-full h-px bg-white bg-opacity-50 my-8" />
            <div className="flex gap-4 flex-col">
              <div className="flex flex-col gap-2 justify-between text-white">
                <span className="opacity-50 flex gap-2 items-center justify-between">
                  Amount to deposit{" "}
                  <div className="text-right text-base font-medium pr-[6px] text-white">
                    <span className="text-xs opacity-50 mr-1 font-normal">
                      Balance
                    </span>
                    {bnbBalance
                      ? (+utils.formatEther(bnbBalance)).toFixed(6)
                      : 0}{" "}
                    BNB
                  </div>
                </span>
                <span className="flex">
                  <input
                    type="number"
                    className="flex-1 text-white pl-6 bg-[#D9D9D9] bg-opacity-10 rounded-lg"
                    value={amount}
                    onChange={({ target }) => setAmount(target.value)}
                  />
                  <span className="opacity-50 ml-2">BNB</span>
                </span>
              </div>
              <button
                className="w-full bg-gradient-to-r from-pinky to-yellowy shadow-[0px_4px_24px_rgba(0,0,0,0.25)] px-4 py-2 rounded-2xl font-medium text-2xl text-white not-italic"
                onClick={async () => await depositToPool(days, amount)}
              >
                Deposit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPayoutCycle;
