import { useEthers, useContractFunction } from "@usedapp/core";
import { Contract } from "ethers";
import abi from "../../../contracts/swap.json";
import { SWAP_CONTRACT } from "../../../consts";

const useSwapEthToTokens = () => {
  const { library, account, chainId } = useEthers();
  const contract =
    library && chainId && SWAP_CONTRACT[chainId]
      ? new Contract(SWAP_CONTRACT[chainId], abi, library.getSigner())
      : null;
  const { send: sendSwap, state } = useContractFunction(
    contract,
    "swapETHForToken",
    { gasLimitBufferPercentage: 100 }
  );

  const swapEthToTokens = async (
    amount,
    amountOutMin,
    path,
    pathToWeth_V2,
    pathToWeth_V3
  ) => {
    try {
      console.log(
        "here",
        account,
        amount,
        amountOutMin,
        path,
        pathToWeth_V2,
        pathToWeth_V3
      );

      if (
        account &&
        amount &&
        amountOutMin != undefined &&
        (pathToWeth_V2.length >= 2 || pathToWeth_V3.length >= 43)
      ) {
        await sendSwap(amountOutMin, path, pathToWeth_V2, pathToWeth_V3, {
          value: amount,
        });
      }
    } catch (error) {
      console.error("Error while swapping ETH to tokens:", error);
    }
  };

  return { swapEthToTokens, state };
};

export default useSwapEthToTokens;
